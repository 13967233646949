/* eslint-disable @typescript-eslint/no-explicit-any */
import InventoryItemsStyled from './styled'
import { useState, useEffect } from 'react'
import { SearchInput } from 'shared'
import SelectCustom from 'shared/SelectCustom'
import { ShopTypeBodyPart, TypeSorts, ShopInventoryParams, ItemShop, ShopTypeRariTy, ShopInventorySort } from 'models'
import { ItemNFT } from 'features/ShopAP/components'
import { useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import { convertPluralText, handleAsyncRequest } from 'utils/helper'
import itemShopsApi from 'apis/itemShopsApi'
import openNotification from 'utils/toast'
import { Spin } from 'antd';

export default function InventoryItems() {
  const myHorseListParams: ShopInventoryParams = {
    limit: 20,
    page: 1,
    sort: '',
    boostType: 'ENERGY',
    rarity: '',
    search: ''
  }
  const [searchValue, setSearchValue] = useState<string>('')
  const [classSelect, setClassSelect] = useState('Sort')
  const [classSelectAll, setClassSelectAll] = useState('All')
  const [params, setParams] = useState<ShopInventoryParams>(myHorseListParams)
  const [isLoading, setIsLoading] = useState(false)
  const [listItems, setListItems] = useState<ItemShop[]>([])
  const [total, setTotal] = useState()
  const [isComingSoon, setIsComingSoon] = useState(false)

  const navigate = useNavigate()
  const { pathname } = useLocation()
  
  const [searchParams] = useSearchParams();
  const typePart = searchParams.get("type"); 
    
  const converBodyPart = (typePart: any) => {
    const handleKey = () => {
      switch (typePart) {
        case ShopTypeBodyPart.HEAD:
          return ''
        case ShopTypeBodyPart.BODY:
          return ''
        case ShopTypeBodyPart.LEG:
          return ''
        case ShopTypeBodyPart.SKIN:
          return ''
        case ShopTypeBodyPart.DOPING:
          return 'DOPING'
        case ShopTypeBodyPart.ENERGY:
          return 'ENERGY'
        default:
          return ''
      }
    }
    setParams({
      ...params, page: 1, boostType: handleKey()
    })
    setIsComingSoon( handleKey() === 'DOPING' ||  handleKey() === 'ENERGY')
  }

  const onItemClick = (id: string) => {
    navigate(`${pathname}/${id}`)
  }

  const defaultTypeRarity: TypeSorts = [
    {
      name: ShopTypeRariTy.ALL,
      isActive: false
    },
    {
      name: ShopTypeRariTy.COMMON,
      isActive: false
    },
    {
      name: ShopTypeRariTy.EPIC,
      isActive: false
    },
    {
      name: ShopTypeRariTy.LEGENDARY,
      isActive: false
    },
    {
      name: ShopTypeRariTy.MYTHIC,
      isActive: false
    },
    {
      name: ShopTypeRariTy.RARE,
      isActive: false
    }
  ]

  const defaultTypeFilters: TypeSorts = [
    {
      name: ShopInventorySort.DEFAULT,
      isActive: false
    },
    {
      name: ShopInventorySort.ASC,
      isActive: false
    },
    {
      name: ShopInventorySort.DESC,
      isActive: false
    }
  ]
  const handleSearchValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
    setTimeout(() => {
      setParams({
        ...params, page: 1, search: e.target.value
      })
    }, 1000);

  }
  const handleClassFilterClicked = (item: string) => {
    const handleKeySort = () => {
      switch (item) {
        case ShopInventorySort.DEFAULT:
          return ''
        case ShopInventorySort.ASC:
          return 'item_name'
        case ShopInventorySort.DESC:
          return 'item_name-desc'
        default:
          return 'lending_date-desc'
      }
    }  
    setParams({
      ...params, page: 1, sort: handleKeySort()
    })
    setClassSelect(item)
  }
  const handleClickedTypeRarity = (item: string) => {
    const handleKeySort = () => {
      switch (item) {
        case ShopTypeRariTy.ALL:
          return ''
        case ShopTypeRariTy.COMMON:
          return 'COMMON'
        case ShopTypeRariTy.EPIC:
          return 'EPIC'
        case ShopTypeRariTy.LEGENDARY:
          return 'LEGENDARY'
        case ShopTypeRariTy.MYTHIC:
          return 'MYTHIC'
          case ShopTypeRariTy.RARE:
          return 'RARE'
        default:
          return 'ALL'
      }
    }
    setParams({
      ...params, page: 1, rarity: handleKeySort()
    })
    setClassSelectAll(item)
  }

  const fetchListItems = async () => {
    setIsLoading(true)
    const [error, result]: any = await handleAsyncRequest(itemShopsApi.getItemInventory({ ...params }))
    const records = result?.data.records
    if (records) {
      setListItems(params.page > 1 ? [...listItems, ...records] : records)
      setTotal(result?.data.total)
    }
    if (error) {
      setListItems([])
      openNotification({
        message: 'ERROR',
        description: error.message || error.errors[0]?.message,
        className: 'ant-custom-error'
      })
    }

    if (records && records.length === 0) {
      // setLoader(true)
    }
    setIsLoading(false)
  }
  const hanleTotalViewMore = () => {
    return total && total - (listItems && listItems?.length || 0)
  }
  const hanldeViewMore = () => {
    setParams({ ...params, page: params.page + 1 })
  }
  useEffect(() => {
    converBodyPart(typePart)
  }, [typePart])

  useEffect(() => {
    if (isComingSoon) {
      fetchListItems()
    }
  }, [params])
  
  return (
    <InventoryItemsStyled>
      <div className='inventory-body'>
        <div className='inventory-top  d-flex justify-content-between align-items-center'>
          <div className='total-items'>
            <span>{convertPluralText(total, 'item')}</span>
          </div>
          <div className='filter-items d-flex justify-content-right'>
            <SelectCustom
              dataSelect={defaultTypeRarity}
              nameSelect={classSelectAll}
              onSelected={handleClickedTypeRarity}
            />
            <SelectCustom
              dataSelect={defaultTypeFilters}
              nameSelect={classSelect}
              onSelected={handleClassFilterClicked}
            />
            <SearchInput
              searchValue={searchValue}
              handleSearchValueChanged={handleSearchValueChanged}
              customClass='search-horse-input'
              placeholder={'Search ...'}
            />
          </div>
        </div>
        {isComingSoon ? (
          <div className='inventory-list'>
            {isLoading ? (
              <span className='btn-name d-flex align-items-center justify-content-center'>
                {isLoading && <Spin size="large" className='mt-5 mr-3' />}
              </span>
            ) : (
              <div className=''>
                { listItems.length === 0 ? (
                  <h1 className='color-white font-bold text-uppercase d-flex justify-content-center align-items-center'>No items Available</h1>
                ) : (
                  <div className='items'>
                  {
                    listItems.map((item, index) => (
                      <ItemNFT 
                        avt={item.boost.img} 
                        key={index} 
                        itemName={item.boost.name} 
                        price={Number(item.boost.price)}
                        onClick={() => onItemClick(item.boost.id)} 
                      />
                    ))
                  }
                  </div>
                )}
                {hanleTotalViewMore() &&
                  <div className='color-white view-more-container mt-5 mb-5' onClick={hanldeViewMore}>
                    <button className='btn-view-more'>
                      <span className='btn-view-more-text'>View More {hanleTotalViewMore()} Horses </span>
                    </button>
                  </div>
                }
              </div>
            )}
          </div>
        ) : (
          <h1 className='color-white font-bold text-uppercase d-flex justify-content-center align-items-center'>Coming Soon</h1>
        )}
      </div>
    </InventoryItemsStyled>
  )
}
