/* eslint-disable @typescript-eslint/no-explicit-any */
import { Navigate, Route, Routes } from 'react-router-dom'

import { constants, paths } from 'apps'
import { useLocalStorage } from 'hooks'
import { CommonLayout } from 'layouts'
import { useEffect } from 'react'
import { PublicRoute } from 'routes'
import { handleAsyncRequest } from 'utils/helper'
import { getSigner } from 'utils/metamask'
import { CreateGuild, GuildDetail, GuildFarm, GuildMain, Information, Manage, Members, YourFarm } from './pages'
import Condition from './pages/Condition'
import GuildList from './pages/GuildList'
import GuildMemberDetail from './pages/GuildMemberDetail'
import HorseOwner from './pages/HorseOwner'
import InCome from './pages/Income'
import UpdateInfo from './pages/UpdateInfo'
import MyHorses from './pages/MyHorses'

function SpecialRace() {
  const [, setUserId] = useLocalStorage(constants.USER_ID_KEY, 0)
  const [, setAccessToken] = useLocalStorage(constants.ACCESS_TOKEN_KEY, null)
  const [, setRefreshToken] = useLocalStorage(constants.REFRESH_TOKEN_KEY, null)

  const checkSignerProfile = async () => {
    const [signerError] = await handleAsyncRequest(getSigner())
    if (signerError) {
      setUserId(0)
      setAccessToken('')
      setRefreshToken('')
    }
  }
  useEffect(() => {
    checkSignerProfile()
  }, [])
  return (
    <Routes>
      <Route
        path={paths.default()}
        element={
          <PublicRoute layout={CommonLayout}>
            <GuildMain />
          </PublicRoute>
        }
      >
        <Route path={paths.guild.createGuild()} element={<CreateGuild />} />
        <Route path={paths.guild.joinGuild()} element={<GuildList />} />
        <Route path={paths.guild.information()} element={<Information />} />
        <Route path={paths.guild.members()} element={<Members />} />
        <Route path={paths.guild.membersId()} element={<Members />} />
        <Route path={paths.guild.guildFarm()} element={<GuildFarm />} />
        <Route path={paths.guild.guildFarmId()} element={<GuildFarm />} />
        <Route path={paths.guild.yourHorse()} element={<YourFarm />} />
        <Route path={paths.guild.manage()} element={<Manage />} />
        {/* <Route path={paths.guild.detail()} element={<HorseDetail />} /> */}
        <Route path={paths.guild.detail()} element={<GuildDetail />} />
        <Route path={paths.guild.detailMember()} element={<GuildMemberDetail />} />
        <Route path={paths.guild.informationDetail()} element={<Information />} />
        <Route path={paths.guild.horseOwner()} element={<HorseOwner />} />
        <Route path={paths.guild.horseOwnerId()} element={<HorseOwner />} />
        <Route path={paths.guild.updateInfo()} element={<UpdateInfo />} />
        <Route path={paths.guild.inCome()} element={<InCome />} />
        <Route path={paths.guild.entryCondition()} element={<Condition />} />
        <Route path={paths.guild.guildList()} element={<GuildList />} />
        <Route path={paths.guild.detailOwner()} element={<GuildMemberDetail />} />
        <Route path={paths.guild.myHorses()} element={<MyHorses />} />
        <Route path={paths.guild.myProfile()} element={<GuildMemberDetail />} />
        <Route path={paths.guild.horseDetailInfarm()} element={<GuildDetail />} />
        <Route path={paths.guild.horseDetail()} element={<GuildDetail />} />
      </Route>
      <Route path='*' element={<Navigate replace to={paths.notFound.feature()} />} />
    </Routes>
  )
}


export default SpecialRace
