import styled from 'styled-components'

const BuyItemsStyled = styled.div`
  width: 100%;
  .container {
    padding: 0px 20px;
    .buy-top {
      padding-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .total-items {
        span {
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          color: #4ef076;
        }
      }
      .filter-items {
        gap: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .select-box-customs {
          width: 96px;
          .select-drop-icon {
            left: 15px;
            filter: brightness(0) invert(1);
          }

          .orange-line {
            bottom: -0.5px;
          }
          span.select-name {
            font-size: 12px;
            top: 7px;
          }
        }
        .search-horse-input {
          width: 174px;
          clip-path: polygon(165px 0, 100% 16px, 100% 100%, 0 100%, 0 0);
          .search-input {
            font-size: 12px;
          }
        }
      }
    }
    .items {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
  }
`

export default BuyItemsStyled
