import { Horse, OWNER_STATUS } from 'models'
import { ClassTag } from 'shared'
import MyHorseItemStyled from './styled'
import { getGuildTag } from 'utils/helper'

interface MyHorseItemProps {
  horse: Horse
  openHorseModal: boolean
  closeHorseModal: () => void
  handleOpenModalHorse: () => void
  myName: string
  atProfile: boolean
  idBeChoose: number
  stt: number
}

function MyHorseITem({ horse, handleOpenModalHorse, stt }: MyHorseItemProps) {
  return (
    <MyHorseItemStyled onClick={handleOpenModalHorse} isActive={horse?.active}>
      <td className='color-white stt width-5'>{stt + 1}</td>
      <td className='color-white width-10'>
        <img src={horse.sub_avatar} alt='list-horse' className='horse-avatar' />
      </td>
      <td className='color-white padding-left-5 width-25 horse-name'><span className='color-yellow'>{horse.guild && getGuildTag(horse.guild.guild_tag)}</span> {horse.name}</td>
      <td className='color-white text-start width-15 padding-left-5'>{horse.bloodline.name}</td>
      <td className='color-white padding-left-4 width-15 gender'>{horse.gender}</td>
      <td className='color-white padding-left-59 width-18'>
        <ClassTag text={horse.racing_class} isActive={true} customClass='horse-class position-relative' />
      </td>
      <td >
        <div className='color-red'>
          {horse.active === false ? 'Disabled' : ''}
        </div>
        {horse?.own_status === 'LENDING' ? <div className='color-white'>BORROWED</div> : ''}
        {horse?.own_status === OWNER_STATUS.GuildKey ? <div className='color-white'>RENTING</div> : ''}
      </td>
    </MyHorseItemStyled>
  )
}

export default MyHorseITem
