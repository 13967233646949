/* eslint-disable @typescript-eslint/no-explicit-any */
import { JsonRpcSigner } from '@ethersproject/providers/lib/json-rpc-provider'
import authApi from 'apis/authApi'
import userApi from 'apis/userApi'
import { configs, constants } from 'apps'
import { ethers } from 'ethers'
import emasABI from 'json/TokenEMAS.json'
import mareABI from 'json/TokenMARE.json'
import { CurrentUser, PostLoginBody, RequestAccountsError, USAGE_WALLET } from 'models'
import { handleAsyncRequest, isMobileDevice } from './helper'

const contract = {
  tokenMARE: configs.tokenMARE,
  tokenEMAS: configs.tokenEMAS,
  chainId: configs.chainId
}

export function isEthereumWalletInstalled(): boolean {
  return Boolean(window.ethereum)
}

// START: 20231113 TQViet [PRIVATE_WALLET-407]-[Intergration_MH] Create Pop-up Adil Wallet MetaHorse
export function isAdilWalletInstalled(): boolean {
  return Boolean(window.adil)
}
// END: 20231113 TQViet [PRIVATE_WALLET-407]-[Intergration_MH] Create Pop-up Adil Wallet MetaHorse

export function checkCurrentNetwork(): boolean {
  // START: 20231113 TQViet [PRIVATE_WALLET-407]-[Intergration_MH] Create Pop-up Adil Wallet MetaHorse
  const web3Provider = getWeb3Provider()
  const chainID = web3Provider.networkVersion
  // END: 20231113 TQViet [PRIVATE_WALLET-407]-[Intergration_MH] Create Pop-up Adil Wallet MetaHorse
  return Boolean(chainID === contract.chainId)
}

export async function getSigner() {
  // START: 20231113 TQViet [PRIVATE_WALLET-407]-[Intergration_MH] Create Pop-up Adil Wallet MetaHorse
  const web3Provider = getWeb3Provider()
  const [error] = await handleAsyncRequest(web3Provider.request({ method: 'eth_requestAccounts' }))

  if (error) throw error

  const provider = new ethers.providers.Web3Provider(web3Provider)
  // END: 20231113 TQViet [PRIVATE_WALLET-407]-[Intergration_MH] Create Pop-up Adil Wallet MetaHorse

  const signer = provider.getSigner()

  return signer
}

export async function getRandomMessage() {
  const [error, randomMessageResponse] = await handleAsyncRequest(authApi.getRandomMessage())

  if (randomMessageResponse) {
    return randomMessageResponse.data.message
  }

  throw error
}

export async function getPostLoginBody(signer: JsonRpcSigner, message: string): Promise<PostLoginBody> {
  const sign = await signer.signMessage(message)
  const address = await signer.getAddress()

  const postLoginBody: PostLoginBody = {
    sign,
    address,
    message
  }

  return postLoginBody
}

export async function getBalance(signer: JsonRpcSigner): Promise<string> {
  // const hexBalance = await signer.getBalance()
  // const balance = hexBalance.toString()

  const mareContract = new ethers.Contract(contract.tokenMARE, mareABI.contract.abi, signer)
  const dataMARE = await mareContract.balanceOf(signer.getAddress())

  const emasContract = new ethers.Contract(contract.tokenEMAS, emasABI.contract.abi, signer)
  const dataEMAS = await emasContract.balanceOf(signer.getAddress())

  const resultMareEmas = `${dataMARE}` + ',' + `${dataEMAS}`

  return resultMareEmas
}

export async function getCurrentUser(): Promise<CurrentUser> {
  const [currentUserError, currentUserResponse] = await handleAsyncRequest(userApi.getCurrentUserInfo())
  if (!currentUserResponse) throw currentUserError

  const { data: currentUser } = currentUserResponse

  return currentUser
}

export function isRequestAccountsError(candidate: any): candidate is RequestAccountsError {
  return candidate?.code ? true : false
}

// START: 20231113 TQViet [PRIVATE_WALLET-407]-[Intergration_MH] Create Pop-up Adil Wallet MetaHorse
export function getWeb3Provider(): any {
  const walletInUse: USAGE_WALLET | null = localStorage.getItem(constants.WALLET_IN_USE) as USAGE_WALLET || null

  if (isMobileDevice()) {
    return (window as Window).ethereum
  }

  if (walletInUse === USAGE_WALLET.ADIL) {
    return (window as Window).adil;
  } else {
    return (window as Window).ethereum
  }
}
// END: 20231113 TQViet [PRIVATE_WALLET-407]-[Intergration_MH] Create Pop-up Adil Wallet MetaHorse
