/* eslint-disable @typescript-eslint/no-explicit-any */
import { ItemContainer } from "./styled";

interface itemProp {
  imgUrl: string
  onClickItem: () => void
  className?: any
}

const Item = ({ imgUrl, onClickItem, className }: itemProp) => {
  return (
    <ItemContainer onClick={onClickItem} className={className}>
      <div className="img-box">
        <img src={imgUrl} alt="icon" />
      </div>
    </ItemContainer>
  )
};

export default Item;