import styled from 'styled-components'

interface MyHorseItemStyled {
  mySelf: boolean
}

const MyHorseItemStyled = styled.tr<MyHorseItemStyled>`
  clip-path: polygon(0 0, 100% 0, 100% 100%, 14px 100%, 0 calc(100% - 14px));
  vertical-align: middle;
  background-color: rgb(37, 43, 67) !important;

  cursor: pointer;
  td {
    font-size: 16px;
    line-height: 20px;
    color: ${({ mySelf }) => (mySelf ? 'rgb(78, 240, 118)' : 'white')};

    p {
      color: ${({ mySelf }) => (mySelf ? 'rgb(78, 240, 118)' : 'white')};
    }

    .horse-avatar {
      width: 50px;
    }

    .horse-class {
      top: 4px;
    }
  }

  .width-8 {
    width: 8%;
    text-align: center;
  }

  .width-25 {
    width: 25%;
    text-align: left;
  }

  .width-20 {
    width: 20%;
    text-align: left;
  }

  .width-18 {
    width: 18%;
  }

  .width-15 {
    width: 15%;
  }

  .width-15-name {
    width: 15%;
    text-align: left;
  }

  .width-10 {
    width: 10%;
  }

  .padding-left-name {
    padding-left: 7.5%;
  }

  .padding-left {
    padding-left: 7.5%;
  }

  .padding-left-5 {
    padding-left: 5%;
  }

  .padding-left-4 {
    padding-left: 4%;
  }

  .padding-left-59 {
    padding-left: 5.8%;
  }

  .padding-left-6 {
    padding-left: 6.4%;
  }

  .width-15 {
    width: 15%;
  }

  .width-5 {
    width: 5%;
  }

  .stt {
    height: 52px;

    img {
      margin-right: 10px;
    }
  }

  .gender {
    text-transform: lowercase;
  }
  .gender:first-line {
    text-transform: capitalize;
  }
  .horse-name {
    justify-content: center;
    align-items: center;
  }
  .horse-name-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 55px;
  }

  .center {
    text-align: center;
  }

  .owner {
    padding-left: 75px;
  }

  .care {
    padding-left: 100px;
  }

  .race {
    padding-left: 80px;
  }
`

export default MyHorseItemStyled
