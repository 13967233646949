/* eslint-disable @typescript-eslint/no-explicit-any */
import { OneLineTitle } from 'shared'
import Armor from '../Armor'
import { ItemBoxContainer } from './styled'

const ItemBox = ({ horseItems, onSuccess, horseId }: any) => {
  return (
    <ItemBoxContainer>
      <div className='content'>
        <OneLineTitle text='armor' />
        <div className='row-item'>
          <Armor type="HEAD" horseItems={horseItems} onSuccess={onSuccess} horseId={horseId} />
          <Armor type="BODY" horseItems={horseItems} onSuccess={onSuccess} horseId={horseId} />
          <Armor type="LEG" horseItems={horseItems} onSuccess={onSuccess} horseId={horseId} />
        </div>
      </div>
      <div className='content'>
        <OneLineTitle text='Skin' />
        <div className='row-item'>
        <Armor type="SKIN" horseItems={horseItems} onSuccess={onSuccess} horseId={horseId} />
        </div>
      </div>
    </ItemBoxContainer>
  )
}

export default ItemBox
