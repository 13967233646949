export default {
  successfully_updated: `Congratulation! 
    You have successfully update information.`,
  image_over_5mb: 'Your file is too large. Please select file with size ≤= 5mb.',
  please_install_testnet: 'Please install testnet.spectre-rpc',
  please_install_spectre: 'Please install spectre-rpc.io',
  please_install_metamask: 'Please install metamask',
  please_login_metamask: 'Please login to metamask wallet and grant login permission to meta-horse system',
  please_install_adil_wallet: 'Please install',
  please_login_adil_wallet: 'Please login to ADIL Wallet and grant login permission to meta-horse system'
}
