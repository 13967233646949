/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */
import exchangeApi from 'apis/exchange'
import { configs, constants } from 'apps'
import { CURRENCY_TYPE, STATUS_TRANSACTION, timeCheckNonce } from 'apps/constants'
import {
  CHAIN_TOKEN_MARE,
  CIRCEL_ARROW_DOWN_BLUE,
  CLOSE_BTN,
  CONNECT_WALLET_EXCHANGE,
  DISABLE_CONNECT_WALLET_EXCHANGE,
  GAME_TOKEN_KUDA,
  UP
} from 'assets/images'
import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import { ResultHorseModal } from 'features/Race/components'
import { useDebounce, useReloadCurrentPage, useToggle } from 'hooks'
import { NOTIFICATION_MESSAGE } from 'i18n/constants'
import tokenGateAbi from 'json/TokenGate.json'
import mareABI from 'json/TokenMARE.json'
import { DepositRequest, DepositResponses } from 'models'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'shared'
import { convertGasPrice, handleAsyncRequest } from 'utils/helper'
import InProgressBalanceModal from '../InProgress'
import ExchangeMAREModalStyled from './styled'
import { getWeb3Provider } from 'utils/metamask'

interface ExchangeMAREModalProps {
  toggleIsExchangeModalOpen: (value: boolean) => void
  coinMare?: number
  coinKuda: number
  coinMareLogic: number
}

const defaultParams: DepositRequest = {
  amount: ''
}

function ExchangeMAREModal({ coinMare, toggleIsExchangeModalOpen, coinKuda, coinMareLogic }: ExchangeMAREModalProps) {
  // START: 20231113 TQViet [PRIVATE_WALLET-407]-[Intergration_MH] Create Pop-up Adil Wallet MetaHorse
  const web3Provider = getWeb3Provider()
  const provider = new ethers.providers.Web3Provider(web3Provider)
  // END: 20231113 TQViet [PRIVATE_WALLET-407]-[Intergration_MH] Create Pop-up Adil Wallet MetaHorse
  const signer = provider.getSigner()

  const [params, setParams] = useState<DepositRequest>(defaultParams)
  const [valueMARE, setValueInput] = useState<string>('')
  const debounceSearchValue = useDebounce<string>(valueMARE, constants.DEBOUNCE_TIME)
  const [resultExchangeDeposit, setResultExchangeDeposit] = useState<DepositResponses>()
  const [isModalResultHorseOpen, toggleIsModalResultHorseOpen] = useToggle(false)
  const [messageError, setMessageError] = useState('')
  const [titleBalace, setTitleBalance] = useState('')
  const [reInputExchange, setReInputExchange] = useState(false)
  const [openInProgressBalanceModal, setOpenInProgressBalanceModal] = useToggle(false)
  const [flagButtonOk, setFlagButtonOk] = useState<boolean>(false)
  const [watchInProgress, setWatchInProgress] = useState<boolean>(false)
  const [watchConnectWallet, setWatchConnectWallet] = useState<boolean>(false)
  const [watchCloseBtnWhenMTOpen, setWatchCloseBtnWhenMTOpen] = useState<boolean>(false)
  const [isModalConfirmExchangeOpen, setModalConfirmExchangeOpen] = useState<boolean>(false)
  const [isConfirmExchange, setConfirmExchange] = useState<boolean>(false)
  const [handleDepositCoin, setHandleDepositCoin] = useState<boolean>(false)
  const { t } = useTranslation()

  const contract = {
    tokenMARE: configs.tokenMARE,
    tokenGate: configs.tokenGate,
    tokenEMAS: configs.tokenEMAS,
    transporter: configs.transporter,
  }

  const messageSuccess = t(`${NOTIFICATION_MESSAGE}.transferredSuccess`)
  const messageFailed = t(`${NOTIFICATION_MESSAGE}.transferredFailed`)
  const messageNotEnoughExchange = t(`${NOTIFICATION_MESSAGE}.needGetMARE`)

  const handleSearchValueChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const inputCoin = parseInt(e.target.value)
    const compareCoinMAREInput = inputCoin > coinMareLogic

    if (compareCoinMAREInput) {
      setTitleBalance('warning')
      toggleIsModalResultHorseOpen(true)
      setMessageError(messageNotEnoughExchange)
      setReInputExchange(true)
      setValueInput((e.target.value = ''))
      setWatchConnectWallet(true)
    }

    setWatchConnectWallet(false)
    setValueInput(e.target.value.replace(/^0+/, ''))
  }

  useEffect(() => {
    if (debounceSearchValue === '') {
      setWatchConnectWallet(true)
    }
    setParams({ ...params, amount: debounceSearchValue })
  }, [debounceSearchValue])

  useEffect(() => {
    if (watchInProgress === true) {
      toggleIsModalResultHorseOpen(true)
      setTitleBalance('success!')
      setMessageError(messageSuccess)
      setFlagButtonOk(true)
      localStorage.removeItem(constants.TRANSACTION)
    }
  }, [watchInProgress])

  const handleDepositMARE = async () => {
    setWatchConnectWallet(true)
    setWatchCloseBtnWhenMTOpen(true)
    const [error, result]: any = await handleAsyncRequest(exchangeApi.postExchange(params))
    if (error) {
      setTitleBalance('failed!')
      toggleIsModalResultHorseOpen(true)
      setMessageError(error?.message || error?.errors[0]?.message || messageFailed)
      setFlagButtonOk(false)
      setWatchCloseBtnWhenMTOpen(false)
    }
    if (!result) return
    if (result) {
      setResultExchangeDeposit(result.data)
      setModalConfirmExchangeOpen(true)
      setConfirmExchange(true)
    }
  }

  const assignMetaMaskMare = async () => {
    try {
      toggleIsExchangeModalOpen(true)
      const { ethereum } = window
      if (!ethereum) return
      if (!resultExchangeDeposit) return


      const amountToApprove = new BigNumber(resultExchangeDeposit.blockchain_amount).toFixed()
      const mareContract = new ethers.Contract(contract.tokenMARE, mareABI.contract.abi, signer)

      try {
        await mareContract.approve(contract.transporter, amountToApprove, { gasPrice: convertGasPrice(resultExchangeDeposit.gas_price) })
        setHandleDepositCoin(true)
      } catch (err: any) {
        toggleIsModalResultHorseOpen(true)
        setTitleBalance('failed!')
        setMessageError(messageFailed)
        setFlagButtonOk(false)
        setWatchConnectWallet(false)
        setWatchCloseBtnWhenMTOpen(false)
        setConfirmExchange(false)
      }

    } catch (err: any) {
      setTitleBalance('failed!')
      toggleIsModalResultHorseOpen(true)
      setMessageError(messageFailed)
      setFlagButtonOk(false)
      setWatchConnectWallet(false)
    }
  }

  const handleDeposit = async () => {
    if (!resultExchangeDeposit) return
    try {
      const tokenGateContract = new ethers.Contract(contract.tokenGate, tokenGateAbi.contract.abi, signer)
      const coverBlockExpired = new BigNumber(resultExchangeDeposit.block_expired).toFixed()
      const amountToApprove = new BigNumber(resultExchangeDeposit.blockchain_amount).toFixed()

      const tx = await tokenGateContract.deposit({
        owner: resultExchangeDeposit.owner,
        token: resultExchangeDeposit.token,
        blockExpired: coverBlockExpired,
        amount: amountToApprove,
        none: resultExchangeDeposit.nonce,
        v: resultExchangeDeposit.v,
        r: resultExchangeDeposit.r,
        s: resultExchangeDeposit.s
      }, { gasPrice: convertGasPrice(resultExchangeDeposit.gas_price) })

      // if (window.performance) {
      //   if (performance.navigation.type == 1) {
      //     setWaitTransaction(tx)
      //   }
      // }

      if (tx.hash) {
        await provider.waitForTransaction(tx.hash)
      }
      setOpenInProgressBalanceModal(true)
    } catch (err: any) {
      setFlagButtonOk(true)
      setTitleBalance('failed!')
      toggleIsModalResultHorseOpen(true)
      setMessageError(messageFailed)
      setWatchConnectWallet(false)
      setWatchCloseBtnWhenMTOpen(false)
    }
  }

  const handlePostCheckNonceDeposit = async () => {
    if (!resultExchangeDeposit) return
    let checkNonceExits = null as any
    let x = 0
    let intervalID = setInterval(async () => {
      const nonce = resultExchangeDeposit.nonce
      const [, result] = await handleAsyncRequest(exchangeApi.postCheckNonce({ nonce }))
      if (!result) return
      checkNonceExits = result.data.status
      if (checkNonceExits === STATUS_TRANSACTION.success) {
        clearInterval(intervalID)
        setWatchInProgress(true)
      }

      if (checkNonceExits === STATUS_TRANSACTION.expired) {
        clearInterval(intervalID)
        setTitleBalance('failed!')
        setMessageError(messageFailed)
        toggleIsModalResultHorseOpen(true)
        setWatchInProgress(false)
        setFlagButtonOk(true)
      }

      if (++x === 10) {
        if (checkNonceExits === STATUS_TRANSACTION.pending) {
          clearInterval(intervalID)
          setTitleBalance('warning')
          toggleIsModalResultHorseOpen(true)
          setMessageError(t(`${NOTIFICATION_MESSAGE}.transferredPending`))
          setFlagButtonOk(true)
        }
      }
    }, timeCheckNonce)
  }

  useEffect(() => {
    if (isConfirmExchange === true) {
      assignMetaMaskMare()
    }
  }, [isConfirmExchange])

  useEffect(() => {
    if (handleDepositCoin === true) {
      handleDeposit()
    }
  }, [handleDepositCoin])

  useEffect(() => {
    if (openInProgressBalanceModal === true) {
      handlePostCheckNonceDeposit()
    }
  }, [openInProgressBalanceModal])

  const handleOk = () => {
    if (flagButtonOk === false) {
      toggleIsModalResultHorseOpen(false)
      setModalConfirmExchangeOpen(false)
    }
    if (flagButtonOk === true) {
      useReloadCurrentPage()
    }
  }

  const handleOkExchange = () => {
    toggleIsModalResultHorseOpen(false)
  }

  const handleDisabledBtnConnect = useCallback(() => {
    if (valueMARE) {
      return false
    }

    return true
  }, [valueMARE])

  const handleDisableBtn = useCallback(() => {
    if (watchConnectWallet === true) {
      return true
    }

    return false
  }, [watchConnectWallet])

  const handleCloseButtonClick = useCallback(() => {
    if (watchCloseBtnWhenMTOpen === true) {
      return toggleIsExchangeModalOpen(true)
    }

    return toggleIsExchangeModalOpen(false)
  }, [watchCloseBtnWhenMTOpen])

  return (
    <Modal>
      <ExchangeMAREModalStyled>
        <button className='close-btn p-0 position-absolute' role='button' onClick={handleCloseButtonClick}>
          <img src={CLOSE_BTN} alt='close' />
        </button>
        <div className='title-exchange d-flex justify-content-between'>
          <div className='withdraw text-uppercase'>{t(`${NOTIFICATION_MESSAGE}.exchange`)}</div>
          <div className='claim'>{t(`${NOTIFICATION_MESSAGE}.getMARE`)}</div>
        </div>
        <div className='merah'>
          <img src={UP} alt='' />
          <img src={CHAIN_TOKEN_MARE} alt='' className='game-token-merah' />
          <input
            className='value-merah search-input flex-grow-1'
            defaultValue={valueMARE}
            placeholder='0'
            onChange={handleSearchValueChanged}
            maxLength={10}
            onKeyPress={event => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault()
              }
            }}
            onPaste={e => {
              e.preventDefault()
              return false
            }}
            onCopy={e => {
              e.preventDefault()
              return false
            }}
          />
          <span className='value-balance'>
            {t(`${NOTIFICATION_MESSAGE}.balance`)}: {coinMare}
          </span>
          <span className='title-merah'>{CURRENCY_TYPE.MARE}</span>
        </div>
        <div className='text-center mt-1 mb-3'>
          <img src={CIRCEL_ARROW_DOWN_BLUE} alt='' />
        </div>
        <div className='merah'>
          <img src={UP} alt='' />
          <img src={GAME_TOKEN_KUDA} alt='' className='game-token-merah' />
          <input
            className='value-merah search-input flex-grow-1'
            placeholder='0'
            defaultValue={valueMARE}
            maxLength={10}
            disabled
            onPaste={e => {
              e.preventDefault()
              return false
            }}
            onCopy={e => {
              e.preventDefault()
              return false
            }}
          />
          <span className='value-balance'>
            {t(`${NOTIFICATION_MESSAGE}.balance`)}:
            {coinKuda > 0
              ? coinKuda
              : 0}
          </span>
          <span className='title-merah'>{CURRENCY_TYPE.KUDA}</span>
        </div>
        <div className='notice-exchange text-center'>
          <div>{t(`${NOTIFICATION_MESSAGE}.exchangeRate`)}</div>
          <div>{t(`${NOTIFICATION_MESSAGE}.mareToKuda`)}</div>
        </div>
        <div className='btn-connect-wallet text-center'>
          <button onClick={handleDepositMARE} disabled={handleDisableBtn()}>
            <img
              src={
                handleDisabledBtnConnect() || watchConnectWallet
                  ? DISABLE_CONNECT_WALLET_EXCHANGE
                  : CONNECT_WALLET_EXCHANGE
              }
              alt='btn-connect-h2h'
            />
          </button>
        </div>
      </ExchangeMAREModalStyled>

      {isModalConfirmExchangeOpen && <InProgressBalanceModal confirmTransaction={true} />}

      {isModalResultHorseOpen && (
        <ResultHorseModal
          title={titleBalace}
          onOk={reInputExchange === true ? handleOkExchange : handleOk}
          message={messageError}
          exchangeCoin={true}
        />
      )}

      {openInProgressBalanceModal && <InProgressBalanceModal />}
    </Modal>
  )
}

export default ExchangeMAREModal
