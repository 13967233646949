import { GAME_TOKEN_BIRU, GAME_TOKEN_KUDA, GAME_TOKEN_MERAH } from 'assets/images'
import dayjs from 'dayjs'
import { encodeURI } from 'js-base64'

import { HorseCareer } from 'models'
import { ClassTag } from 'shared'
import { ordinalSuffixOf } from 'utils/helper'
import HorseCareerItemStyled from './styled'

interface HorseCareerItemProps {
  career: HorseCareer
}

function HorseCareerItem({ career }: HorseCareerItemProps) {
  return (
    <HorseCareerItemStyled
      className='horse-career color-white w-100'
      onClick={() => window.open(`/race/detail/${encodeURI(career.race_id.toString())}`, '_blank')}
    >
      <td className='time-table font-bold ps-4'>
        {dayjs.unix(parseInt(career.race_date)).format('DD/MM/YYYY')}{' '}
        {dayjs.unix(parseInt(career.race_date)).format('HH:mm')}
      </td>
      <td className='place-table position-relative'>{career.race_name}</td>
      <td className='race-course-table'>
        {career.race_course.name}
      </td>
      <td className='class-table'>
        <ClassTag text={career.race_class} isActive={true} />
      </td>
      <td className='field-table'>{career.field_type}</td>
      <td className='distance-table font-bold'>{career.distance.toLocaleString()}m</td>
      <td className='rank-table font-bold color-yellow'>
        <span dangerouslySetInnerHTML={{ __html: ordinalSuffixOf(career.race_position) }} />
      </td>
      <td className='total-prize-table font-bold'>
        {career.entry_fee === 0 ? (
          <span className='color-primary font-bold'>FREE</span>
        ) : (
          <>
            <span className='color-kuda'>{career.entry_fee} </span>
            <img src={GAME_TOKEN_KUDA} alt='kuda' className='kuda' />
          </>
        )}
      </td>
      <td className='entry-fee-table font-bold'>
        {!career?.total_prize ? (
          <span className='font-bold'> --- </span>
        ) : career?.entry_fee === 0 ? (
          <>
            <span className='color-biru'>{career.total_prize} </span>
            <img src={GAME_TOKEN_BIRU} alt='biru' />
          </>
        ) : (
          <>
            <span className='color-merah'>{career.total_prize} </span>
            <img src={GAME_TOKEN_MERAH} alt='merah' />
          </>
        )}
      </td>
    </HorseCareerItemStyled>
  )
}

export default HorseCareerItem
