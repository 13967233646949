/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiResponse, CreateGuildRequest } from 'models'

import axiosClient from './axiosClient'

const guildApi = {
  createGuild(body: CreateGuildRequest): Promise<ApiResponse<string>> {
    const url = `/guilds`
    return axiosClient.post(url, body)
  },

  putEditGuild(guildId: any, params: any): Promise<ApiResponse<string>> {
    const url = `/guilds/${guildId}`
    return axiosClient.put(url, { ...params })
  },

  getGuildMaterData(): Promise<ApiResponse<string>> {
    const url = '/guilds/guild-master-data'
    return axiosClient.get(url)
  },

  getGuildInformation(): Promise<ApiResponse<string>> {
    const url = '/guilds/information'
    return axiosClient.get(url)
  },

  getListGuild(params: any): Promise<ApiResponse<string>> {
    const url = `/guilds/guilds-list`
    return axiosClient.get(url, { params })
  },

  getListGuildMember(guildId: string | number, params: any): Promise<ApiResponse<string>> {
    const url = `/guilds/members/${guildId}`
    return axiosClient.get(url, { params })
  },

  joinGuild(guildId: number): Promise<ApiResponse<string>> {
    const url = `/guilds/join-guilds/${guildId}`
    return axiosClient.post(url)
  },

  getGuildInfomationById(guildId: string | number): Promise<ApiResponse<string>> {
    const url = `/guilds/information/${guildId}`
    return axiosClient.get(url)
  },

  getGuildInfomation(): Promise<ApiResponse<string>> {
    const url = '/guilds/information'
    return axiosClient.get(url)
  },

  getGuildManager(): Promise<ApiResponse<string>> {
    const url = '/guilds/information'
    return axiosClient.get(url)
  },

  putRoleUserGuild(guildId: number, userId: number | string, params: any): Promise<ApiResponse<string>> {
    const url = `/guilds/${guildId}/members/${userId}/roles`
    return axiosClient.put(url, { ...params })
  },

  getGuildMember(userId: string | number): Promise<ApiResponse<string>> {
    const url = `/guilds/member/${userId}`
    return axiosClient.get(url)
  },

  userLeaveGuild(guildId: string | number): Promise<ApiResponse<string>> {
    const url = `/guilds/${guildId}/leave-guild`

    return axiosClient.put(url)
  },

  putKickMemberOutGuild(guildId: string | number, memberId: number): Promise<ApiResponse<string>> {
    const url = `/guilds/${guildId}/members/${memberId}/kick`

    return axiosClient.put(url)
  },

  getGuildFarms(guildId: string | number, params: any): Promise<ApiResponse<string>> {
    const url = `/guilds/guild-farms/${guildId}`
    return axiosClient.get(url, { params })
  },

  getYourHorseGuildFarms(params: any): Promise<ApiResponse<string>> {
    const url = `/guilds/guild-farms`
    return axiosClient.get(url, { params })
  },

  getMyHorsesInFarm(params: any): Promise<ApiResponse<any>> {
    const url = `/guilds/my-horses`
    return axiosClient.get(url, { params })
  },

  getAvailableMyHorses(params: any): Promise<ApiResponse<any>> {
    const url = `/guilds/my-horses/available`
    return axiosClient.get(url, { params })
  },

  postAddHorseGuildFarm(data: any): Promise<ApiResponse<any>> {
    const url = '/guilds/add-horse'
    return axiosClient.post(url, data)
  },

  postCheckNonce(data: any): Promise<ApiResponse<any>> {
    const url = '/guilds/check-nonce'
    return axiosClient.post(url, data)
  },

  getHorseGuildDetails(horseId: number | string): Promise<ApiResponse<any>> {
    const url = `/guilds/guild-farms/horses/${horseId}`
    return axiosClient.get(url)
  },

  getMemberDetails(guildId: number | string, userId: number | string): Promise<ApiResponse<any>> {
    const url = `/guilds/${userId}/${guildId}/member-detail`
    return axiosClient.get(url)
  },

  getMyProfile(): Promise<ApiResponse<any>> {
    const url = `/guilds/my-profile`
    return axiosClient.get(url)
  },

  postWithrawHorse(data: any): Promise<ApiResponse<any>> {
    const url = '/guilds/withdraw-horse'
    return axiosClient.post(url, data)
  },

  editProfitGuild(guildId: string | number, params: any): Promise<ApiResponse<string>> {
    const url = `/guilds/${guildId}/profit-percentage`

    return axiosClient.put(url, { ...params })
  },

  postSendToGuild(horseId: number | string, params: any): Promise<ApiResponse<any>> {
    const url = `/guilds/guild-farms/horses/${horseId}/send-to-market`
    return axiosClient.post(url, params)
  },

  postHorseRentGuild(horseId: number | string, params: any): Promise<ApiResponse<any>> {
    const url = `/guilds/guild-farms/horses/${horseId}/rent`
    return axiosClient.post(url, params)
  },

  postHorseBackToFarm(horseId: number | string): Promise<ApiResponse<any>> {
    const url = `/guilds/guild-farms/horses/${horseId}/back-to-farm`
    return axiosClient.post(url)
  },

  getTopHorses(guildId: number | string): Promise<ApiResponse<any>> {
    const url = `/guilds/${guildId}/top-horses`
    return axiosClient.get(url)
  },

  getTopMembers(guildId: number | string): Promise<ApiResponse<any>> {
    const url = `/guilds/${guildId}/top-members`
    return axiosClient.get(url)
  },

  getTopOwners(guildId: number | string): Promise<ApiResponse<any>> {
    const url = `/guilds/${guildId}/top-owners`
    return axiosClient.get(url)
  },

  putHorseToGuild(horseId: number | string, guildId: number | string): Promise<ApiResponse<any>> {
    const url = `/guilds/${horseId}/${guildId}/to-guild-farm`
    return axiosClient.put(url)
  },

  putWithdrawFromGuild(horseId: number | string): Promise<ApiResponse<any>> {
    const url = `/guilds/${horseId}/withdraw-from-guild`
    return axiosClient.put(url)
  },

  getHorseOwner(guildId: number | string, params: any): Promise<ApiResponse<any>> {
    const url = `/guilds/horse-owner/${guildId}`
    return axiosClient.get(url, { params })
  },

  getRaceHistory(guildId: string | number, params: any): Promise<ApiResponse<string>> {
    const url = `/guilds/${guildId}/races-history`
    return axiosClient.get(url, { params })
  },

  putHorseArrToGuild(guildId: number | string, params: any): Promise<ApiResponse<any>> {
    const url = `/guilds/${guildId}/to-guild-farm`
    return axiosClient.put(url, params)
  },

  getMyHorses(params: any): Promise<ApiResponse<any>> {
    const url = '/guilds/guild-farms/my-horses'
    return axiosClient.get(url, { params })
  },

  getMemberRaceHistory(guildId: string | number, userId: string | number, params: any): Promise<ApiResponse<string>> {
    const url = `/guilds/${userId}/${guildId}/member-races-history`
    return axiosClient.get(url, { params })
  },

  getHorseRaceHistory(guildId: string | number, horseId: string | number, params: any): Promise<ApiResponse<string>> {
    const url = `/guilds/${horseId}/${guildId}/horse-races-history`
    return axiosClient.get(url, { params })
  }
}

export default guildApi
