import styled from 'styled-components'

const UpdateInfoStyled = styled.div`
  /* width: 100%; */
  margin: 0 auto;
  .guild-container {
    width: 800px;
    padding-top: 10px;

    .line__guild--gradiant {
      height: 2px;
      padding: 30px 0;
      img {
        width: 100%;
        height: 2px;
      }
    }

    .guil__content {
      overflow: hidden;
      word-break: break-word;
      display: flex;
      flex-direction: column;

      .guil__content--mar5 {
        margin-top: 5px;
      }
    }
    .guild-label {
      display: flex;
      align-items: center;
      font-size: 18px;
    }

    .guild__label--logo {
      flex-shrink: 0;
    }

    .guild__content--logo {
      width: 100%;
      -webkit-column-gap: 8px;
      column-gap: 8px;
      background-color: #252B43;
      -webkit-clip-path: polygon(97% 0%,100% 15px,100% 100%,0 100%,0 0);
      clip-path: polygon(97% 0%,100% 15px,100% 100%,0 100%,0 0);
      position: relative;
      /* flex: 1; */
      display: flex;
      flex-direction: column;
      min-height: 278.56px;

      .orange-line {
        left: 0;
        bottom: -1px;
        width: 70px;
        height: 3px;
      }

      .guild__line {
        display: flex;
        justify-content: center;
        width: 100%;

        img {
          width: 39px;
          height: 2px;
        }

        .guild__line--space {
          width: 15px;
        }
      }

      .guild__content--arr-avatar {
        width: 100%;
        height: 100%;
        flex: 1;
        padding: 10px;
        display: flex;
        display: flex;
        flex-wrap: wrap;

        .item__avatar {
          width: 56px;
          height: 56px;
          text-align: center;
          border-radius: 50%;
          padding: 2px;
          cursor: pointer;
          box-sizing: border-box;

          &.active {
            border: 1px solid #44E8AF;
          }

          img {
            width: 50px;
            height: 50px;
          }
        }
      }
    }

    .guil__content--checkbox {
      margin-top: 15px;
      line-height: 1.5715;
      position: relative;

      .guild__create--checkbox {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
      }

      label {
        position: relative;
        cursor: pointer;
        font-size: 18px !important;
      }

      label:before {
        content: "";
        -webkit-appearance: none;
        background-color: transparent;
        border: 2.11952px solid #4EF076;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
          inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 8px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 15px;
        background-color: #252B43;
        top: -2px;
      }

      .guild__create--checkbox:checked + label:after {
        content: "";
        display: block;
        position: absolute;
        top: 5px;
        left:6px;
        width: 8px;
        height: 12px;
        border: solid #4EF076;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        background-color: #252B43;
      }
      .border__cover {
        display: block;
        position: absolute;
        top: -4px;
        left:28px;
        width: 8.5px;
        height: 14px;
        border: solid #4EF076;
        border-width: 0 0 2px 0;
        transform: rotate(45deg);
        background-color: #121212;
      }
    }

    .guild__content--avatar {
      width: 100%;
      text-align: center;

      .guild-avatar {
        display: inline-block;
        border-radius: 50%;
        width: 100px;
        height: 100px;
      }
    }
    
    .guild-logo {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 60px;
    }
    .btn-create {
      display: flex;
      justify-content: center;
      padding-top: 30px;
      width: 100%;
    }
    .ant-form-item {
      margin: 0px;
    }
    .ant-form-item-control-input {
      margin-bottom: 10px;
    }

    .col-9 {
      padding-left: 0;
    }

    .minus-botton10 {
      margin-bottom: -10px;
    }

    .request__red {
      color: red;
      padding-left: 5px;
    }

    .guild__textarea {
      overflow: hidden;
    }

    .disableDiv {
      pointer-events: none;
    }
  }

  .ant-notification .ant-notification-top {
    background-color: red;
  }

  .input-box {
    input,
    textarea {
      text-align: left;
    }
  }

  .ant-input-suffix {
    display: none;
  }

  .ant-input-affix-wrapper-status-error,
  .ant-input {
    background: transparent !important;
    color: #fff;
  }

  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
  .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(
      .ant-input-affix-wrapper-borderless
    ).ant-input-affix-wrapper,
  .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(
      .ant-input-affix-wrapper-borderless
    ).ant-input-affix-wrapper:hover {
    background: #fff;
    border-color: transparent !important;
    box-shadow: none !important;
  }

  .ant-input-disabled {
    color: rgb(157, 157, 157);
  }
`

export default UpdateInfoStyled
