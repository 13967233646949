import { Modal } from 'shared';
import { ModalConfirmCreateGuildContainer } from './styled';
// import { CLOSE_BTN } from 'assets/images'
import Button from 'shared/Button';

interface MProps {
  onClose: () => void;
  onConfirm: () => void;
  titleModal?: string;
  fee: number;
  loading: boolean;
  error?: string;
}

const ModalConfirmCreateGuild = ({
  onClose,
  onConfirm,
  titleModal = '',
  fee,
  loading,
  error
}: MProps) => {

  const _onConfirm = () => {
    onConfirm();
  }

  return (
    <Modal>
      <ModalConfirmCreateGuildContainer>
        <div className='modal__container'>
          <div className='header'>
            <p>
              <span className='title'>
                {titleModal}
              </span>
            </p>
            <span className='line__under--title'></span>
          </div>
          {/* <button
            disabled={loading}
            className='close-btn p-0 position-absolute'
            role='button'
            onClick={onClose}
          >
            <img src={CLOSE_BTN} alt='close' />
          </button> */}
          <div className='content-box'>
            <p>
              You will have to pay {fee} KUDA to create guild <br />
              Are you ok ?
            </p>
          </div>
          <p className='error'>{error}</p>
          <div className='button-box'>
            <Button buttonName="No" onClickButton={onClose} width={150} height={35} btnCancel disabled={loading} />
            <Button buttonName="Yes" onClickButton={_onConfirm} width={150} height={35} isLoading={loading} />
          </div>
        </div>
      </ModalConfirmCreateGuildContainer>
    </Modal>
  )
}

export default ModalConfirmCreateGuild
