/* eslint-disable @typescript-eslint/no-explicit-any */

import { notification } from 'antd'
import guildApi from 'apis/guildApi'
import { links } from 'apps'
import constants, { ROLE_GUILD } from 'apps/constants'
import ModalConfirmJoinGuild from 'features/Guild/components/ModalConfirmJoinGuild'
import ModalManageMember from 'features/Guild/components/ModalManageMemberGuild'
import { RaceTable } from 'features/Race/components'
import { useAppSelector, useDebounce, useFocusTopScreen, useToggle, useUpdateEffect } from 'hooks'
import { GUILD_MESSAGE, NOTIFICATION_MESSAGE } from 'i18n/constants'
import { Career, GuildListSortType, MyHorseListParams } from 'models'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { SearchInput } from 'shared'
import SelectCustom from 'shared/SelectCustom'
import { guildMemberColumns, guildMemberColumns2 } from 'utils/columns'
import { handleAsyncRequest, shortenUserName } from 'utils/helper'
import MembersStyled from './styled'


const defaultSortType: any = [
  {
    name: GuildListSortType.RankingAsc,
  },
  {
    name: GuildListSortType.RankingDesc
  },
  {
    name: GuildListSortType.Ascending
  },
  {
    name: GuildListSortType.Decrease
  }
]

export default function Members() {
  useFocusTopScreen()
  const navigate = useNavigate()
  const { guild_id } = useParams()
  const [api, contextHolder] = notification.useNotification()
  const myHorseListParams: MyHorseListParams = {
    limit: 20,
    page: 1,
    sort: 'rank-desc'
  }

  const [guildMembers, setGuildMembers] = useState<any>([])
  const [params, setParams] = useState<MyHorseListParams>(myHorseListParams)
  const [total, setTotal] = useState()
  const [loading, setLoading] = useState<boolean>(false)
  const [memberSelect, setMemberSelect] = useState<any>()
  const [showModalManageMemberGuild, setShowModalManageMemberGuild] = useToggle(false)
  const [loadingModal,] = useToggle(false)
  const { t } = useTranslation()
  const { profile } = useAppSelector(state => state)
  const [showModalConfirmJoinGuild, setShowModalConfirmJoinGuild] = useToggle(false)
  const [showModalConfirmPromoteGuild, setShowModalConfirmPromoteGuild] = useToggle(false)
  const [showModalConfirmDowngradeGuild, setShowModalConfirmDowngradeGuild] = useToggle(false)
  const [updateRole, setUpdateRole] = useToggle(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const selectRef = useRef<any>(null)
  const debounceSearchValue = useDebounce<string>(searchValue, constants.DEBOUNCE_TIME)
  const [classSelect, setClassSelect] = useState<any>(GuildListSortType.RankingDesc)

  const memoizedResultListSelectionColumns = useMemo(() => {
    if ((profile?.role_in_guild === 'MEMBER' || !profile?.guild_id) || (guild_id && Number(profile?.guild_id) !== Number(guild_id))) {
      return guildMemberColumns2
    }
    return guildMemberColumns
  }, [profile])

  useEffect(() => {
    if (profile.guild_id !== 0) {
      getListGuild()
    }
  }, [params, profile, updateRole])

  useUpdateEffect(() => {
    setParams({ ...params, page: 1, sort: selectRef.current, search: debounceSearchValue })
  }, [debounceSearchValue])


  const openNotification = (message: string, description: string) => {
    notification.config({
      duration: 4
    })

    api.info({
      message,
      description,
      placement: 'bottomRight',
      className: message === 'ERROR' ? 'ant-custom-error' : 'ant-custom-success'
    })
  }

  const roleType = (role: string) => {
    switch (role) {
      case ROLE_GUILD.master: return 'Guild Master'
      case ROLE_GUILD.manager: return 'Guild Manager'
      case ROLE_GUILD.member: return 'Guild Member'
    }
  }

  const getListGuild = async () => {
    setLoading(true)
    const [error, result]: any = await handleAsyncRequest(guildApi.getListGuildMember(guild_id ? guild_id : profile.guild_id, { ...params }))
    setLoading(false)

    if (error) {
      openNotification('ERROR', error.message || error.errors[0]?.message)
    }

    if (result) {
      const formatData = result.data.records.map((record: any) => {
        return {
          ...record,
          member: shortenUserName(record.user_name),
          winRate: handleCareer(record.career),
          position: roleType(record.role),
          role: record.role
        }
      })
      setGuildMembers(params.page > 1 ? [...guildMembers, ...formatData] : formatData)
      setTotal(result.data?.total || 0)
    }

    setLoading(false)
  }

  const kichMemberOutGuild = async () => {
    setLoading(true)
    const [error, result]: any = await handleAsyncRequest(guildApi.putKickMemberOutGuild(profile.guild_id && profile.guild_id, memberSelect?.id))
    setLoading(false)

    if (error) {
      openNotification('ERROR', error.message || error.errors[0]?.message)
      setShowModalConfirmJoinGuild()
    }
    if (result) {
      openNotification('successfully', "You have removed this user from your guild")
      setShowModalConfirmJoinGuild()
      setUpdateRole()
      setShowModalManageMemberGuild()
    }
    setLoading(false)
  }


  const updateRoleUserGuild = async (role: string) => {
    setLoading(true)
    const [error, result]: any = await handleAsyncRequest(guildApi.putRoleUserGuild(profile.guild_id && profile.guild_id, memberSelect?.id, { role }))
    setLoading(false)
    if (error) {
      openNotification('ERROR', error.message || error.errors[0]?.message)
    }
    if (result) {
      openNotification('success', role === ROLE_GUILD.manager ? 'This member has been promoted' : 'This member has been demoted')
      setUpdateRole()
      setMemberSelect({ ...memberSelect, position: role === ROLE_GUILD.manager ? 'Guild Manager' : 'Guild Member', role: role })
    }
    setLoading(false)
    // setShowModalManageMemberGuild()
    setShowModalConfirmPromoteGuild(false)
    setShowModalConfirmDowngradeGuild(false)
  }

  const putLeaveGuild = async () => {
    kichMemberOutGuild()
  }

  const handleCareer = (career: Career) => {
    const totalRace = career?.total_number_of_races
    const firstClass = career?.first_count
    const secondClass = career?.second_count
    const thirdClass = career?.third_count
    const tltClass = (firstClass + secondClass + thirdClass) / totalRace
    const newTltClass = isNaN(tltClass) ? 0 : tltClass * 100
    const winRateResult = `${Number.isInteger((firstClass / totalRace) * 100)
      ? (firstClass / totalRace) * 100
      : ((firstClass / totalRace) * 100).toFixed(2)
      }% - ${Number.isInteger(newTltClass) ? newTltClass : newTltClass.toFixed(2)}%`
    let valueDisplay = ''
    if (totalRace === 0) {
      valueDisplay = '---'
    }
    if (totalRace > 0 && firstClass === 0 && secondClass === 0 && thirdClass === 0) {
      valueDisplay = '0.00% - 0.00%'
    }
    if (totalRace !== 0 && (firstClass !== 0 || secondClass !== 0 || thirdClass !== 0)) {
      valueDisplay = winRateResult
    }
    return valueDisplay
  }

  const onClickManageMember = (value: any) => {
    navigate(links.guild.detailMember(guild_id ? guild_id : profile.guild_id, value.id))
  }


  const onClickPromote = () => {
    setShowModalConfirmPromoteGuild()
  }

  const onClickDowngrade = () => {
    setShowModalConfirmDowngradeGuild()
  }
  const onClickGuildOut = () => {
    setShowModalConfirmJoinGuild()
  }

  const handleSearchValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const handleSortAscDesc = (item: string) => {
    const handleKeySort = () => {
      switch (item) {
        case GuildListSortType.RankingAsc:
          return 'rank-asc'
        case GuildListSortType.RankingDesc:
          return 'rank-desc'
        case GuildListSortType.Ascending:
          return 'user_name-asc'
        case GuildListSortType.Decrease:
          return 'user_name-desc'
        default:
          return 'rank-desc'
      }
    }
    selectRef.current = handleKeySort()
    setParams({
      ...params, page: 1, sort: selectRef.current, search: debounceSearchValue
    })
    setClassSelect(item)
  }

  const handleRowItemClick = (value: any) => {
    navigate(links.guild.detailMember(guild_id ? guild_id : profile.guild_id, value))
  }

  return (
    <MembersStyled>
      {contextHolder}
      <div className='search-box'>
        <SelectCustom
          dataSelect={defaultSortType}
          nameSelect={classSelect}
          onSelected={handleSortAscDesc}
          customClass='select-custom'
        />
        <SearchInput
          searchValue={searchValue}
          handleSearchValueChanged={handleSearchValueChanged}
          customClass='search-horse-input'
          placeholder={t(`${GUILD_MESSAGE}.search_member`)}
        />
      </div>
      <div className='members'>
        <RaceTable
          columns={memoizedResultListSelectionColumns}
          data={guildMembers ?? []}
          raisePage={setParams}
          loader={!loading}
          params={params}
          isRowClickable
          checkLoadingPage={false}
          totalRace={total}
          currentUser={profile}
          noDataText={t(`${NOTIFICATION_MESSAGE}.dataNotFound`)}
          onClickOneCell={(id) => onClickManageMember(id)}
          onRowClick={handleRowItemClick}
        />
      </div>
      {showModalManageMemberGuild && (
        <ModalManageMember
          onClose={setShowModalManageMemberGuild}
          loading={loadingModal}
          memberInfor={memberSelect}
          onClickPromote={onClickPromote}
          onClickDowngrade={onClickDowngrade}
          onClickGuildOut={onClickGuildOut}
        />
      )}

      {showModalConfirmJoinGuild && (
        <ModalConfirmJoinGuild
          onClose={setShowModalConfirmJoinGuild}
          onConfirm={putLeaveGuild}
          loading={loadingModal}
          title='Confirm'
          message={
            <div>
              Only expel members when they violate guild rules.
              <br />
              Are you sure you want to kick this member?
            </div>
          }
        />
      )}

      {showModalConfirmPromoteGuild && (
        <ModalConfirmJoinGuild
          onClose={setShowModalConfirmPromoteGuild}
          onConfirm={() => updateRoleUserGuild(ROLE_GUILD.manager)}
          loading={loadingModal}
          title='Confirm'
          message={
            <div>
              You are promoting this member.
              <br />
              Are you sure?
            </div>
          }
        />
      )}

      {showModalConfirmDowngradeGuild && (
        <ModalConfirmJoinGuild
          onClose={setShowModalConfirmDowngradeGuild}
          onConfirm={() => updateRoleUserGuild(ROLE_GUILD.member)}
          loading={loadingModal}
          title='Confirm'
          message={<div>
            You are demoting this member.
            <br />
            Are you sure?
          </div>}
        />
      )}
    </MembersStyled>
  )
}