export default {
  baseApiUrl: process.env.REACT_APP_API_BASE_URL,
  baseSocketUrl: process.env.REACT_APP_BASE_WS_URL ?? 'https://dev-api-meta-horse.bappartners.com/stomp',
  tokenMARE: process.env.REACT_APP_TOKEN_MARE ?? '0',
  tokenGate: process.env.REACT_APP_TOKEN_GATE ?? '0',
  tokenEMAS: process.env.REACT_APP_TOKEN_EMAS ?? '0',
  linkH2H: process.env.REACT_APP_LINK_H2H ?? 'https://dev.head-2-head.net/register',
  linkYoutube: process.env.REACT_APP_LINK_YOUTUBE,
  linkTwitch: process.env.REACT_APP_LINK_TWITCH,
  chainId: process.env.REACT_APP_CHAIN_ID,
  chainIdDirect: process.env.REACT_APP_CHAIN_ID_DIRECT,
  linkRpcUrls: process.env.REACT_APP_SPEC_TRE,
  nameChain: process.env.REACT_APP_CHAIN_NAME,
  nameCurrency: process.env.REACT_APP_CURRENCY_NAME,
  linkBlockExplorerUrls: process.env.REACT_APP_LINK_BLOCK_EXPLORERURLS ?? 'https://testnet.spectre-scan.io/',
  valueDecimals: process.env.REACT_APP_VALUE_DECIMALS ?? 18,
  linkAndroid:
    process.env.REACT_APP_LINK_ANDROID ??
    ' https://meta-horse-prd-general.oss-ap-northeast-1.aliyuncs.com/_apk-files/metahorse_android_1.0.2.60_betatest.apk',
  linkIOS: process.env.REACT_APP_LINK_IOS ?? 'https://testflight.apple.com/join/EFZgK0qz',
  horseNFT: process.env.REACT_APP_HORSE_NFT ?? '0',
  horseFarm: process.env.REACT_APP_HORSE_FARM ?? '0',
  transporter: process.env.REACT_APP_HORSE_TRANSPORTER ?? '0',
  linkAirdropH2H: process.env.REACT_APP_LINK_AIRDROP_H2H ?? 'https://airdrop.head-2-head.net/',
  specialHorseFarm: process.env.REACT_APP_SPECIAL_HORSE_FARM ?? '0x9882175164329CC92a5701D497A3b9930113A991',
  guildFarm: process.env.REACT_APP_GUILD_FARM ?? ''
}
