/* eslint-disable @typescript-eslint/no-explicit-any */
import { CLOSE_BTN, FRAME_BOT, BUTTON_CHANGE, BUTTON_REMOVE } from 'assets/images'
import { Modal } from 'shared'
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next'
import HourseModalStyle from './styled'
import { ITEMS_MESSAGE } from 'i18n/constants'
interface HorseModalRaceViewProps {
  onOverlayClick?: () => void
  onCloseButtonClick?: () => void
  onRemove: () => void
  onChange: () => void
  dataPreview?: any
  isLoading: boolean
}

function HorseModalConfirmRemoveItem({ 
  onOverlayClick,
  onRemove,
  onChange,
  onCloseButtonClick,
  dataPreview,
  isLoading
}: HorseModalRaceViewProps) {
  const { t } = useTranslation()
  
  return (
    <Modal onOverlayClick={onOverlayClick}>
      <HourseModalStyle>
        <div className='choose-horse-modal'>
          <button className='close-btn p-0 position-absolute' role='button' onClick={onCloseButtonClick}>
            <img src={CLOSE_BTN} alt='close' />
          </button>
          <div className='confirm-horse'>
            <h4 className='color-white'>
              {t(`${ITEMS_MESSAGE}.item_info`)}
              {isLoading ? '' : 
                ( <img className='frame' src={FRAME_BOT} alt={dataPreview?.boost?.name} /> )
              }
            </h4>
            {isLoading ? (
              <span className='btn-name d-flex align-items-center justify-content-center'>
                {isLoading && <Spin size="large" className='mt-5 mr-3' />}
              </span>
            ) : (
              <>
                <div className="horse-detail d-flex align-items-center">
                  <div className="boots-img">
                    <img src={dataPreview?.boost?.img} alt={dataPreview?.boost?.name} />
                  </div>
                  <div className="des">
                    <p className='color-white'>{dataPreview?.boost.name}</p>
                    <span>{t(`${ITEMS_MESSAGE}.type`)}: {dataPreview?.boost.effect_type}</span>
                  </div>
                </div>
                <div className="des-meta">
                  <p className='color-white'>{t(`${ITEMS_MESSAGE}.attribute`)}</p>
                  <span>{dataPreview?.boost.effect.effect.master_effect_type} +{dataPreview?.boost.effect.effect.value}{dataPreview.boost.effect.effect.unit == 'PERCENT' ? '%' : ''}</span>
                </div>
                <div className="des-meta">
                  <p className='color-white'>{t(`${ITEMS_MESSAGE}.information`)}</p>
                  <span>{dataPreview?.boost?.description}</span>
                </div>
              </>
            )}
            <div className='confirm-horse-btns d-flex align-items-center justify-content-center'>
              <button onClick={onChange} className='confirm-btn'>
                <img src={BUTTON_CHANGE} alt='confirm' />
              </button>
              <button onClick={onRemove} className='confirm-btn'>
                <img src={BUTTON_REMOVE} alt='confirm' />
              </button>
            </div>
          </div>
        </div>
      </HourseModalStyle>
    </Modal>
  )
}

export default HorseModalConfirmRemoveItem
