import styled from 'styled-components'

const HeadContainer = styled.div`
  margin-bottom: 12px;
  width: 100%;
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.media.lessThan(theme.size.sm)} {
    overflow-x: scroll;
  }

  .title-tabs {
    column-gap: 10px;

    ${({ theme }) => theme.media.lessThan(theme.size.sm)} {
      width: fit-content;
    }
    font-size: 32px;
    .tab-link {
      ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
        font-size: 26px;
      }

      ${({ theme }) => theme.media.lessThan(theme.size.md)} {
        font-size: 20px;
        line-height: 20px;
      }

      ${({ theme }) => theme.media.lessThan(theme.size.sm)} {
        min-width: 160px;
        width: auto;
      }
    }

    .text {
      margin-bottom: 15px;
    }
  }
`

export default HeadContainer
