/* eslint-disable @typescript-eslint/no-explicit-any */
import ProgressCircle from 'features/Guild/components/ProgressCircle'
import RaceBoxContainer from './styled'

interface RaceBoxProps {
  data: any
}

const RaceBox = ({ data }: RaceBoxProps) => {

  return (
    <RaceBoxContainer>
      <div className='left'>
        <div className='total'>
          <p className='title'>Total race</p>
          <span>{data?.career?.total_number_of_races}</span>
        </div>
        <div>
          <p className='title'>Career</p>
          <div className='row-career first'>
            <span>1st</span>
            <span>{data?.career?.first_count}</span>
          </div>
          <div className='row-career second'>
            <span>2st</span>
            <span>{data?.career?.second_count}</span>
          </div>
          <div className='row-career thrid'>
            <span>3st</span>
            <span>{data?.career?.third_count}</span>
          </div>
        </div>
      </div>
      <ProgressCircle career={data?.career} />
    </RaceBoxContainer>
  )
}

export default RaceBox
