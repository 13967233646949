/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames'
import { ChangeEvent } from 'react'
import { Input } from 'antd';

import { ONE_LINE } from 'assets/images'
import InputStyled from './styled'

const { TextArea } = Input;

interface InputProps {
  value?: string
  onChange?: (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => void
  customClass?: string
  placeholder?: string
  type?: string
  minLength?: number
  maxLength?: number
  name?: string
  width?: number
  disabled?: boolean
  onKeyDown?: (e: never) => void
  addonAfter?:string
}

function InputCustom({
  value,
  onChange,
  placeholder,
  customClass = '',
  type,
  minLength = 0,
  maxLength,
  name,
  width,
  disabled = false,
  onKeyDown,
  addonAfter,
  ...props
}: InputProps) {
  const searchInputClass = classNames('position-relative', 'd-flex', 'align-items-center', customClass)

  const _onChange = (v: any) => {
    onChange && onChange(v?.target?.value)
  }
  return (
    <InputStyled className={searchInputClass} width={width}>
      <img src={ONE_LINE} alt='' className='orange-line position-absolute' />

      {type === 'textarea' ? (
        <TextArea
          name={name}
          rows={4}
          cols={50}
          className={`search-input flex-grow-1 ${customClass}`}
          value={value || ''}
          onChange={onChange}
          minLength={minLength}
          maxLength={maxLength}
          disabled={disabled}
          {...props}
        ></TextArea>
      ) : (
        <Input
          type={type || 'text'}
          className={`search-input flex-grow-1 ${disabled ? 'input_opacity' : ''}`}
          placeholder={placeholder}
          value={value || ''}
          onChange={_onChange}
          minLength={minLength}
          maxLength={maxLength}
          name={name}
          onKeyDown={onKeyDown}
          disabled={disabled}
          addonAfter={addonAfter}
          {...props}
        />
      )}
    </InputStyled>
  )
}

export default InputCustom
