import styled from 'styled-components'

const SubMenuStyled = styled.div`
  background: linear-gradient(90deg, #4ef076 0%, rgba(72, 247, 186, 0) 100%);
  width: 256px;
  ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark {
    background: #252b43;
  }
  .title-main {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-menu-item-group-title {
    background: linear-gradient(90deg, #4ef076 0%, rgba(72, 247, 186, 0) 100%);
  }
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #001529;
    color: #4ef076;
    font-weight: 600;
  }
  .ant-menu-root > li:nth-child(1),
  .ant-menu-root > li:nth-child(5) {
    padding: 0px !important;
    color: white;
    font-weight: 400;
  }
`

export default SubMenuStyled
