import styled from 'styled-components'

export const ItemDetailsStyled = styled.div`
  width: 100%;
  color: white;
  padding: 20px;
  .container {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 20px 100%, 0 calc(100% - 20px));
    background: #7a83a6;
    padding: 1px;
    .item-block {
      background: #191d2c;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 20px 100%, 0 calc(100% - 20px));
      position: relative;
      .body-item {
        padding: 30px;
        .item {
          display: flex;
          .avatar {
            position: relative;
            .item {
              width: 300px;
              height: 300px;
            }
            .avatar-img{
              position: absolute;
              left: 50%;
              -webkit-transform: translate(-50%,0);
              -ms-transform: translate(-50%,0);
              transform: translate(-50%,0);
              height: 100px;
              top: 15%;
            }
          }
          .name {
            padding: 0px 20px;
            .item-name {
              font-size: 20px;
              font-weight: 400;
            }
            .field-name {
              font-size: 18px;
              font-weight: 400;
              line-height: 37px;
              color: #fff566;
            }
          }
        }
        .information {
          margin-top: 20px;
          border: 1px solid #47f0b1;
          padding: 20px;
          .information-box {
            display: flex;
            gap: 20px;
            font-size: 18px;
            margin-bottom: 10px;
            cursor: pointer;
            .active {
              color: #4ef076;
            }
          }
        }
      }
    }
  }
  .price{
    margin-bottom: 10px;
    img{
      width: 15px;
      margin-right: 5px;
    }
  }
`

export const TitleStyled = styled.div`
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0px;
  color: #4ef076;
  display: flex;
  justify-content: center;
`

export const BtnBacktSyled = styled.div`
  .btn-back {
    width: fit-content;
    cursor: pointer;
    display: flex;
    .text-btn {
      font-size: 20px;
    }
  }
`

export const ButtonSyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  flex-direction: column;
`
