import styled from 'styled-components'
interface ItemListInfomationStyledProp {
  total?: number
}

const ItemListInfomationStyled = styled.div<ItemListInfomationStyledProp>`
  width: 1232px;
  .my-item {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    .nav {
      a {
        text-decoration: none;
        text-transform: uppercase;
        font-size: 24px;
        margin-right: 28px;
        &:hover {
          color: #4ef076;
        }
        &.item {
          position: relative;
          color: #4ef076;
          img {
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 86px;
          }
        }
      }
    }
  }
  .body-list-item {
    background: #21273c;
    position: relative;
    margin-top: 46px;
    padding: 36px 45px;
    &:before {
      content: '';
      position: absolute;
      height: 1px;
      width: 100%;
      background: #000;
      top: -23px;
      left: 0;
    }
    .tab-block {
      .tab-link {
        cursor: pointer;
        width: 180px;
        height: 55px;
        margin-right: 37px;
        background: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        clip-path: polygon(6% 0, 100% 0, 100% 80%, 95% 100%, 0 100%, 0 23%);
        transition: transform 0.5s ease-out;

        &.active,
        &:hover {
          background-image: linear-gradient(#4ff077, #48f7b9);
          transform: translate(0, -2px);
        }
      }

      .store-item {
        cursor: pointer;
        transition: transform 0.5s ease-out;
        &:hover {
          transform: translate(0, -2px);
        }
        img {
          width: 65px;
        }
      }
    }
    .tab-container {
      margin-top: 35px;
      border: 2px solid #1fcef0;
      padding: 33px;
      padding-bottom: 0;
      border-radius: 0 0 16px 16px;
      flex-wrap: wrap;
      height: 460px;
      overflow-y: auto;
      overflow-x: hidden;

      .item-list {
        width: 183px;
        /* clip-path: polygon(7% 0, 84% 0, 100% 19%, 100% 100%, 0 100%, 0 8%);
        background: #515460; */
        padding: 1px;
        margin-right: 34px;
        margin-bottom: 34px;
        cursor: pointer;

        &:nth-child(5n) {
          margin-right: 0px;
        }
        .item-line {
          padding: 33px 0px;
          clip-path: polygon(7% 0, 84% 0, 100% 19%, 100% 100%, 0 100%, 0 8%);
          background: #21273c;

          &.active,
          &:hover {
            background-image: linear-gradient(#4ff077, #48f7b9);
          }
          img {
            width: 105px;
          }
        }
      }
      .view-more-container {
        width: 100%;
        display: flex;
        justify-content: center;
        height: 80px;
        .btn-view-more {
          background: linear-gradient(
            178.19deg,
            rgb(240 241 240 / 82%) 1.64%,
            rgb(235 245 241 / 48%) 47.93%,
            rgb(251 251 251 / 63%) 97.84%
          );
          border-radius: 4px;
          height: 48px;
          width: 250px;
          font-size: 16px;
          &-text {
            margin: 16px;
            color: white;
          }
        }
        .btn-view-more:hover {
          background: linear-gradient(
            178.19deg,
            rgb(120 229 147 / 82%) 1.64%,
            rgb(52 255 184 / 48%) 47.93%,
            rgb(78 240 118 / 63%) 97.84%
          );
        }
        .btn-view-more:after {
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #f9fafb;
          content: '';
          position: relative;
          right: -5px;
          top: 11px;
        }
      }
    }
    .infinite-scroll-component::-webkit-scrollbar-track {
      border: 1px solid #000;
      padding: 3px 0;
      background-color: #000;
      margin-bottom: 20px;
    }

    .infinite-scroll-component::-webkit-scrollbar {
      width: 5px;
    }

    .infinite-scroll-component::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #2f323e;
      border: 1px solid #000;
    }
    .no-data {
      color: #ff7a00;
      font-size: 20px;
      display: flex;
      justify-content: center;
      text-align: center;
      width: 100%;
      text-transform: uppercase;
    }
  }
`

export default ItemListInfomationStyled
