import styled from 'styled-components'

const RaceBoxContainer = styled.div`
  display: flex;

  .left {
    margin-right: 20px;
    .total {
      span {
        font-size: 20px;
        color: #fff;
      }
    }
    .title {
      font-size: 16px;
      color: #4ef076;
      margin-bottom: 0;
    }

    .row-career {
      display: flex;
      align-items: center;
      span {
        color: #fff;

        &:first-child {
          padding-right: 10px;
          font-size: 20px;
        }
        &:last-child {
          font-size: 18px;
        }
      }

      &.first {
        span {
          &:first-child {
            background: linear-gradient(90deg, #ffd901 0%, #fd6401 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
          }
         
        }
      }
      &.second {
        span {
          &:first-child {
            background: linear-gradient(90deg, #F2F2F2 0%, #4C4C4C 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
          }
         
        }
      }
      &.thrid {
        span {
          &:first-child {
            background: linear-gradient(90deg, #E69D68 0%, #7E3F1B 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
          }
         
        }
      }
    }
  }
`

export default RaceBoxContainer
