import { ARMOR_BODY_ICON, ARMOR_FOOT_ICON, ARMOR_HEAD_ICON, SKIN_HORSE_ICON } from "assets/images"

/* eslint-disable @typescript-eslint/no-explicit-any */
const checkExistItem = (type: string, items: any) => {
  if (items.length <= 0) return null

  const res = items?.find((item: any) => item?.body_part === type)
  return res
}

const getImageItem = (type: string) => {
  switch(type) {
    case 'HEAD':
      return ARMOR_HEAD_ICON;
    case 'BODY':
      return ARMOR_BODY_ICON;
    case 'LEG':
      return ARMOR_FOOT_ICON
    default:
      return SKIN_HORSE_ICON
  }
}

export { checkExistItem, getImageItem }
