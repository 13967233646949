/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiResponse } from 'models'
import axiosClient from './axiosClient'

const itemShopsApi = {
  putBosstItem(
    data: { horseId: string | number },
    params: { recover_type?: string; pay_for_energy?: boolean }
  ): Promise<ApiResponse<any>> {
    const url = `/items/horses/${data.horseId}/energy`
    return axiosClient.put(url, params)
  },

  getItemShops(params?: any): Promise<ApiResponse<any>> {
    const url = '/item-shops'
    return axiosClient.get(url, { params })
  },

  getItemShopsDetails(id: number | string): Promise<ApiResponse<any>> {
    const url = `/item-shops/${id}/details`
    return axiosClient.get(url)
  },

  getItemInventory(params?: any): Promise<ApiResponse<any>> {
    const url = '/items/users/boost'
    return axiosClient.get(url, { params })
  },
  getItemInventoryDetail(userId: any, boostId: any): Promise<ApiResponse<any>> {
    const url = `/items/user/${userId}/boost/${boostId}/equipped-detail`
    return axiosClient.get(url)
  }
}

export default itemShopsApi
