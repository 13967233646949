export interface ShopResponse {
  limit: number
  page: number
  records: ItemShop[]
  total: number
  total_page: number
}

export interface ItemShop {
  amount: number
  boost: {
    boost_type: string
    currency: string
    description: string
    effect: {
      effect: {
        master_effect_type: string
        unit: string
        value: string
      }
      recover: string
    }
    effect_type: string
    id: string
    img: string
    level: string
    name: string
    price: string
    rarity_type: string
  }
  currency: string
  distributor: string
  id: string
  item: {
    active: true
    body_part: string
    color: string
    description: string
    effect: {
      effect: [
        {
          master_effect_type: string
          unit: string
          value: string
        }
      ]
      recover: string
    }
    id: string
    img: string
    item_set_id: string
    item_token: string
    name: string
    own_status: string
    rarity_type: string
    status: string
  }
  item_info: string
  item_type: string
  owner: {
    avatar: string
    description: string
    email: string
    guild_tag: string
    h2h_id: string
    id: string
    name: string
    public_address: string
  }
  price: string
}

export interface ItemShopsParams {
  limit: number
  page: number
  name?: string
  search?: string | null
  sort?: string
  bodyPart?: string
  boostType?: string
  distributor?: string
  rarity?: string
  yourItems?: boolean
}

export interface ItemDetailsParams {
  itemShopId: number | string
}

export enum ShopTypeBodyPart {
  HEAD = 'inventory_head',
  BODY = 'inventory_body',
  LEG = 'inventory_leg',
  SKIN = 'inventory_skin',
  ENERGY = 'inventory_energy',
  DOPING = 'inventory_doping',
}

export interface ShopInventoryParams {
  limit: number
  page: number
  name?: string
  bodyPart?: string
  search?: string | null
  sort?: string
  filter?: string
  rarity?: string
  boostType?: string
}