import { configs, constants, paths } from 'apps'
import { GAS_CONFIG, MAX_ID, MAX_TIME_ENERGY, OPERATING_SYSTEM } from 'apps/constants'
import { GUILDFARM_TAG, INFARM_TAG, ONMARKET_TAG, RENTING_TAG } from 'assets/images'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc.js'
import { ethers } from 'ethers'
import { ELeaseType, GUILD_STATUS, Horse, HorseAbility, LENDING_STATUS, LENDING_TYPE, SpecialSortName } from 'models'
import SockJS from 'sockjs-client'
import Stomp from 'stompjs'
/* eslint-disable @typescript-eslint/no-explicit-any */
export function ordinalSuffixOf(number: number): string {
  const j = number % 10
  const k = number % 100

  if (!number) return ''
  if (j == 1 && k != 11) {
    return number + `<span class="suffix">st</span>`
  }

  if (j == 2 && k != 12) {
    return number + `<span class="suffix">nd</span>`
  }

  if (j == 3 && k != 13) {
    return number + `<span class="suffix">rd</span>`
  }

  return number + `<span class="suffix">th</span>`
}

export const formatTime = (millis: number) => {
  if (millis < 0) return '00:00:00'
  const date = new Date(millis)
  const seconds = date.getUTCSeconds()
  const minutes = date.getUTCMinutes()
  const hours = date.getUTCHours()
  return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${
    seconds < 10 ? '0' + seconds : seconds
  }`
}

export const formatTimeMS = (millis: number) => {
  if (millis < 0) return '00:00:00'
  const date = new Date(millis)
  const seconds = date.getUTCSeconds()
  const minutes = date.getUTCMinutes()
  return `${minutes + 'm'}:${seconds + 's'}`
}

export const formatTimeV2 = (millis: number, random = false) => {
  if (millis < 0) return '00:00:00'
  const date = new Date(millis)
  const seconds = date.getUTCSeconds()
  const minutes = date.getUTCMinutes()
  const milliSeconds = random ? Math.floor(Math.random() * 99) + 1 : date.getMilliseconds() / 10
  return `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}:${milliSeconds}`
}

export const formatStatsRank = (statsRank: string) => {
  return statsRank?.replace('_PLUS', '+')
}

export function getBearerToken(token: string) {
  return token ? `Bearer ${token}` : ''
}

export function isObjectEmptyArray(candidate: any): candidate is [] {
  if (!Array.isArray(candidate)) return false
  if (candidate.length > 0) return false
  return true
}

export async function handleAsyncRequest<D>(promise: Promise<D>): Promise<[unknown, D?]> {
  const disableAccount = 'User account is disabled'
  const loggedAccount = 'Your account is currently logged onto another device'
  const errorMessageCommon = 'Error in running checked-exception to unchecked-exception'
  try {
    const data: D = await promise
    return [undefined, data]
  } catch (error: any) {
    if (error?.code === 403 || error?.code === -32002) {
      localStorage.setItem(constants.ACCESS_TOKEN_KEY, 'null')
      localStorage.setItem(constants.REFRESH_TOKEN_KEY, 'null')
      localStorage.setItem(constants.USER_ID_KEY, 'null')
    }
    if (error?.status === 401 && localStorage?.getItem(constants.ACCESS_TOKEN_KEY)) {
      removeDataAtLocalStorage()
    }

    if (error?.status === 401 && error?.error === disableAccount) {
      window.location.href = '/disable-account'
    }

    if (error?.status === 401 && error?.error === loggedAccount) {
      window.location.href = '/logged-account'
    }

    if (error?.code === 404) {
      // window.location.href = paths.notFound.feature()
    }

    if (error.code === 503) {
      if (window.location.pathname !== paths.auth.login()) {
        window.location.href = '/maintenace'
      }
    }

    if (error.code === 400 && error?.message && error?.message.includes(errorMessageCommon)) {
      const message = error?.message?.slice(errorMessageCommon.length + 1)
      return [{ ...error, message }, undefined]
    }

    return [error, undefined]
  }
}

export function removeCharacterEnd(text: string | undefined) {
  if (!text || text === 'FreeStyle' || text === 'All' || text === 'TURF' || text === 'DIRT') {
    return ''
  }

  return text.slice(0, -1)
}

export function capitalizeOnlyFirstLetter(text: string | undefined) {
  if (!text) {
    return ''
  }

  if (text === 'IQ') {
    const firstLetter = text[0].toUpperCase()
    const secondLetter = text[1].toUpperCase()
    const theRestText = text.slice(2).toLowerCase()

    return firstLetter + secondLetter + theRestText
  }

  const firstLetter = text[0].toUpperCase()
  const theRestText = text.slice(1).toLowerCase()

  return firstLetter + theRestText
}

export function getCurrentEnergyPercent(currentEnergy: number | null, maxEnergy: number | null) {
  currentEnergy = currentEnergy || 0
  maxEnergy = maxEnergy || 100

  return Math.round((100 * currentEnergy) / maxEnergy)
}

export function getEnergyPercent(firstTime: number | null) {
  firstTime = firstTime || 0
  if (firstTime <= 0) {
    return 100
  } else return 100 - (firstTime * 100) / MAX_TIME_ENERGY
}

export function getNumberOfMailBox(lengthSeenRace: number, lengthSeenEvent: number, lengthSeenSystem: number) {
  return lengthSeenRace + lengthSeenEvent + lengthSeenSystem
}

export function getNumberAllMailRead(lengthSeenRace: number, lengthSeenEvent: number, lengthSeenSystem: number) {
  return lengthSeenRace + lengthSeenEvent + lengthSeenSystem
}

export function shortenRaceName(raceName?: string) {
  if (!raceName) {
    return ''
  }

  if (raceName.length > 17) {
    raceName = `${raceName.slice(0, 14)}...`
  }

  return raceName
}

export function shortenUserName(name?: string) {
  if (!name) {
    return ''
  }

  if (name.length > 25) {
    return `${name.slice(0, 6)}...${name.slice(-4)}`
  }

  return name
}

export function shortenRaceCourseNameClone(name?: string) {
  if (!name) {
    return ''
  }

  let newName = name

  if (newName.length > 25) {
    newName = `${newName.slice(0, 25)}...`
  }
  return newName
}

export function shortenRaceCourseName(name?: string) {
  if (!name) {
    return ''
  }

  let newName = name

  if (newName.length > 20) {
    newName = `${newName.slice(0, 15)}...`
  }
  return newName
}

export function shortenRaceMailTitle(name?: string) {
  if (!name) {
    return ''
  }

  let newName = name

  if (newName.length > 20) {
    newName = `${newName.slice(0, 20)}...`
  }
  return newName
}

export function shortenRaceMailContent(name?: string) {
  if (!name) {
    return ''
  }

  let newName = name

  if (newName.length > 96) {
    newName = `${newName.slice(0, 80)}...`
  }
  return newName
}

export function handleGroupingsOfThreeNumbers(value: number) {
  return value
    .toString()
    .split(/(?=(?:\d{3})+(?:\.|$))/g)
    .join(',')
}

export function numberWithCommas(n?: number | string) {
  if (!n) return 0
  const parts = n.toString().split('.')
  return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (parts[1] ? '.' + parts[1] : '')
}

export function convertStringToNumber(value?: string) {
  if (!value) {
    return 0
  }

  return Number(value)
}

export const connectSocket = () => {
  const link = configs.baseSocketUrl
  return new Promise(function (resolve, reject) {
    const stomp = Stomp.over(new SockJS(link)) as Stomp.Client
    stomp.connect(
      {},
      () => {
        resolve(stomp)
      },
      (err: Stomp.Frame | string) => {
        reject(err)
      }
    )
  })
}

export const isoDateFormat = (value: Date = new Date()) => {
  return dayjs(value).toISOString()
}

export const checkFormatId = (_id: string) => {
  if (isNaN(+_id) || parseInt(_id) > MAX_ID) {
    window.location.href = paths.notFound.feature()
  }
}

export const removeDataAtLocalStorage = () => {
  localStorage.removeItem(constants.ACCESS_TOKEN_KEY)
  localStorage.removeItem(constants.REFRESH_TOKEN_KEY)
  localStorage.removeItem(constants.USER_ID_KEY)
  // START: 20231113 TQViet [PRIVATE_WALLET-407]-[Intergration_MH] Create Pop-up Adil Wallet MetaHorse
  localStorage.removeItem(constants.WALLET_IN_USE)
  // END: 20231113 TQViet [PRIVATE_WALLET-407]-[Intergration_MH] Create Pop-up Adil Wallet MetaHorse
}

export const handeLeaseType = (value: string) => {
  if (value === LENDING_TYPE.Available) return ELeaseType.Available
  if (value === LENDING_TYPE.Share) return ELeaseType.Share
  return ''
}
export const convertStatusLending = (status_lending: any): any => {
  if (status_lending === 'IN_FARM') {
    return LENDING_STATUS.InFarm
  }
  if (status_lending === 'LENDING') {
    return LENDING_STATUS.LendingMarket
  } else {
    return status_lending
  }
}
export const hanldeHorseOwnerName = (horse: Horse) => {
  if (!horse) return
  const chainAddress = horse?.chain_owner_address?.toLowerCase()
  const publicAddress = horse?.user?.public_address?.toLowerCase()
  const userName = horse?.user?.name

  if (chainAddress && chainAddress === publicAddress) {
    return userName ?? publicAddress
  }
  if (chainAddress && chainAddress !== publicAddress) {
    return chainAddress
  }
  if (!chainAddress && !horse?.user) {
    if (horse.own_status === GUILD_STATUS.InGuild) {
      return 'IN GUILD'
    }
    if (horse.own_status === 'LENDING') {
      return 'LENDING'
    }
    if (horse.own_status === 'STABLE') {
      return 'IS EVENT'
    } else return userName
  } else return userName
}

export const getMobileOperatingSystem = () => {
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(navigator.userAgent)) {
    return OPERATING_SYSTEM.window
  }

  if (/android/i.test(navigator.userAgent)) {
    return OPERATING_SYSTEM.android
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    return OPERATING_SYSTEM.testflight
  }
  return OPERATING_SYSTEM.unknown
}

export const format5Decimals = (str: string | number) => {
  const num = parseFloat(str.toString())

  return Math.round(num * 100000) / 100000
}

export const convertGasPrice = (value: number) => {
  return ethers.utils.hexlify(value ? value * GAS_CONFIG : 2 * GAS_CONFIG)
}

export const convertPluralText = (value = 0, text: string) => {
  if (value <= 1) {
    return value + ' ' + text
  } else {
    return value + ' ' + text + 's'
  }
}

export const getWeekDays = (date: Date | undefined | number) => {
  const dateTime = date || new Date()
  const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
  const d = new Date(dateTime)
  const dayName = days[d.getDay()]
  return dayName
}

export const convertDateTime = (date: Date | number) => {
  return dayjs(date).format('DD/MM/YYYY')
}

// key sort api special horse
export const getKeySort = (value: string) => {
  switch (value) {
    case SpecialSortName.Newest:
      return 'in_stable_date-desc'
    case SpecialSortName.Oldest:
      return 'in_stable_date-asc'
    case SpecialSortName.ASC:
      return 'horse_name-asc'
    case SpecialSortName.Desc:
      return 'horse_name-desc'
    default:
      return 'in_stable_date-asc'
  }
}

export const sortAbilities = (ablilities: HorseAbility[]) => {
  return ablilities?.sort((a: HorseAbility, b: HorseAbility) => (a.id > b.id ? -1 : 1))
}

export const convertTimestampToDate = (value: number) => {
  if (!value) return '---'
  dayjs.extend(utc)
  const dateTime = dayjs.unix(value)
  return dateTime.format('DD/MM/YYYY')
}

export function moveIndex<T>(array: T[] | undefined, fromIndex: number, toIndex: number): T[] {
  if (!array || isObjectEmptyArray(array)) {
    return []
  }

  if (array.length <= 2) {
    return array
  }

  const element = array[fromIndex]

  array.splice(fromIndex, 1)
  array.splice(toIndex, 0, element)

  return array
}

export function moveIndexHorse(array: []) {
  if (!array || isObjectEmptyArray(array)) {
    return []
  }

  const a = [array[1], array[0], array[2]]?.filter(i => i !== undefined)

  return a
}

export function inOnlyLatin(keyPressed: any) {
  return keyPressed?.replace(/[^a-zA-Z0-9| ]/gi, '')
}

export function shortenRaceNameV2(raceName?: string, numLength = 17) {
  if (!raceName) {
    return ''
  }

  if (raceName.length > numLength) {
    raceName = `${raceName.slice(0, 6)}...${raceName.slice(-4)}`
  }

  return raceName
}

export const takeDecimalNumber = (num: number, n: number) => {
  const base = 10 ** n
  const result = Math.round(num * base) / base
  return result
}

export const convertStatusGuild = (status: string) => {
  switch (status) {
    case GUILD_STATUS.InFarm:
      return `<img src="${INFARM_TAG}" alt="infarm"/>`
    case GUILD_STATUS.GuildFarm:
      return `<img src="${GUILDFARM_TAG}" alt="guild-farm"/>`
    case GUILD_STATUS.OnMarket:
      return `<img src="${ONMARKET_TAG}" alt="onmarket"/>`
    case GUILD_STATUS.Renting:
      return `<img src="${RENTING_TAG}" alt="renting"/>`
    default:
      return status
  }
}
export const filterLendingToOnMarket = (status_lending: any): any => {
  if (status_lending === 'ON MARKET') {
    return LENDING_STATUS.Lending
  } else {
    return status_lending
  }
}

export const convertDateTimeMailLocal = (content: string) => {
  const regex = /\[\[(\d+)\]\]/g
  const matches = [...content.matchAll(regex)]

  for (const match of matches) {
    const timestamp = parseInt(match[1])
    const localTime = dayjs(timestamp * 1000).format('DD/MM/YYYY HH:mm:ss')
    content = content.replace(match[0], localTime)
  }

  const replacedContent = content.replace(
    /https:\/\/[^\s]+/g,
    (match) => `<a href="${match}" target="_blank">${match}</a>`
  );
  return replacedContent
}

export const getGuildTag = (guildTag: string) => {
  return guildTag?.length ? `[${guildTag}]` : ''
}

export const getWinRate = (user: any) => {
  let valueDisplay = ''
  const totalRace:number = user?.total_number_of_races || user?.total_race || 0
  const firstClass:number  = user?.first_count || 0
  const secondClass:number  = user?.second_count || 0
  const thirdClass:number  = user?.third_count || 0

  const tltClass = (firstClass + secondClass + thirdClass) / totalRace
  const newTltClass = isNaN(tltClass) ? 0 : tltClass * 100

  const winRateResult = `${Number.isInteger((firstClass / totalRace) * 100)
    ? (firstClass / totalRace) * 100
    : ((firstClass / totalRace) * 100).toFixed(2)
    }% - ${Number.isInteger(newTltClass) ? newTltClass : newTltClass.toFixed(2)}%`
  
  if (totalRace === 0 && firstClass === 0 && secondClass === 0 && thirdClass === 0) {
    valueDisplay = '---'
  }
  if (totalRace > 0 && firstClass === 0 && secondClass === 0 && thirdClass === 0) {
    valueDisplay = '0.00% - 0.00%'
  }
  if (totalRace !== 0 && (firstClass !== 0 || secondClass !== 0 || thirdClass !== 0)) {
    valueDisplay = winRateResult
  }
  return valueDisplay
}

// START: 20231113 TQViet [PRIVATE_WALLET-407]-[Intergration_MH] Create Pop-up Adil Wallet MetaHorse
export const isMobileDevice = () => {
  return 'ontouchstart' in window || 'onmsgesturechange' in window
}
// END: 20231113 TQViet [PRIVATE_WALLET-407]-[Intergration_MH] Create Pop-up Adil Wallet MetaHorse
