/* eslint-disable @typescript-eslint/no-explicit-any */
import { CAREER_BIRU_ICON, CAREER_MERAH_ICON } from 'assets/images'
import dayjs from 'dayjs'
import { convertPluralText } from 'utils/helper'
import IncomeBoxContainer from './styled'

interface IncomeBoxProps {
  data: any
}

const IncomeBox = ({ data }: IncomeBoxProps) => {
  const guildInfo = data?.guild_info
  const lendingInfo = data?.lending_info

  const renderRevenue = (items: any) => {
    return items.map((item: any, index: number) => (
      <div key={index} className='row-revenue'>
        <span>{item?.amount}</span>
        <img src={item.item_type === 'MERAH' ? CAREER_MERAH_ICON : CAREER_BIRU_ICON} alt='merah' />
      </div>
    ))
  }
  return (
    <IncomeBoxContainer>
      <div className='content-left'>
        <div>
          <p className='title'>Share income</p>
          <span>{guildInfo?.profit_renter ? guildInfo?.profit_renter + '%' : '---'} </span>
        </div>
        <div>
          <p className='title'>Start at</p>
          <span>{lendingInfo?.start_date ? dayjs(lendingInfo.start_date * 1000).format('DD/MM/YYYY HH:mm') : '---'}</span>
        </div>
        <div className='revenue-box'>
          <p className='title'>Total Revenue</p>
          <div className='row-revenue'>
            {data?.lending_info?.revenue ? renderRevenue(data?.lending_info?.revenue) : <span>---</span>}
          </div>
        </div>
      </div>
      <div className='content-right'>
        <div>
          <p className='title'>Duration</p>
          <span>{lendingInfo?.rent_days ? (convertPluralText(lendingInfo?.rent_days, 'day')) : '---'}</span>
        </div>
        <div>
          <p className='end'>End at</p>
          <span>{lendingInfo?.end_date ? dayjs(lendingInfo.end_date * 1000).format('DD/MM/YYYY HH:mm') : '---'}</span>
        </div>
      </div>
    </IncomeBoxContainer>
  )
}

export default IncomeBox
