import styled from 'styled-components'

const SelectItemModalStyled = styled.div`
.modal-select-item{
    width: 620px;
    background: #191D2C;
    padding: 40px 35px;
    position: relative;
    clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 40px 100%, 0px calc(100% - 40px));
    .confirm-horse{
        text-align: center;        
        h4{
            font-size: 24px;
            font-weight: 400;
            line-height: 20px;
            text-transform: uppercase;
            color: #4EF076;
            margin-bottom: 0;
        }
        .iframe{
            width: 212px;
        }
    }
    .confirm-horse-title{
        font-size: 20px;
        margin-top: 20px;
        margin-bottom: 32px;
    }
    .close-btn{
        right: 23px;
        top: 19px;
        background: transparent;
        border: none;
    }
    .confirm-horse-btns{
        button{
            background: transparent;
            border: none;
            margin: 0 16.5px;
            position: relative;
            filter: brightness(0.8);

            span{
                position: absolute;
                width: 100%;
                margin: 0 auto;
                left: 0;
                color: #fff;
                top: 8px;
                font-size: 24px;
            }
            &:hover{
                filter: brightness(1);
            }
            &.disable{
                filter: brightness(0.5);
                cursor: not-allowed;
            }
        }
    }
    .list-item{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-bottom: 40px;
        height: 183px;
        overflow: auto;
        .confirm-horse-title{
            width: 100%;
        }
        &.infinite-scroll-component::-webkit-scrollbar-track {
            border: 1px solid #000;
            padding: 2px 0;
            background-color: #000;
          }
      
        &.infinite-scroll-component::-webkit-scrollbar {
            width: 5px;
        }
      
        &.infinite-scroll-component::-webkit-scrollbar-thumb {
            border-radius: 10px;
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #2f323e;
            border: 1px solid #000;
        }
        .meta-block {
            margin-right: 20px;
            margin-bottom: 20px;
            cursor: pointer;
            &:nth-child(4n){
                margin-right: 0px;
            }
            &.active, &:hover{
                .item-block
                {
                    background-image: linear-gradient(#4FF077,#48F7B9);
                    .sub-block{
                        background-image: linear-gradient(#4FF077,#48F7B9);
                    }
                    .numb{
                        img{
                            filter: none;
                        }
                        span{
                            color: #114D06;
                        }
                    }
                }
            }
        }
        .item-block {
            clip-path: polygon(7% 0,84% 0,100% 19%,100% 100%,0 100%,0 8%);
            background: #515460;
            padding: 2px;
            .sub-block{
                background: #030317;
                clip-path: polygon(7% 0,84% 0,100% 19%,100% 100%,0 100%,0 8%);
                position: relative;
                .amount{
                    position: relative;
                    text-align: left;
                    margin-left: 5px;
                    padding-bottom: 3px;
                    .star{
                        .disable{
                            display: none;
                        }
                    }
                }
                .numb{
                    position: relative;
                    width: 31px;

                    img{
                        filter: brightness(0) invert(1);
                    }
                    span{
                        position: absolute;
                        left: 0;
                        color: #fff;
                        font-size: 10px;
                        width: 100%;
                        text-align: center;
                        bottom: 3px;
                    }
                }
                .star {
                    div{
                        width: 14px;
                        height: 14px;
                    }
                }
            }
        }
        .item-dp{
            background: transparent;
            border: none;
            padding: 17px 0;
            &:focus-visible {
                outline: none;
            }
            img {
                height: 50px;
            }
        }
        .count-item{
            position: relative;
            width: 120px;
            span{
                position: absolute;
                width: 100%;
                text-align: center;
                z-index: 1;
                top: 12px;
                left: 0px;
                font-size: 16px;
            }
            img{
                width: 100%;
                height: 44px;
                margin-top: 4px;
            }
        }
    }
}

`

export default SelectItemModalStyled
