/* eslint-disable @typescript-eslint/no-explicit-any */
import { Spin } from 'antd';
import { BG_BUTTON, BTN_CANCEL, REMOVE_BTN, BTN_SELL } from 'assets/images';
import ButtonStyled from './styled';

interface ButtonProps {
  onClickButton: () => void
  buttonName: string | React.ReactNode
  disabled?: boolean
  width?: number
  btnCancel?: boolean,
  isLoading?: boolean
  height?: number
  classNames?: any
  btnRemove?: boolean
  btnSell?: boolean
}

function Button({
  onClickButton,
  buttonName,
  disabled,
  width = 215,
  btnCancel,
  isLoading,
  height = 45,
  btnRemove,
  btnSell,
  classNames
}: ButtonProps) {

  const handleClick = () => {
    if (disabled || isLoading) return
    onClickButton()
  }

  return (
    <ButtonStyled className={classNames} >
      <div className={`btn-container-${disabled || isLoading ? 'disable' : ''}`} onClick={handleClick} >
        <img src={btnRemove? REMOVE_BTN : btnCancel ? BTN_CANCEL : (btnSell ? BTN_SELL : BG_BUTTON)} alt='' className='background-btn' width={width} height={height} />
        <span className='btn-name'>{isLoading && <Spin className='mt-2 mr-3' />}{buttonName}</span>
      </div>
    </ButtonStyled>
  )
}

export default Button
