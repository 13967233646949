import styled from 'styled-components'

const ShopeMainStyled = styled.div`
  .shop-container {
    display: flex;
    padding-top: 50px;
    .content {
      width: 100%;
    }
  }
`

export default ShopeMainStyled
