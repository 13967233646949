import styled from "styled-components"

const ButtonGroupContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  width: 975px;
  margin: 0 auto;
  padding: 40px 0px 20px 0px;

  .btn-cancel {
    margin-right: 20px;
  }

  .btn-container- {
    span {
      font-size: 18px;
    }
  }
`

export default ButtonGroupContainer