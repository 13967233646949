/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useEffect } from "react";
import classNames from 'classnames'
import { ChangeEvent } from 'react'
import styled from "styled-components";

import { ONE_LINE } from 'assets/images'
import InputStyled from './styled'

const TextArea = styled.textarea`
  padding: 2px;
  resize: none;
  overflow-y: hidden;
  min-height: 100px;
`;

interface InputProps {
  value?: string
  onChange?: (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => void
  customClass?: string
  placeholder?: string
  type?: string
  minLength?: number
  maxLength?: number
  name?: string
  disabled?: boolean
  width?: number
  style?: any
  onKeyDown?: (e: never) => void
}

function InputTextarea({
  value,
  onChange,
  customClass = '',
  minLength = 0,
  maxLength,
  name,
  disabled = false,
  width,
  style = {},
  ...props
}: InputProps) {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const searchInputClass = classNames('position-relative', 'd-flex', 'align-items-center', customClass)

  const resizeTextArea = () => {
    if (textAreaRef.current != null) {
      textAreaRef.current.style.height = "auto"
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px"
    }
  }

  useEffect(() => {
    resizeTextArea()
  }, [value])

  return (
    <InputStyled className={searchInputClass} width={width}>
      <img src={ONE_LINE} alt='' className='orange-line position-absolute' />
      <TextArea
        ref={textAreaRef}
        name={name}
        rows={4}
        cols={50}
        className={`search-input flex-grow-1 ${customClass}`}
        value={value || ''}
        onChange={onChange}
        minLength={minLength}
        maxLength={maxLength}
        disabled={disabled}
        style={style}
        {...props}
      ></TextArea>
    </InputStyled>
  )
}

export default InputTextarea
