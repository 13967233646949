import styled from 'styled-components'

const ItemDetailContainer = styled.div`
  min-height: 274px;
  position: relative;
  background-color: #191d2c;
  -webkit-clip-path: polygon(0% 0%, 0 90%, 100% 300%, 100% 100%, 100% 0%);
  clip-path: polygon(0% 0%, 0 90%, 100% 300%, 100% 100%, 100% 0%);
  padding: 40px;

  &:before {
    content: '';
    position: absolute;
    min-height: 272px;
    top: 1px;
    left: 1px;
    background-color: #191d2c;
    display: block;
    -webkit-clip-path: polygon(0% 0%, 0 90%, 100% 300%, 100% 100%, 100% 0%);
    clip-path: polygon(0% 0%, 0 90%, 100% 300%, 100% 100%, 100% 0%);
  }

  &.HEAD {
    .attribute-content {
      .avatar-box {
        display: flex;
        justify-content: flex-start;
        .avatar {
          width: 142px;
          height: 142px;

          img {
            transform: translateX(125px) translateY(80px) scale(3);
          }
        }
      }
    }
  }

  .attribute-content {
    .avatar-box {
      display: flex;
      justify-content: flex-start;
      .avatar {
        width: 142px;
        height: 142px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .info {
        background: linear-gradient(90deg, #044817 0%, #0f121c 100%);
        flex: 1;
        padding: 20px;
        p {
          color: #fff;
        }
        .title {
          font-size: 24px;
          font-weight: 400;
          line-height: 20px;
          text-transform: uppercase;
        }
      }
    }

    .content-box {
      margin: 20px 0;

      .attribute-head {
        position: relative;
        background-color: #0f121c;
        height: 48px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 10px;
        clip-path: polygon(
          100% 0%,
          70% 0%,
          30% 0%,
          0% 0%,
          0% 25%,
          0% 65%,
          2% 100%,
          6% 100%,
          39% 100%,
          64% 100%,
          100% 100%,
          100% 73%,
          100% 47%,
          100% 25%
        );
        -webkit-clip-path: polygon(
          100% 0%,
          70% 0%,
          30% 0%,
          0% 0%,
          0% 25%,
          0% 65%,
          2% 100%,
          6% 100%,
          39% 100%,
          64% 100%,
          100% 100%,
          100% 73%,
          100% 47%,
          100% 25%
        );

        &:before {
          content: '';
          position: absolute;
          top: 1px;
          left: 1px;
          background-color: #0f121c;
          display: block;
          clip-path: polygon(
            100% 0%,
            70% 0%,
            30% 0%,
            0% 0%,
            0% 25%,
            0% 65%,
            2% 100%,
            6% 100%,
            39% 100%,
            64% 100%,
            100% 100%,
            100% 73%,
            100% 47%,
            100% 25%
          );
          -webkit-clip-path: polygon(
            100% 0%,
            70% 0%,
            30% 0%,
            0% 0%,
            0% 25%,
            0% 65%,
            2% 100%,
            6% 100%,
            39% 100%,
            64% 100%,
            100% 100%,
            100% 73%,
            100% 47%,
            100% 25%
          );
        }

        p {
          position: relative;
          color: #fff;
          margin-bottom: 0;
          text-transform: uppercase;
          font-size: 24px;
          font-weight: 400;
          line-height: 20px;
        }
      }

      .attribute-info {
        padding: 20px 10px;
        p {
          color: rgba(255, 255, 255, 0.48);
          font-size: 24px;
          font-weight: 400;
          line-height: 35px;
          text-align: left;
          margin-bottom: 0;
        }
      }
    }
  }

  .button-row {
    display: flex;
    justify-content: space-evenly;
  }
`

export default ItemDetailContainer
