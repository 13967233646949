export default {
  ACCESS_TOKEN_KEY: 'access_token',
  REFRESH_TOKEN_KEY: 'refresh_token',
  USER_ID_KEY: 'user_id',
  HEROKU_BY_PASS: 'https://heroku-bypass.herokuapp.com/',
  DEBOUNCE_TIME: 500,
  HTTP_STATUS: {
    BAD_REQUEST: 400,
    BAD_REQUEST_429: 429
  },
  TRANSACTION: 'transaction',
  SIGNER: 'signer',
  CLICKLOGIN: 'clickLogin',
  WALLET_IN_USE: 'walletInUse',
}

export enum STATUS {
  WAITING = 'WAITING',
  SCHEDULING = 'SCHEDULING',
  LIVE = 'LIVE'
}

export const DATE_FORMAT = 'DD/MM/YYYY HH:mm'

export const MAX_TIME_ENERGY = 54000000

export const MAX_ID = 2147483647

export const TIME_CLOSE_MODAL = 5000

export const TIME_CLOSE_STATS_MODAL = 3000

export const SPECTRE_RPC = '55'

export const SPEED_UP = 1.5

export const minInput = '0.00001'

export const maxInput = '9999999999.99999'

export const exceptThisSymbols = ['e', 'E', '+', '-', ',']

export const timeCheckNonce = 5000

export const decimalFormatCoin = 5

export const timeLineEndAt = 5000

export const optionPercent = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

export const MAX_HORSE_LEVEL = 20

export const TIME_NOTIFICATION = 20000

export const MailStatusOptions = [
  {
    name: 'All',
    isActive: true
  },
  {
    name: 'Read',
    isActive: false
  },
  {
    name: 'Unread',
    isActive: false
  }
]

export const MAX_PRICE_MARE = 1000000
export const TIME_CONFIGS = [
  { name: '1', day: '1', id: 1, active: false, value: '0' },
  { name: '3', day: '3', id: 2, active: false, value: '0' },
  { name: '7', day: '7', id: 3, active: false, value: '0' },
  { name: '15', day: '15', id: 4, active: false, value: '0' },
  { name: '30', day: '30', id: 5, active: false, value: '0' }
]

export const TIME_GUILD_CONFIGS = [
  { name: '1', day: '1', id: 1, active: false },
  { name: '3', day: '3', id: 2, active: false },
  { name: '7', day: '7', id: 3, active: false },
  { name: '15', day: '15', id: 4, active: false },
  { name: '30', day: '30', id: 5, active: false }
]

export const TIME_CONFIGS_PRIVATE = [{ name: '1', day: '1', id: 1, active: true, value: '0' }]

export const CURRENCY_TYPE = {
  ADIL: 'ADIL',
  MARE: 'MARE',
  EMAS: 'EMAS',
  KUDA: 'KUDA',
  MERAH: 'MERAH',
  BIRU: 'BIRU'
}

export const STATS_TYPE = ['SPEED', 'MUSCLE', 'STAMINA', 'AGILITY', 'SPIRIT', 'IQ']
export const TWO_STATS_TYPE = ['SPEED', 'AGILITY', 'MUSCLE', 'SPIRIT', 'STAMINA', 'IQ']

export const OPERATING_SYSTEM = {
  window: 'WindowsPhone',
  android: 'Android',
  testflight: 'testflight',
  unknown: 'unknown'
}

export const LINK_APP = 'metahorse://web3login'

export const REGEX_NICK_NAME = '^[a-zA-Z0-9 ]{0,20}$'
export const REGEX_TAG = '^[a-zA-Z0-9]{0,3}$'
export const REGEX_FIRST_SPACE = /^\s/
export const REGEX_LAST_SPACE = /\s+$/
export const REGEX_TWO_SPACE = /\s{2,}/g

export const STATUS_TRANSACTION = {
  pending: 'PENDING',
  expired: 'EXPIRED',
  success: 'SUCCESS'
}

export const ACTION_REJECTED = 'ACTION_REJECTED'

export const TIME_TRANSACTION = 30000

export const SCREEN = {
  lending: 'LENDING',
  specialRace: 'SPECIAL_RACE'
}

export const GAS_CONFIG = 1000000000
export const TIME_ONE_DAY = 100000000

export enum ROLE_GUILD {
  master = 'MASTER',
  member = 'MEMBER',
  manager = 'MANAGER'
}

export const LENDING_USER_TYPE = {
  ALL_USER: 'ALL_USER',
  TARGET_USER: 'TARGET_USER'
}