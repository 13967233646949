/* eslint-disable @typescript-eslint/no-explicit-any */
import { HorseRank } from 'models'
import MyHorseItemStyled from './styled'
import { getWinRate, shortenRaceNameV2 } from 'utils/helper'
import { constants } from 'apps'
import { useLocalStorage } from 'hooks'
import RankCount from '../RankCount'

interface MyHorseItemProps {
  horse: HorseRank
}

function MyHorseITem({ horse }: MyHorseItemProps) {
  const [userId] = useLocalStorage(constants.USER_ID_KEY, 0)

  return (
    <MyHorseItemStyled mySelf={userId === horse?.owner_id}>
      <td className='color-white stt width-20 center'>
        <RankCount rank={horse?.rank} />
      </td>
      <td className='color-white width-20 horse-name left'>
        <div className='horse-name-content'>
          <img src={horse?.horse_avatar} alt='list-horse' className='horse-avatar' />
          <span>{shortenRaceNameV2(horse?.horse_name, 18)}</span>
        </div>
      </td>
      <td className='color-white width-20 left owner'>
        <span>{shortenRaceNameV2(horse?.owner, 25)}</span>
      </td>
      <td className='color-white width-20 left care'>
        <span>
          {horse?.total_number_of_races} {horse?.first_count}/{horse?.second_count}/{horse?.third_count}
        </span>
      </td>
      <td className='color-white width-20 left race'>{getWinRate(horse)}</td>
    </MyHorseItemStyled>
  )
}

export default MyHorseITem
