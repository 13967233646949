/* eslint-disable @typescript-eslint/no-explicit-any */
import { Menu, MenuProps } from 'antd'
import SubMenuStyled from './styled'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { links } from 'apps';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuItem[] | any = [
  getItem(<div className='title-main'>BUY</div>, 'buy', null, [], 'group'),
  getItem('Armor', 'buy_armor', '', [
    getItem('Head', 'buy_head'),
    getItem('Body', 'buy_body'),
    getItem('Leg', 'buy_leg'),
  ]),
  getItem('Skin', 'buy_skin', ''),
  getItem('Booster', 'buy_booster', '', [
    getItem('Energy Recover', 'buy_energy'),
    getItem('Doping', 'buy_doping'),
  ]),
  getItem(<div className='title-main'>INVENTORY</div>, 'inventory', null, [], 'group'),
  getItem('Armor', 'inventory_armor', '', [
    getItem('Head', 'inventory_head'),
    getItem('Body', 'inventory_body'),
    getItem('Leg', 'inventory_leg'),
  ]),
  getItem('Skin', 'inventory_skin', ''),
  getItem('Booster', 'inventory_booster', '', [
    getItem('Energy Recover', 'inventory_energy'),
    getItem('Doping', 'inventory_doping'),
  ]),
  getItem('NFT Selling', 'inventory_nft', ''),
  getItem('Booster Selling', 'inventory_selling', ''),
];


export default function SubMenu() {
  const navigate = useNavigate()
  const handleClicked = (e: any) => {
    const { key } = e
    const searchParams = new URLSearchParams(window.location.search);
    if (key?.includes('inventory')) {
      searchParams.set('type', key);
      const newURL = `${links.shop.inventoryItems()}?${searchParams.toString()}`;
      navigate(newURL);
    } else {
      searchParams.set('type', key);
      const newURL = `${links.shop.buyItems()}?${searchParams.toString()}`;
      navigate(newURL);
    }
  }
  const [searchParams] = useSearchParams();
  const typePart = searchParams.get("type") || ''; 

  return (
    <SubMenuStyled >
      <div style={{ width: 256 }}>
        <Menu
          defaultSelectedKeys={[typePart || 'buy_head']}
          mode="inline"
          theme="dark"
          items={items}
          onClick={handleClicked}
          defaultOpenKeys={['buy_armor', 'buy_booster', 'inventory_booster']}
        />
      </div>
    </SubMenuStyled>
  )
}