import { BtnBacktSyled, ButtonSyled, ItemDetailsStyled, TitleStyled } from './styled'
import { useEffect, useState } from 'react'
import { CARET_LEFT, SUB_TRACT, GAME_TOKEN_KUDA} from 'assets/images'
import { useNavigate, useParams } from 'react-router-dom'
import Button from 'shared/Button'
import { ItemShop, CurrentUser } from 'models'
import { handleAsyncRequest } from 'utils/helper'
import itemShopsApi from 'apis/itemShopsApi'
import { getCurrentUser } from 'utils/metamask'
import { Spin } from 'antd';

export default function ItemInventoryDetails() {
  const [tabInfor, setTabInfor] = useState('attribute')
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false)
  const [itemDetails, setItemDetails] = useState<ItemShop>()
  const { id } = useParams()
  const [currentUser, setCurrentUser] = useState<CurrentUser>()

  const onTabClick = (value: string) => {
    setTabInfor(value)
  }

  const onBuyItemClick = () => {
    console.log('onCloseButtonClick')
  }

  const fetchItem = async () => {
    setIsLoading(true)
    const [, result] = await handleAsyncRequest(itemShopsApi.getItemInventoryDetail(currentUser?.id, id))
    const records = result?.data
    setItemDetails(records)
    setIsLoading(false)
  }

  const fetchCurrentUser = async () => {
    const [, currentUser] = await handleAsyncRequest(getCurrentUser())
    setCurrentUser(currentUser)
  }

  useEffect(() => {
    fetchCurrentUser()
    fetchItem()
  }, [id, currentUser?.id])  

  return (
    <ItemDetailsStyled>
      <BtnBacktSyled>
        <div className='btn-back' onClick={() => navigate(-1)}>
          <img src={CARET_LEFT} alt='' />
          <span className='text-btn'>BACK</span>
        </div>
      </BtnBacktSyled>
      <TitleStyled>ITEM INFORMATION</TitleStyled>
      { isLoading ? (
          <span className='btn-name d-flex align-items-center justify-content-center'>
            {isLoading && <Spin size="large" className='mt-5 mr-3' />}
          </span>
      ) : (
        <>
          <div className='container'>
            <div className='item-block'>
              <div className='body-item'>
                <div className='item'>
                  <div className='avatar'>
                    <img className='sub-tract' src={SUB_TRACT} alt="" />
                    <img className='avatar-img' src={itemDetails?.boost?.img} alt={itemDetails?.boost?.name} />
                  </div>
                  <div className='name'>
                    <div className='item-name'>{itemDetails?.boost?.name}</div>
                    <div> <span className='field-name'>Id:</span> {itemDetails?.boost?.id}</div>
                    <div> <span className='field-name'>Owner:</span> {currentUser?.name}</div>
                    <div> <span className='field-name'>Type:</span> {itemDetails?.boost.effect_type}</div>
                    <div> <span className='field-name'>Rarity:</span> {itemDetails?.boost?.rarity_type}</div>
                  </div>
                </div>
                <div className='information'>
                  <div className='information-box'>
                    <div className={`attribute ${tabInfor === 'attribute' && 'active'}`} onClick={() => onTabClick('attribute')}>
                      Attribute
                    </div>
                    <div className={`description ${tabInfor === 'description' && 'active'}`} onClick={() => onTabClick('description')}>
                      Description
                    </div>
                  </div>
                  <div className='content'>
                    {tabInfor === 'attribute' 
                      ? (
                        <span>
                          {itemDetails?.boost.effect_type === 'ENERGY' ? ('No infomation') : (
                            <>
                              {itemDetails?.boost?.effect?.effect?.master_effect_type}: {itemDetails?.boost?.effect?.effect?.value}%
                            </>
                          )}
                        </span>
                      )
                      : itemDetails?.boost?.description}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ButtonSyled>
            <div className='price'> 
              <img src={GAME_TOKEN_KUDA} alt='kuda' className='kuda' />
              {itemDetails?.boost?.price}
            </div>
            <Button buttonName='Sell' disabled btnSell onClickButton={onBuyItemClick} width={150} />
          </ButtonSyled>
        </>
      )}
    </ItemDetailsStyled>
  )
}
