import styled from 'styled-components'

const ListItemContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: space-evenly;
  flex-wrap: wrap;

  .button-group {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .not-found {
    font-size: 24px;
    color: #fff;
    text-transform: uppercase;
  }
`

export default ListItemContainer
