/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { Modal } from 'antd'
import ItemDetailContainer from './styled'
import Head from '../Head'
import ChangeButton from '../ButtonAction/change'
import RemoveButton from '../ButtonAction/remove'
import UseButton from '../ButtonAction/use'
import horseApi from 'apis/horseApi'
import { handleAsyncRequest } from 'utils/helper'
import Loading from 'shared/Loading'
import { weapons } from 'models'

interface itemProps {
  open: boolean
  onCancel: () => void
  itemToken: number
  isUsedItem?: boolean
  onSuccess: () => void
  type?: string
  isChangeItem?: boolean
  className?: string
  horseId: number
}

const ItemDetail = ({
  open,
  onCancel,
  itemToken,
  isUsedItem = false,
  onSuccess,
  type,
  isChangeItem,
  className,
  horseId
}: itemProps) => {
  const [item, setItem] = useState<weapons>()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (itemToken) {
      fetchSyncHorseItems()
    }
  }, [itemToken])

  const fetchSyncHorseItems = async () => {
    setLoading(true)
    const [, data]: any = await handleAsyncRequest(horseApi.getWeaponDetail(itemToken))
    setLoading(false)

    if (data) {
      setItem(data?.data)
    }
  }

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      footer={false}
      width={615}
      className='modal-item'
      style={{ background: 'transparent' }}
      bodyStyle={{
        background: 'transparent',
        padding: 0
      }}
    >
      <ItemDetailContainer className={className}>
        <Head name='Item information' />
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className='attribute-content'>
              <div className='avatar-box'>
                <div className='avatar'>
                  <img src={item?.img} alt='icon' />
                </div>
                <div className='info'>
                  <p className='title'>{item?.name}</p>
                  <p>{item?.body_part}</p>
                </div>
              </div>
              <div className='content-box'>
                <div className='attribute-head'>
                  <p>Attributes</p>
                </div>
                <div className='attribute-info'>
                  {item?.effect?.effect?.map((e: any, index: number) => {
                    return (
                      <div key={index}>
                        {e?.master_effect_type ? (
                          <p>
                            {e?.master_effect_type} +{e?.value}
                            {e?.unit === 'PERCENT' ? '%' : e?.unit}
                          </p>
                        ) : (
                          <p>---</p>
                        )}
                      </div>
                    )
                  })}
                </div>
                <div className='attribute-head'>
                  <p>Informations</p>
                </div>
                <div className='attribute-info'>
                  <p>{item?.description || '---'}</p>
                </div>
              </div>
            </div>
            <div className='button-row'>
              {(isUsedItem && !isChangeItem)? (
                <>
                  <ChangeButton type={type} oldToken={itemToken} horseId={horseId} item={item} onChange={onCancel} />
                  <RemoveButton itemToken={itemToken} onSuccess={onSuccess} />
                </>
              ) : (
                <UseButton itemToken={itemToken} onSuccess={onSuccess} isChangeItem={isChangeItem} />
              )}
            </div>
          </>
        )}
      </ItemDetailContainer>
    </Modal>
  )
}

export default ItemDetail
