import styled from 'styled-components'

const IncomeBoxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  .title {
    font-size: 16px;
    color: #4ef076;
    margin-bottom: 0;
  }

  .end {
    color: #eea414;
    font-size: 16px;
    margin-bottom: 0;
  }

  span {
    color: #fff;
    font-size: 20px;
  }
  .revenue-box {
    .row-revenue {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &:first-child {
        margin-right: 10px;
      }

      img {
        width: 20px;
        height: 20px;
        margin-bottom: 4px;
      }

      span {
        font-size: 20px;
      }
    }

    p {
      font-size: 16px;
      margin-bottom: 0;
      margin-top: 10px;
      color: #4ef076;
    }
  }
`

export default IncomeBoxContainer
