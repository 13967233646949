import { ItemNFT } from 'features/ShopAP/components'
import { BtnBacktSyled, ButtonSyled, ItemDetailsStyled, TitleStyled } from './styled'
import { useEffect, useState } from 'react'
import { CARET_LEFT } from 'assets/images'
import { useNavigate, useParams } from 'react-router-dom'
import Button from 'shared/Button'
import { ItemShop } from 'models'
import { handleAsyncRequest } from 'utils/helper'
import itemShopsApi from 'apis/itemShopsApi'

export default function ItemDetails() {
  const [tabInfor, setTabInfor] = useState('attribute')
  const navigate = useNavigate();
  const [, setIsLoading] = useState(false)
  const [itemDetails, setItemDetails] = useState<ItemShop>()
  const { id } = useParams()

  const onTabClick = (value: string) => {
    setTabInfor(value)
  }

  const onBuyItemClick = () => {
    console.log('onCloseButtonClick')
  }

  const fetchItem = async () => {
    setIsLoading(true)
    const [, result] = await handleAsyncRequest(itemShopsApi.getItemShopsDetails(id || ''))
    const records = result?.data
    setItemDetails(records)
    setIsLoading(false)
  }

  useEffect(() => {
    fetchItem()
  }, [])

  return (
    <ItemDetailsStyled>
      <BtnBacktSyled>
        <div className='btn-back' onClick={() => navigate(-1)}>
          <img src={CARET_LEFT} alt='' />
          <span className='text-btn'>BACK</span>
        </div>
      </BtnBacktSyled>
      <TitleStyled>ITEM INFORMATION</TitleStyled>
      <div className='container'>
        <div className='item-block'>
          <div className='body-item'>
            <div className='item'>
              <div className='avatar'>
                <ItemNFT avt={itemDetails?.item?.img} />
              </div>
              <div className='name'>
                <div className='item-name'>{itemDetails?.item?.name}</div>
                <div> <span className='field-name'>Id:</span> {itemDetails?.item?.id}</div>
                <div> <span className='field-name'>Owner:</span> {itemDetails?.owner?.name}</div>
                <div> <span className='field-name'>Type:</span> {itemDetails?.item_type}</div>
                <div> <span className='field-name'>Rarity:</span> {itemDetails?.item?.rarity_type}</div>
              </div>
            </div>
            <div className='information'>
              <div className='information-box'>
                <div className={`attribute ${tabInfor === 'attribute' && 'active'}`} onClick={() => onTabClick('attribute')}>
                  Attribute
                </div>
                <div className={`description ${tabInfor === 'description' && 'active'}`} onClick={() => onTabClick('description')}>
                  Description
                </div>
              </div>
              <div className='content'>
                {tabInfor === 'attribute' ? itemDetails?.distributor : itemDetails?.item?.description}

              </div>
            </div>
          </div>
        </div>
      </div>
      <ButtonSyled>
        <div className='price'> 500 </div>
        <Button buttonName='Buy' onClickButton={onBuyItemClick} width={150} />
      </ButtonSyled>
    </ItemDetailsStyled>
  )
}
