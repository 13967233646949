/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-escape */
import { useEffect, useState } from 'react'
import { Form, Spin, notification } from 'antd'
import { useNavigate } from 'react-router-dom'

import { handleAsyncRequest } from 'utils/helper'
import useAppSelector from 'hooks/useAppSelector'
import guildApi from 'apis/guildApi'
import links from 'apps/links'

export interface dataProps {
  profit_guild: string | number
  profit_owner: string | number
  profit_renter: string | number
}

import InComeStyled from './styled'
import { InputCustomV2 } from 'shared'
import Button from 'shared/Button'
import ModalConfirmLeaveGuild from 'features/Guild/components/ModalConfirmLeaveGuild'

const regix = /^\d+$/

const InCome = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [api, contextHolder] = notification.useNotification()

  const [loading, setLoading] = useState<boolean>(true)
  const { profile } = useAppSelector(state => state)

  const [showModalEditIcome, setShowModalEditIncome] = useState<boolean>(false)
  const [dataIncomeEdit, setDataIncomeEdit] = useState<any>(null)
  const [loadingUpdateIncome, setLoadingUpdateIncome] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)

  useEffect(() => {
    getManagerData()
  }, [profile])

  const getManagerData = async () => {
    const [error, result]: any = await handleAsyncRequest(guildApi.getGuildInfomation())

    if (error) {
      navigate(links.guild.joinGuild())
    }

    if (result) {
      const guildData = result?.data
      form.setFieldsValue({
        profitGuild: guildData?.profit_guild,
        profitOwner: guildData?.profit_owner,
        profitRenter: guildData?.profit_renter,
        profitSystem: guildData?.profit_system
      })
    }
    setLoading(false)
  }

  const openNotification = (message: string, description: string) => {
    notification.config({
      duration: 4
    })

    api.info({
      message,
      description,
      placement: 'bottomRight',
      className: message === 'ERROR' ? 'ant-custom-error' : 'ant-custom-success'
    })
  }

  const handleShowModalEditIncome = () => {
    setShowModalEditIncome(!showModalEditIcome)
  }

  const onSubmitForm = (value: any) => {
    const { profitGuild, profitOwner, profitRenter, profitSystem } = value
    const totalPercent = +profitGuild + +profitOwner + +profitRenter + +profitSystem
    if (totalPercent !== 100) {
      openNotification('ERROR', 'The total profit percentage is 100 !')
      return
    }

    setDataIncomeEdit(value)
    setShowModalEditIncome(!showModalEditIcome)
  }

  const handleConfirmEdit = async () => {
    setLoadingUpdateIncome(true)
    const { profitGuild, profitOwner, profitRenter } = dataIncomeEdit

    const payload: dataProps = {
      profit_guild: +profitGuild,
      profit_owner: +profitOwner,
      profit_renter: +profitRenter
    }
    const [error, result]: any = await handleAsyncRequest(guildApi.editProfitGuild(profile?.guild_id, payload))
    setLoadingUpdateIncome(false)

    if (error) {
      openNotification('ERROR', error.message || error.errors[0]?.message)
    }

    if (result) {
      openNotification('SUCCESS', 'Edit profit percentage successfully!')
      setShowModalEditIncome(false)
      getManagerData()
      _handleEdit()
    }
  }

  const _handleKeyDown = (e: any) => {
    if (['e', 'E', '+', '-', '.', ','].includes(e.key) || [38, 40].includes(e.keyCode)) {
      return e.preventDefault()
    }

    if (!regix.test(e.key) && e.keyCode !== 8) {
      return e.preventDefault()
    }

    if (e.key === 'Unidentified') {
      return
    }
  }

  const _handleEdit = () => {
    setIsEdit(!isEdit)
  }

  return (
    <InComeStyled>
      {contextHolder}
      {loading ? (
        <div className='loading'>
          <Spin className='mt-2 mr-3' />
        </div>
      ) : (
        <>
          <Form form={form} name='basic' onFinish={onSubmitForm} autoComplete='off'>
            {values => {
              const total = +values?.profitGuild + +values?.profitRenter + +values?.profitSystem + +values?.profitOwner
                           
              return (
                <div>
                  <div className='form-content'>
                    <p className='title'>Please set income percentage when from horse income</p>
                    <div className='row-content'>
                      <div className='left'>
                        <div className={`income-row ${!isEdit ? 'disabled' : ''}`}>
                          <span>MH system</span>
                          <div className='input-box'>
                            <Form.Item name='profitSystem' className='input-box'>
                              <InputCustomV2 type='text' maxLength={3} width={175} disabled />
                            </Form.Item>
                            <span className='percent'>%</span>
                          </div>
                        </div>
                        <div className={`income-row ${!isEdit ? 'disabled' : ''}`}>
                          <span>
                            Owner income <span className='requied'>(*)</span>
                          </span>
                          <div className='input-box'>
                            <Form.Item
                              name='profitOwner'
                              className='input-box'
                              rules={[
                                { required: true, message: 'Please input your owner income!' },
                                {
                                  validator: (_, value) => {
                                    if (+value > 100) {
                                      return Promise.reject(new Error('Percent max is 100% !'))
                                    }

                                    return Promise.resolve()
                                  }
                                }
                              ]}
                            >
                              <InputCustomV2
                                type='text'
                                maxLength={3}
                                onKeyDown={_handleKeyDown}
                                width={175}
                                disabled={!isEdit}
                              />
                            </Form.Item>
                            <span className='percent'>%</span>
                          </div>
                        </div>
                      </div>

                      <div className='right'>
                      <div className={`income-row ${!isEdit ? 'disabled' : ''}`}>
                          <span>
                            Guild income <span className='requied'>(*)</span>
                          </span>
                          <div className='input-box'>
                            <Form.Item
                              name='profitGuild'
                              className='input-box'
                              rules={[
                                { required: true, message: 'Please input your guild income!' },
                                {
                                  validator: (_, value) => {
                                    if (+value > 100) {
                                      return Promise.reject(new Error('Percent max is 100% !'))
                                    }
                                    return Promise.resolve()
                                  }
                                }
                              ]}
                            >
                              <InputCustomV2
                                type='text'
                                maxLength={3}
                                onKeyDown={_handleKeyDown}
                                width={175}
                                value={values.profitGuild}
                                disabled={!isEdit}
                              />
                            </Form.Item>
                            <span className='percent'>%</span>
                          </div>
                        </div>
                        
                        <div className={`income-row ${!isEdit ? 'disabled' : ''}`}>
                          <span>
                            Member income <span className='requied'>(*)</span>
                          </span>
                          <div className='input-box'>
                            <Form.Item
                              name='profitRenter'
                              className='input-box'
                              rules={[
                                { required: true, message: 'Please input your member income!' },
                                {
                                  validator: (_, value) => {
                                    if (+value > 100) {
                                      return Promise.reject(new Error('Percent max is 100% !'))
                                    }

                                    return Promise.resolve()
                                  }
                                }
                              ]}
                            >
                              <InputCustomV2
                                type='text'
                                maxLength={3}
                                onKeyDown={_handleKeyDown}
                                width={175}
                                disabled={!isEdit}
                              />
                            </Form.Item>
                            <span className='percent'>%</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <p className='total'>
                      Total <span>{total}%</span>
                    </p>
                  </div>
                  <Form.Item className='button-edit'>
                    {total !== 100 && <p className='error'>Total is 100%</p>}
                    {!isEdit ? (
                      <Button buttonName='Edit' onClickButton={_handleEdit} />
                    ) : (
                      <Button buttonName='Save' onClickButton={form.submit} disabled={total !== 100} />
                    )}
                  </Form.Item>
                </div>
              )
            }}
          </Form>
        </>
      )}
      {showModalEditIcome && (
        <ModalConfirmLeaveGuild
          onClose={handleShowModalEditIncome}
          onConfirm={handleConfirmEdit}
          loading={loadingUpdateIncome}
          message={'You have changed the income. Are you sure?'}
          title={'Edit income'}
        />
      )}
    </InComeStyled>
  )
}

export default InCome
