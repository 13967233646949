/* eslint-disable @typescript-eslint/no-explicit-any */
import { CLOSE_BTN } from 'assets/images'
import { TypeModal } from 'models'
import { Modal } from 'shared'
import Button from 'shared/Button'
import { ModalConfirmCommon } from './styled'

interface MProps {
  onClose: () => void
  onConfirm: () => void
  loading: boolean
  message?: any
  title?: string
  type?: TypeModal
  size?: string
}

const ModalConfirmGuildCommon = ({ onClose, onConfirm, loading, message, title, type = TypeModal.confirm, size = 'SM' }: MProps) => {
  const _onConfirm = () => {
    onConfirm()
  }

  return (
    <Modal>
      <ModalConfirmCommon size={size}>
        <div className='header'>
          <p className='title'>
            <span className={`title ${type}`}>{title ? title : 'CONFIRM'}</span>
          </p>
        </div>
        <button disabled={loading} className='close-btn p-0 position-absolute' role='button' onClick={onClose}>
          <img src={CLOSE_BTN} alt='close' />
        </button>
        <div className='content-box'>
          <p>{message}</p>
        </div>
        <div className='button-box'>
          <Button buttonName='No' onClickButton={onClose} width={100} height={35} btnCancel disabled={loading} />
          <Button buttonName='Yes' onClickButton={_onConfirm} width={100} height={35} isLoading={loading} />
        </div>
      </ModalConfirmCommon>
    </Modal>
  )
}

export default ModalConfirmGuildCommon
