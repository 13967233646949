import { useGetRankInfo } from 'features/Home/hooks/'
import { Options, TopHorse } from 'models'
import { useEffect, useRef } from 'react'
import VanillaTilt from 'vanilla-tilt'

import GuildInfo from '../GuildInfor'

import {
  TOP_HORSE_FIRST_LEFT_FRAME,
  TOP_HORSE_FIRST_RIGHT_FRAME,
  TOP_HORSE_LEFT_STICK_BRONZE,
  TOP_HORSE_LEFT_STICK_GOLD,
  TOP_HORSE_LEFT_STICK_SLIVER,
  TOP_HORSE_RIGHT_STICK_BRONZE,
  TOP_HORSE_RIGHT_STICK_GOLD,
  TOP_HORSE_RIGHT_STICK_SLIVER,
  TOP_HORSE_SECOND_LEFT_FRAME_SLIVER,
  TOP_HORSE_SECOND_RIGHT_FRAME_SLIVER
} from 'assets/images'

import TopHorseBoxStyled from './styled'
import { shortenRaceNameV2 } from 'utils/helper'

interface TopHorseBoxProps {
  horse: TopHorse
  selfIndex: number
  customClass?: string
  options: Options
}

enum Rank {
  FIRST = '1st',
  SECOND = '2nd',
  THIRD = '3rd'
}

function TopHorseBox({ horse, selfIndex, customClass = '', options }: TopHorseBoxProps) {
  const { circle, crown, position } = useGetRankInfo(selfIndex)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tilt = useRef<HTMLElement | any>(null)

  useEffect(() => {
    VanillaTilt.init(tilt.current, options)
  }, [options])

  return (
    <TopHorseBoxStyled circle={circle} position={position} className={customClass}>
      <div className='top-horse d-flex flex-column align-items-center mx-auto position-relative' ref={tilt}>
      <img
          src={
            (position === Rank.FIRST && TOP_HORSE_LEFT_STICK_GOLD) ||
            (position === Rank.SECOND && TOP_HORSE_LEFT_STICK_SLIVER) ||
            (position === Rank.THIRD && TOP_HORSE_LEFT_STICK_BRONZE) ||
            ''
          }
          className='left-stick position-absolute d-none d-md-inline-block'
        />
        <img
          src={
            (position === Rank.FIRST && TOP_HORSE_RIGHT_STICK_GOLD) ||
            (position === Rank.SECOND && TOP_HORSE_RIGHT_STICK_SLIVER) ||
            (position === Rank.THIRD && TOP_HORSE_RIGHT_STICK_BRONZE) ||
            ''
          }
          className='right-stick position-absolute d-none d-md-inline-block'
        />
        <img src={TOP_HORSE_FIRST_LEFT_FRAME} className='first-left-frame position-absolute' />
        <img src={TOP_HORSE_FIRST_RIGHT_FRAME} className='first-right-frame position-absolute' />
        <img src={TOP_HORSE_SECOND_LEFT_FRAME_SLIVER} className='second-left-frame position-absolute' />
        <img src={TOP_HORSE_SECOND_RIGHT_FRAME_SLIVER} className='second-right-frame position-absolute' />

        <div className='avatar-container d-flex align-items-center justify-content-center'>
          <img src={horse.sub_avatar} alt={horse.horse_name} className='avatar' />
        </div>
        <div className='rank-container d-flex flex-column align-items-center'>
          <img src={crown} alt='' className='crown' />
          <div className='rank color-white font-bold'>{position}</div>
        </div>
        <div className='info-container text-center'>
          <div className='horse-name color-white font-bold'>{shortenRaceNameV2(horse.name, 10)}</div>
          <div className='horse-name color-white font-bold'>{shortenRaceNameV2(horse.owner_name, 10)}</div>
        </div>
        <div className='d-flex achievement text-center color-white font-bold my-1'></div>
        <div className='achievement-container d-flex'>
          <GuildInfo career={horse} className="horse" />
        </div>
      </div>
    </TopHorseBoxStyled>
  )
}

export default TopHorseBox
