/* eslint-disable @typescript-eslint/no-explicit-any */
import { UserRank } from 'models'
import { constants } from 'apps'
import { useLocalStorage } from 'hooks'
import MyHorseItemStyled from './styled'
import { getWinRate, shortenUserName } from 'utils/helper'

import { AVATAR_DEFAULT } from 'assets/images'
import RankCount from '../RankCount'

interface UserItemProps {
  user: UserRank
}

function UserITem({ user }: UserItemProps) {
  const [userId] = useLocalStorage(constants.USER_ID_KEY, 0)

  return (
    <MyHorseItemStyled mySelf={userId === user?.owner_id}>
      <td className='color-white stt width-20 center'>
        <RankCount rank={user?.rank} />
      </td>
      <td className='color-white width-20 horse-name'>
        <div className='horse-name-content'>
          <img src={AVATAR_DEFAULT} alt='avatar' className='horse-avatar' />
          <span>{shortenUserName(user?.owner_name)}</span>
        </div>
      </td>
      <td className='color-white width-20 left'>
        <span>
          {user?.total_number_of_races} {user?.first_count}/{user?.second_count}/{user?.third_count}
        </span>
      </td>
      <td className='color-white width-20 left'>{getWinRate(user)}</td>
    </MyHorseItemStyled>
  )
}

export default UserITem
