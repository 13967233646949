/* eslint-disable @typescript-eslint/no-explicit-any */
import { decode } from 'js-base64'
import {
  ApiResponse,
  GetHorseCareerListByTokenIdParams,
  GetHorseCareerListParams,
  GetHorseCareerResponse,
  GetHorseDetailByTokenId,
  GetTopParams,
  Horse,
  HorseAvailable,
  HouseFeeChangeName,
  HouseInRace,
  HouseStats,
  PostResponseAPI,
  MessageValidateChangeName,
  TopHorse,
  TopStable,
  UpdateStatsParams,
  GetItemsWaeponsListParams,
  HorseBoostItems,
  putListItemsParams,
  changeItemsParams
} from 'models'
import { checkFormatId } from 'utils/helper'
import axiosClient from './axiosClient'

const horseApi = {
  getHorseAvailableDetail(horseId: string): Promise<ApiResponse<HorseAvailable>> {
    const url = `/horse/${horseId}`
    return axiosClient.get(url)
  },

  getHorseDetail(horseId: string): Promise<ApiResponse<Horse>> {
    const url = `/horse/${horseId}`
    return axiosClient.get(url)
  },

  getHorseDetailByTokenId(params: GetHorseDetailByTokenId = {}): Promise<ApiResponse<Horse>> {
    const url = `/horse/details`
    return axiosClient.get(url, { params })
  },

  getHorseDetailRaceView(token_id: string, raceId: string): Promise<ApiResponse<Horse>> {
    try {
      const decodeId = decode(raceId)
      checkFormatId(decodeId)
      const url = `/horse/${token_id}/race/${decodeId}`
      return axiosClient.get(url)
    } catch (err) {
      return axiosClient.get('')
    }
  },

  getTopHorses(params: GetTopParams = {}): Promise<ApiResponse<TopHorse[]>> {
    const url = '/horse/top-horses'
    return axiosClient.get(url, { params })
  },

  getTopStables(params: GetTopParams = {}): Promise<ApiResponse<TopStable[]>> {
    const url = '/horse/top-stables'
    return axiosClient.get(url, { params })
  },

  getHorseCarrerList(
    params: GetHorseCareerListParams = { horseId: '0' }
  ): Promise<ApiResponse<GetHorseCareerResponse>> {
    const url = `horse/${params.horseId}/career`
    return axiosClient.get(url, { params })
  },

  getHorseCarrerListByTokenId(
    params: GetHorseCareerListByTokenIdParams = { token_id: '0' }
  ): Promise<ApiResponse<GetHorseCareerResponse>> {
    const url = `horse/${params.token_id}/career`
    return axiosClient.get(url, { params })
  },

  postUpdateStats(horseId: number, params: UpdateStatsParams[]): Promise<ApiResponse<UpdateStatsParams>> {
    const url = `horse/${horseId}/update-stats`
    return axiosClient.post(url, params)
  },

  postLevelUpHorse(horseId: number): Promise<ApiResponse<PostResponseAPI>> {
    const url = `user/horse/${horseId}/level-up`
    return axiosClient.post(url)
  },

  getHouseStats(): Promise<ApiResponse<HouseStats>> {
    const url = `/master-data/horse/horse-stats`
    return axiosClient.get(url)
  },

  getHorseIsInRace(horseId: string, params: { statuses: string }): Promise<ApiResponse<HouseInRace>> {
    const url = `horse/${horseId}/is-in-race`
    return axiosClient.get(url, { params })
  },

  getHouseFee(): Promise<ApiResponse<HouseFeeChangeName>> {
    const url = `/master-data/horse/fee-for-changing-horse-name`
    return axiosClient.get(url)
  },

  postHorseValidateName(horseId: number, params: { name: string }): Promise<ApiResponse<MessageValidateChangeName>> {
    const url = `horse/${horseId}/validate-horse-name`
    return axiosClient.post(url, params)
  },

  postHorseChangeName(horseId: number, params: { name: string }): Promise<ApiResponse<PostResponseAPI>> {
    const url = `horse/${horseId}/update-name`
    return axiosClient.post(url, params)
  },

  getItemsWeapons(horseId: number, params: GetItemsWaeponsListParams): Promise<ApiResponse<PostResponseAPI>> {
    const url = `items/horse/${horseId}/weapons`
    return axiosClient.get(url, { params })
  },

  getWeaponDetail(itemToken: number): Promise<ApiResponse<PostResponseAPI>> {
    const url = `items/users/weapon/${itemToken}/detail`
    return axiosClient.get(url)
  },

  addWeapon(itemToken: number, tokenId: number): Promise<ApiResponse<PostResponseAPI>> {
    const url = `items/${itemToken}/horses/${tokenId}/weapons`
    return axiosClient.put(url)
  },

  removeWeapon(itemToken: number, tokenId: number): Promise<ApiResponse<PostResponseAPI>> {
    const url = `items/${itemToken}/horses/${tokenId}/weapons`
    return axiosClient.delete(url)
  },

  changeWeapon(itemNew: number, tokenId: number): Promise<ApiResponse<PostResponseAPI>> {
    const url = `items/${tokenId}/horse/${itemNew}/change-equipment`
    return axiosClient.put(url)
  },

  getListBoostItems(params?: any): Promise<ApiResponse<any>> {
    const url = '/items/users/boost/doping/selection'
    return axiosClient.get(url, { params })
  },  
  getListChangeBoostItems(params?: any): Promise<ApiResponse<any>> {
    const url = '/items/users/boost/doping/selection/change'
    return axiosClient.get(url, { params })
  },  
  
  getBoostItemsDetail(equippedId: any): Promise<ApiResponse<any>> {
    const url = `/items/horse/${equippedId}/equipped-detail`
    return axiosClient.get(url)
  },

  putHorseBoosterItem(horseId: string, boostItemId: string, params: putListItemsParams): Promise<ApiResponse<HorseBoostItems>> {
    const url = `/items/${boostItemId}/horses/${horseId}/boost`
    return axiosClient.put(url, params)
  },

  changeHorseBoosterItem(tokenId: string, oldEquippedId: string, params: changeItemsParams): Promise<ApiResponse<HorseBoostItems>> {
    const url = `items/users/boost/doping/${oldEquippedId}/horses/${tokenId}`
    return axiosClient.put(url, params)
  },

  deleteHorseBoosterItem(tokenId: string, equippedId: string): Promise<ApiResponse<PostResponseAPI>> {
    const url = `/items/users/boost/doping/${equippedId}/horses/${tokenId}`
    return axiosClient.delete(url)
  },
}

export default horseApi
