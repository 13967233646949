import styled from 'styled-components'

const ModalContainer = styled.div`
  min-height: 274px;
  position: relative;
  background-color: #191d2c;
  -webkit-clip-path: polygon(0% 0%, 0 90%, 100% 300%, 100% 100%, 100% 0%);
  clip-path: polygon(0% 0%, 0 90%, 100% 300%, 100% 100%, 100% 0%);
  padding: 40px;

  &:before {
    content: '';
    position: absolute;
    min-height: 272px;
    top: 1px;
    left: 1px;
    background-color: #191d2c;
    display: block;
    -webkit-clip-path: polygon(0% 0%, 0 90%, 100% 300%, 100% 100%, 100% 0%);
    clip-path: polygon(0% 0%, 0 90%, 100% 300%, 100% 100%, 100% 0%);
  }

  .row {
    text-align: center;

    &.button-group {
      display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    }
  }

  .text {
    color: #fff;
    text-align: center;
    margin-top: 50px;
    font-size: 24px;
    line-height: 20px;
    font-weight: 400;
  }
`

const Container = styled.div`
  .modal-item {
    background-color: red;
  }
`

export { ModalContainer, Container }
