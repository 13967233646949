import { Owner } from './lending'

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface CreateGuildRequest {
  avatar: string
  description: string
  guild_tag: string
  name: string
}

export interface EditGuildRequest {
  avatar: string
  description: string
}

export interface ErrorProps {
  message: string
}

export interface CreateGuildResponse {
  error: ErrorProps
}

export interface GuildMembersResponse {
  avatar: string
  description: string
  guild_tag: string
  name: string
  career: any
  guild_logo: string
  id: number
  role: string
}

export interface GuildHorseInfo {
  // price_configs: LendingPriceConfigs[]
  lending_status: string
  lending_type: string
  is_owner: boolean
  renter: Owner
  owner: Owner
  start_date: number
  end_date: number
  rent_fee: number
  rent_days: number
  status: string
  rent_type: string
}

export interface GuildPriceConfigs {
  active: boolean
  name?: string
  day: string
  id?: number
}

export interface GuildInforManager {
  avatar: string
  day: string
  id: number
  name: string
  profit_guild: number
  profit_owner: number
  profit_renter: number
  guild_tag: string
}

export enum TypeModal {
  confirm = 'confirm',
  warning = 'warning'
}

export enum GUILD_STATUS {
  InFarm = 'IN_FARM',
  Lending = 'LENDING',
  Borrowed = 'BORROWED',
  Available = 'AVAILABLE',
  GuildFarm = 'GUILD_FARM',
  OnMarket = 'ON_MARKET',
  Renting = 'RENTING',
  InGuild = 'IN_GUILD',
  OWNER = 'OWNER',
}

export enum GUILD_ROLE {
  GUEST = 'GUEST',
  MASTER = 'MASTER',
  MANAGER = 'MANAGER',
  OWNER = 'OWNER',
  MEMBER = 'MEMBER'
}
