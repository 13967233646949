import styled from 'styled-components'

const BuyItemsStyled = styled.div`
  padding-left: 24px;
  .inventory-body {
    .inventory-top {
      padding-bottom: 20px;
      .total-items {
        span {
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          color: #4ef076;
        }
      }
      .filter-items {
        gap: 24px;
        .search-icon {
          width: 16px;
        }
        .select-box-customs {
          width: 110px;
          .select-drop-icon {
            left: 30px;
            filter: brightness(0) invert(1);
          }

          .orange-line {
            bottom: -0.5px;
          }
          span.select-name {
            font-size: 16px;
            top: 4px;
            padding-left: 5px;
          }
          .select-content-dropdown li {
            font-size: 16px;
            padding: 5px;
          }
        }
        .search-horse-input {
          width: 200px;
          clip-path: polygon(185px 0, 100% 10px, 100% 100%, 0 100%, 0 0);
          .search-input {
            font-size: 16px;
          }
        }
      }
    }
    .inventory-list {
      .items {
        display: grid;
        gap: 20px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      }
      .view-more-container{
        text-align: center;
      }
      button.btn-view-more {
        background: linear-gradient( 178.19deg, rgb(240 241 240 / 82%) 1.64%, rgb(235 245 241 / 48%) 47.93%, rgb(251 251 251 / 63%) 97.84% );
        border-radius: 4px;
        height: 100%;
        width: 250px;
        font-size: 16px;
        height: 60px;
        &:after {
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #f9fafb;
          content: '';
          position: relative;
          right: -20px;
          top: 11px;
        }
      }
    }
  }
`

export default BuyItemsStyled
