import { HORSE_AVATAR_BG } from 'assets/images'
import styled from 'styled-components'

const HorseImageContainer = styled.div`
  .background {
    background-image: url(${HORSE_AVATAR_BG});
    background-repeat: no-repeat;
    background-position: center;
    position: relative;

    ${({ theme }) => theme.media.lessThan(theme.size.sm)} {
      background-size: cover;
    }

    .avatar {
      width: 400px;
      aspect-ratio: 300 / 300;
      object-fit: contain;
      object-position: center;

      ${({ theme }) => theme.media.lessThan(theme.size.sm)} {
        width: 100%;
      }
    }

    .item {
      width: 400px;
      height: 400px;
      position: absolute;
      top: 0;
      left: 0;

      &.head {
        z-index: 2;
      }

      &.body {
        z-index: 3;
      }

      &.foot {
        z-index: 4;
      }

      &.skin {
        z-index: 1;
      }
    }
  }
`

export default HorseImageContainer
