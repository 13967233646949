import styled from 'styled-components'

const ConditionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40vh;

  p {
    text-transform: uppercase;
    background: linear-gradient(90deg, #ffd901 0%, #fd6401 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-size: 30px;
  }
`

export default ConditionContainer
