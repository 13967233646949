/* eslint-disable @typescript-eslint/no-explicit-any */
import { checkExistItem } from '../utils'
import HorseImageContainer from './styled'

interface iprops {
  avatar: string
  name: string
  items: any
}

const HorseAvatar = ({ avatar, name, items = [] }: iprops) => {
  const headItem = checkExistItem('HEAD', items)
  const bodyItem = checkExistItem('BODY', items)
  const footItem = checkExistItem('LEG', items)
  const skinItem = checkExistItem('SKIN', items)
  
  return (
    <HorseImageContainer>
      <div className='background d-flex align-items-center justify-content-center'>
        <img src={avatar} alt={name} className='avatar' />
        {headItem && <img src={headItem.img} alt={headItem.name} className='item head' />}
        {bodyItem && <img src={bodyItem.img} alt={bodyItem.name} className='item body' />}
        {footItem && <img src={footItem.img} alt={footItem.name} className='item foot' />}
        {skinItem && <img src={skinItem.img} alt={skinItem.name} className='item skin' />}
      </div>
    </HorseImageContainer>
  )
}

export default HorseAvatar
