import { INCOME_BG } from 'assets/images'
import styled from 'styled-components'

const InComeStyled = styled.div`
  .form-content {
    width: 800px;
    min-height: 400px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: url(${INCOME_BG});

    .title {
      color: #fff;
      font-size: 16px;
      margin-top: 20px;
    }
    .row-content {
      display: flex;
      justify-content: center;
      width: 100%;
      .left {
        margin-right: 40px;

        .income-row {
          &:first-child {
            input {
              color: rgb(157, 157, 157);
            }
            span {
              color: rgb(157, 157, 157) !important;
            }
          }
        }
      }
    }
    .income-row {
      margin-bottom: 40px;
      align-items: center;
      position: relative;

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type='number'] {
        -moz-appearance: textfield;
      }

      &.disabled {
        .input-box {
          span,
          input,
          .percent {
            color: #9d9d9d;
          }
        }
      }

      span {
        display: inline-block;
        color: #9d9d9d;
        font-size: 16px;
        text-transform: uppercase;

        &:last-child {
          background: #30343f;
          padding: 4px 30px;
          border: 1px solid #9c9c9c;
          min-width: 99px;
          text-align: center;
          display: inline-block;
        }

        &.requied {
          color: #e01621;
          border: none;
          background: transparent;
          min-width: 10px;
          padding: 0;
        }
      }

      .input-box {
        span {
          position: absolute;
          right: 30px;
          top: 1px;

          background: transparent !important;
          padding: 0 !important;
          border: none !important;
          min-width: 0 !important;
          text-align: center;
          display: inline-block;
          padding-left: 2px;
        }

        .ant-form-item {
          margin: 0;

          &.ant-form-item-has-error {
            margin: 0 0 24px;
          }
        }

        .percent {
          display: inline-block;
          color: #fff;
          position: absolute;
          top: 35px !important;
          right: 66% !important;
        }
      }

      .ant-form-item-explain {
        position: absolute;
        min-width: 200px;
        right: 0;
        text-align: right;
      }
    }

    .total {
      color: #fff;
      font-size: 23px;

      span {
        color: #4ef076;
        font-size: 28px;
      }
    }
  }

  .button-edit {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }

  .error {
    color: #e01621;
    font-size: 18px;
    text-align: center;
  }

  .ant-spin-dot-item {
    background-color: #4ef076 !important;
  }
`

export default InComeStyled
