import styled from 'styled-components'

const Container = styled.div`
  .meta-block {
    width: 100%;
    margin-right: 20px;
    cursor: pointer;
    &:nth-child(4n) {
      margin-right: 0px;
    }
    &.active,
    &:hover {
      .item-block {
        background-image: linear-gradient(#4ff077, #48f7b9);
        .sub-block {
          background-image: linear-gradient(#4ff077, #48f7b9);
        }
        .numb {
          img {
            filter: none;
          }
          span {
            color: #114d06;
          }
        }
      }
    }
  }
  .item-block {
    clip-path: polygon(7% 0, 84% 0, 100% 19%, 100% 100%, 0 100%, 0 8%);
    background: #515460;
    padding: 2px;
    .sub-block {
      background: #030317;
      clip-path: polygon(7% 0, 84% 0, 100% 19%, 100% 100%, 0 100%, 0 8%);
      position: relative;
      .amount {
        position: relative;
        text-align: left;
        margin-left: 5px;
        padding-bottom: 3px;
      }
      .numb {
        position: relative;
        width: 31px;
        img {
          filter: brightness(0) invert(1);
        }
        span {
          position: absolute;
          left: 0;
          color: #fff;
          font-size: 10px;
          width: 100%;
          text-align: center;
          bottom: 3px;
        }
      }
      .star {
        div {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
  .item-dp {
    background: transparent;
    border: none;
    padding: 17px 0;
    width: 183px;
    img {
      height: 100px;
    }
  }

  .HEAD {
    img {
      transform: translateX(130px) translateY(82px) scale(4);
    }
  }
  .BODY {
    img {
      transform: translateX(7px) translateY(12px) scale(2);
    }
  }
  .LEG {
    img {
      transform: translateX(0px) translateY(-30px) scale(2);
    }
  }
  .SKIN {
    img {
      transform: scale(1.5);
    }
  }
  .DOPING {
    img {
      transform: scale(1);
    }
  }
  .ENERGY {
    img {
      transform: scale(1);
    }
  }
  .count-item {
    position: relative;
    span {
      position: absolute;
      width: 100%;
      text-align: center;
      z-index: 1;
      top: 12px;
      left: 0px;
      font-size: 16px;
    }
    img {
      width: 100%;
      height: 44px;
      margin-top: 4px;
    }
  }
`

export { Container }
