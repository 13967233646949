import { ALL_HEAD, GAME_TOKEN_KUDA, SUB_TRACT } from 'assets/images'
import ItemNFTStyled from './styled'

interface ItemNFTProps {
  avt?: string
  itemName?: string
  price?: number
  onClick?: () => void
}

export default function ItemNFT({ onClick, avt, itemName, price, ...props }: ItemNFTProps) {

  return (
    <ItemNFTStyled {...props} onClick={onClick} >
      <div className='item'>
        <img className='sub-tract' src={SUB_TRACT} alt="" />
        <img className='avt-item' src={avt ?? ALL_HEAD} alt="" />
        <div className='description'>
          <h4>{itemName}</h4>
          {price ? (
            <span>
              <img src={GAME_TOKEN_KUDA} alt='kuda' className='kuda' />
              {' '} {price}
            </span>
          ) : (
            <span>
              <img src={GAME_TOKEN_KUDA} alt='kuda' className='kuda' />
              {' '} {price}
            </span> 
          )
          }
        </div>
      </div>
    </ItemNFTStyled>
  )
}
