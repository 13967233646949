import { CLOSE_BTN } from 'assets/images'
import { Modal } from 'shared'
import Button from 'shared/Button'
import { ModalConfirmJoinGuildContainer } from './styled'

interface MProps {
  onClose: () => void
  onConfirm: () => void
  loading: boolean
  title?: string
  message?: React.ReactNode
  error?: string
}

const ModalConfirmJoinGuild = ({ onClose, onConfirm, loading, title, message, error }: MProps) => {
  const _onConfirm = () => {
    onConfirm()
  }

  return (
    <Modal>
      <ModalConfirmJoinGuildContainer>
        <div className='header'>
          <p>
            <span className='title text-uppercase'>{title}</span>
          </p>
        </div>
        <button
          disabled={loading}
          className='close-btn p-0 position-absolute'
          role='button'
          onClick={onClose}
        >
          <img src={CLOSE_BTN} alt='close' />
        </button>
        <div className='content-box'>
          {error ? (
            <p className={`${error ? 'error' : ''}`} dangerouslySetInnerHTML={{ __html: error || '' }} />
          ) : (
            <p dangerouslySetInnerHTML={{ __html: message as string }} />
          )}
        </div>
        <div className='button-box'>
          {error ? (
            <Button buttonName='OK' onClickButton={onClose} width={100} height={35} disabled={loading} />
          ) : (
            <>
              <Button buttonName='No' onClickButton={onClose} width={100} height={35} btnCancel disabled={loading} />
              <Button buttonName='Yes' onClickButton={_onConfirm} width={100} height={35} isLoading={loading} />
            </>
          )}
        </div>
      </ModalConfirmJoinGuildContainer>
    </Modal>
  )
}

export default ModalConfirmJoinGuild
