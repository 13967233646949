import styled from 'styled-components'

const ItemNFTStyled = styled.div`
  .item {
    width: 175px;
    height: 175px;
    position: relative;
    cursor: pointer;
    .sub-tract {
    }
    .avt-item {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      height: 100px;
      top: 10%;
    }
    .description {
      margin-bottom: 5px;

      span {
        img {
          width: 12px;
        }
      }
    }
    .description {
      position: absolute;
      width: 100%;
      bottom: 0;
      text-align: center;
      h4 {
        color: #fff;
        font-size: 16px;
        margin-bottom: 0px;
      }
      span {
        color: #fff;
        font-size: 16px;
      }
    }
  }
  .item:hover {
    transform: perspective(1000px) rotateX(0deg) rotateY(15deg) scale3d(1, 1, 1);
  }
`

export default ItemNFTStyled
