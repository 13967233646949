import ConditionContainer from './styled';

const Condition = () => {
  return (
    <ConditionContainer>
      <p>Comming soons</p>
    </ConditionContainer>
  )
}

export default Condition;