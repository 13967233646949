/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'
import { useToggle } from 'hooks'
import { Modal } from 'shared'
import { handleAsyncRequest } from 'utils/helper'
import horseApi from 'apis/horseApi'
import { useTranslation } from 'react-i18next'
import SelectItemModalStyled from './styled'
import { BG_BUTTON, CLOSE_BTN, IFRAME_ITEM, INPUT_PREVIEW, ITEM_NUMBER, VIEW_BUTTON } from 'assets/images'
import HourseItemModalDetail from 'features/Horse/components/HourseItemModalDetail'
import HourseItemConfirmSelectedModal from 'features/Horse/components/HourseItemConfirmSelectedModal'
import { RateStar } from 'features/Horse/components'
import { Spin } from 'antd';
import { ITEMS_MESSAGE } from 'i18n/constants'
import openNotification from 'utils/toast'

interface SelectItemModalProps {
  onCloseButtonClick: () => void
  onConfirm: () => void
  listTypeBoots: any
  oldItems: any
  token_id: string
  position: string
  isChangeItem: boolean
  isLoading: boolean
  currentStatsSelected: string
}

function SelectItemModal({
  onCloseButtonClick,
  onConfirm,
  listTypeBoots,
  token_id,
  position,
  currentStatsSelected,
  isChangeItem,
  oldItems,
  isLoading
}: SelectItemModalProps) {
  const { t } = useTranslation()
  const [isModalOpen, toggleIsModalOpen] = useToggle(false)
  const [isModalConfirmSelectOpen, toggleIsModalConfirmSelectOpen] = useToggle(false)
  const [searchValue, setSearchValue] = useState<string>('')

  const [boostItemId, setCurrentIdActive] = useState<string>('')
  const boostType = 'DOPING'
  const [slotType, setCurrentSlotType] = useState('')
  const [itemDetailPreview, setItemDetailPreview] = useState([])

  const choosedItemDoping = (data: any) => {
    setCurrentIdActive(data.id)
    setItemDetailPreview(data)
    setCurrentSlotType(data.boost.effect_type)
  }

  const handlePreviewItems = async () => {
    return toggleIsModalOpen(true)
  }
  const handleCloseModalConfirm = async () => {
    toggleIsModalConfirmSelectOpen(false)
    setSearchValue('')
  }
  const OnOpenModalUse = async () => {
    toggleIsModalConfirmSelectOpen(true)
  }
  const handleCloseModal = async () => {
    return toggleIsModalOpen(false)
  }
  const handleBackModalCancel = () => {
    onCloseButtonClick()
  }

  const handleonConfirmUse = async () => {
    const params = {
      'amount': Number(searchValue),
      'boost_type': boostType,
      'slot_type': position || currentStatsSelected ? currentStatsSelected : slotType,
      'pay_for_energy': true,
      'position': Number(position)
    }
    const [error, result]: any =
      await handleAsyncRequest(horseApi.putHorseBoosterItem(token_id, boostItemId, params))
    if (result) {
      onConfirm()
    }
    if (error) {
      openNotification({
        message: 'ERROR',
        description: error.message || error.errors[0]?.message,
        className: 'ant-custom-error'
      })
    }
  }
  const handleonConfirmChange = async () => {
    const params = {
      'amount': Number(searchValue),
      'new_boost_id': Number(boostItemId),
      'slot_type': position || currentStatsSelected ? currentStatsSelected : slotType,
      'position': Number(position)
    }
    const [error, result]: any =
      await handleAsyncRequest(horseApi.changeHorseBoosterItem(token_id, oldItems.id, params))
    if (result) {
      onConfirm()
    }
    if (error) {
      openNotification({
        message: 'ERROR',
        description: error.message || error.errors[0]?.message,
        className: 'ant-custom-error'
      })
    }
  }

  const handleSearchValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const generateRateLevel = (maxLevel: number, currentLevel: number): JSX.Element[] => {
    const rateStars: JSX.Element[] = []
    for (let i = 0; i < maxLevel; i++) {
      rateStars.push(<RateStar key={i} isActive={i < currentLevel} />)
    }
    return rateStars
  }

  const displayListItemByType = listTypeBoots.length > 0 && listTypeBoots.map((tab: any) =>
    <div
      className={`meta-block ${boostItemId === tab.id ? 'active' : 'inactive'}`}
      key={tab.boost.id}
      onClick={() => choosedItemDoping(tab)}
    >
      <div className="item-block">
        <div className="sub-block">
          <button className="item-dp">
            <img src={tab.boost.img} alt={tab.boost.description} />
          </button>
          <div className="amount d-flex align-items-center">
            <div className="numb">
              <img src={ITEM_NUMBER} alt="" />
              <span>{tab.amount}</span>
            </div>
            <div className="star d-flex align-items-center">
              {generateRateLevel(3, tab.boost.level)}
            </div>
          </div>
        </div>
      </div>
      <div className="count-item">
        <img src={INPUT_PREVIEW} alt="" />
        <span className='color-white'>{tab.boost.name}</span>
      </div>
    </div>
  );
  return (
    <Modal>
      <SelectItemModalStyled>
        <div className='choose-horse-modal modal-select-item'>
          <button className='close-btn p-0 position-absolute' role='button' onClick={handleBackModalCancel}>
            <img src={CLOSE_BTN} alt='close' />
          </button>
          {isLoading ? (
            <span className='btn-name d-flex align-items-center justify-content-center'>
              {isLoading && <Spin size="large" className='mt-2 mr-3' />}
            </span>
          ) : (
            <div className='confirm-horse'>
              <h4>{t(`${ITEMS_MESSAGE}.select_item`)}</h4>
              <img className='iframe' src={IFRAME_ITEM} alt="" />
              {displayListItemByType && (
                <p className='confirm-horse-title color-white'>{t(`${ITEMS_MESSAGE}.choose_items`)}</p>
              )}
              <div className="list-item infinite-scroll-component">
                {displayListItemByType ? displayListItemByType : (
                  <p className='confirm-horse-title color-white'>{t(`${ITEMS_MESSAGE}.no_items`)}</p>
                )}
              </div>
              <div className='confirm-horse-btns d-flex align-items-center justify-content-center'>
                <button
                  className={`back-btn ${boostItemId !== '' ? 'active' : 'disable'}`}
                  onClick={() =>
                    boostItemId !== '' ? handlePreviewItems() : ''
                  }>
                  <img src={VIEW_BUTTON} alt='back' />
                  <span className="btn-name">View</span>
                </button>
                <button
                  onClick={() => boostItemId !== '' ? OnOpenModalUse() : ''}
                  className={`confirm-btn ${boostItemId !== '' ? 'active' : 'disable'}`
                  }>
                  <img src={BG_BUTTON} alt='confirm' />
                  <span className="btn-name">Use</span>
                </button>
              </div>
            </div>
          )}
        </div>
      </SelectItemModalStyled>
      {isModalOpen &&
        <HourseItemModalDetail
          dataPreview={itemDetailPreview}
          onConfirmUseItem={OnOpenModalUse}
          onCloseButtonClick={handleCloseModal}
        />}

      {isModalConfirmSelectOpen &&
        <HourseItemConfirmSelectedModal
          dataPreview={itemDetailPreview}
          onConfirmUse={
            isChangeItem ? handleonConfirmChange : handleonConfirmUse
          }
          onCloseButtonClick={handleCloseModalConfirm}
          searchValue={searchValue}
          handleSearchValueChanged={handleSearchValueChanged}
          isChangeItem={false}
        />}
    </Modal>
  )
}

export default SelectItemModal
