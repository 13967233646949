/* eslint-disable @typescript-eslint/no-explicit-any */
import ItemDetailContainer from './styled'
import { CLOSE_BTN } from 'assets/images'
import { Modal, TwoLineTitle } from 'shared'
import Button from 'shared/Button'

interface itemProps {
  open: boolean
  onCancel: () => void
  boostItem: any
  className?: string
  onConfirm: () => void
  isShowBtn?: boolean
}

const ItemInformationModal = ({ onCancel, boostItem, className, onConfirm, isShowBtn = true }: itemProps) => {
  const { boost } = boostItem

  return (
    <Modal onOverlayClick={onCancel}>
      <ItemDetailContainer className={className}>
        <TwoLineTitle text='Item information' customClass='title' />
        <>
          <button className='close-btn p-0 position-absolute' role='button' onClick={onCancel}>
            <img src={CLOSE_BTN} alt='close' />
          </button>
          <div className='attribute-content'>
            <div className='avatar-box'>
              <div className='avatar'>
                <img src={boost?.img} alt='icon' />
              </div>
              <div className='info'>
                <p className='info-title'>{boost?.name}</p>
                <p className='attribute-type'>TYPE: {boost?.effect_type}</p>
              </div>
            </div>
            <div className='content-box'>
              <div className='attribute-head'>
                <p>Attributes</p>
              </div>
              <div className='attribute-info'>
                <p>
                  {boost?.boost_type === 'ENERGY' ? (
                    boost?.effect?.recover === 'FULL' ? (
                      'FULL'
                    ) : (
                      '1 LEVEL'
                    )
                  ) : (
                    <>
                      <span>{boost?.effect?.effect?.master_effect_type}</span>
                      <span>
                        {` + `}
                        {boost?.effect?.effect?.value}
                      </span>
                      <span>{boost?.effect?.effect?.unit === 'PERCENT' ? '%' : ''}</span>
                    </>
                  )}
                </p>
              </div>
              <div className='attribute-head'>
                <p>Informations</p>
              </div>
              <div className='attribute-info'>
                <p>{boost?.description || '---'}</p>
              </div>
            </div>
          </div>

          <div className='button-row'>
            {isShowBtn ? (
              <Button buttonName='Use' onClickButton={onConfirm} width={140} />
            ) : (
              <div className='text color-orange'>You must return to the horse details screen to use it.</div>
            )}
          </div>
        </>
      </ItemDetailContainer>
    </Modal>
  )
}

export default ItemInformationModal
