/* eslint-disable @typescript-eslint/no-explicit-any */
import { Spin } from 'antd'
import specialRaceApi from 'apis/specialRaceApi'
import { configs, paths } from 'apps'
import { STATUS_TRANSACTION, timeCheckNonce } from 'apps/constants'
import { CLOSE_BTN } from 'assets/images'
import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import InProgressBalanceModal from 'features/Balance/components/InProgress'
import { ChooseHorseItem } from 'features/Race/components'
import ConfirmOkModal from 'features/Race/components/ConfirmOkModal'
import { useToggle } from 'hooks'
import { NOTIFICATION_MESSAGE, SPECIAL_MESSAGE } from 'i18n/constants'
import horseNFTABI from 'json/HorseNFT.json'
import specialHorseFarm from 'json/SpecialHorseFarm.json'
import { HorseAvailable } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Modal } from 'shared'
import Button from 'shared/Button'
import { convertGasPrice, handleAsyncRequest } from 'utils/helper'
import openNotification from 'utils/toast'
import ConfirmSpecialRaceModalStyled from './styled'
import { getWeb3Provider } from 'utils/metamask'

interface ConfirmSpecialRaceModalProps {
  toggleIsModalOpen?: (value?: boolean) => void
  toggleIsChooseHorseModal?: (value?: boolean) => void
  onCloseButtonClick?: () => void
  horse?: HorseAvailable
  isWithdraw: boolean
  toggleIsRefresh: (value?: boolean) => void
}

const contract = {
  specialHorseFarm: configs.specialHorseFarm,
  horseNFT: configs.horseNFT,
  transporter: configs.transporter
}

function ConfirmSpecialRaceModal({
  toggleIsModalOpen,
  onCloseButtonClick,
  toggleIsChooseHorseModal,
  horse,
  isWithdraw,
  toggleIsRefresh
}: ConfirmSpecialRaceModalProps) {
  const { t } = useTranslation()
  const [horseDetail, setHorseDetail] = useState<HorseAvailable>()
  const [isLoading, setIsLoading] = useState(false)

  const [resultHorseContract, setResultHorseContract] = useState<any>()
  // const [params,] = useState<MyHorseListParams>(myHorseListParams)
  // START: 20231113 TQViet [PRIVATE_WALLET-407]-[Intergration_MH] Create Pop-up Adil Wallet MetaHorse
  const web3Provider = getWeb3Provider()
  const provider = new ethers.providers.Web3Provider(web3Provider)
  // END: 20231113 TQViet [PRIVATE_WALLET-407]-[Intergration_MH] Create Pop-up Adil Wallet MetaHorse
  const signer = provider.getSigner()

  const [isAddHorse, toggleIsAddHorse] = useToggle(false)
  const [isApprovedHorse, toggleIsApproveHorse] = useToggle(false)
  const [isWithdrawContract, toggleIsWithdrawContract] = useToggle(false)
  const [openConfirmLeaseModal, toggleOpenConfirmLeaseModal] = useToggle(false)
  const [openInProgressBalanceModal, toggleOpenInProgressBalanceModal] = useToggle(false)
  const [messageError, setMessageError] = useState('')
  const { pathname: currentPathname } = useLocation()

  const checkIsOldFarm = () => {
    const slicePathName = currentPathname?.split('/').slice(2)[0]
    if (slicePathName === paths.subDomainSpecialRace.oldFarm()) return true
    else return false
  }

  const addHorseSpecial = async () => {
    setIsLoading(true)
    const [error, result]: any = await handleAsyncRequest(
      specialRaceApi.postHorseSpecial({ token_id: [horseDetail?.token_id] })
    )
    if (error) {
      setIsLoading(false)
      setMessageError(error?.message)
      toggleOpenConfirmLeaseModal(false)
      return
    }
    if (result) {
      setResultHorseContract(result.data)
      toggleIsApproveHorse(true)
    }
    toggleOpenInProgressBalanceModal(true)
    setIsLoading(false)
  }

  const withdrawHorseSpecial = async () => {
    setIsLoading(true)
    const [error, result]: any = await handleAsyncRequest(checkIsOldFarm() ? specialRaceApi.postOldFarmWithdraw({ token_id: [horseDetail?.token_id] }) :
      specialRaceApi.postWithdrawSpecial({ token_id: [horseDetail?.token_id] })
    )
    if (error) {
      setIsLoading(false)
      setMessageError(error?.message)
      toggleOpenConfirmLeaseModal(false)
      return
    }
    if (result) {
      setResultHorseContract(result.data)
      toggleIsWithdrawContract(true)
      toggleOpenInProgressBalanceModal(true)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (isApprovedHorse) {
      approveLeaseHorseContract()
    }
  }, [isApprovedHorse])

  useEffect(() => {
    if (isWithdrawContract) {
      withdrawHorseContract()
    }
  }, [isWithdrawContract])

  useEffect(() => {
    if (isAddHorse) {
      leaseHorseContract()
    }
  }, [isAddHorse])

  const approveLeaseHorseContract = async () => {
    try {
      const { ethereum } = window
      if (!ethereum) return
      if (!resultHorseContract) return
      const leaseHorseContract = new ethers.Contract(contract.horseNFT, horseNFTABI.contract.abi, signer)
      const coverTokenId = new BigNumber(resultHorseContract.token_ids).toFixed()
      const coverGasPrice = convertGasPrice(resultHorseContract.gas_price)
      try {
        await leaseHorseContract.approve(contract.transporter, coverTokenId, { gasPrice: coverGasPrice })
        toggleIsAddHorse(true)
      } catch (err) {
        transactionFaild()
      }
    } catch (err) {
      transactionFaild()
    }
  }

  const leaseHorseContract = async () => {
    if (!resultHorseContract) return
    try {
      const addHorseContract = new ethers.Contract(configs.specialHorseFarm, specialHorseFarm.contract.abi, signer)
      const coverBlockExpired = new BigNumber(resultHorseContract.block_expired).toFixed()
      const coverGasPrice = convertGasPrice(resultHorseContract.gas_price)

      try {
        const tx = await addHorseContract.lease({
          owner: resultHorseContract.owner,
          horseId: resultHorseContract.token_ids,
          blockExpired: coverBlockExpired,
          nonce: resultHorseContract.nonce,
          v: resultHorseContract.v,
          r: resultHorseContract.r,
          s: resultHorseContract.s
        }, { gasPrice: coverGasPrice })
        if (tx.hash) {
          await provider.waitForTransaction(tx.hash)
          checkNonceWithDrawContract(resultHorseContract.nonce)
        }
      } catch (err) {
        transactionFaild()
      }
    } catch (err) {
      transactionFaild()
    }
  }

  const withdrawHorseContract = async () => {
    if (!resultHorseContract) return
    try {
      const addHorseContract = new ethers.Contract(configs.specialHorseFarm, specialHorseFarm.contract.abi, signer)
      const coverBlockExpired = new BigNumber(resultHorseContract.block_expired).toFixed()
      // const coverHorseId = new BigNumber(resultHorseContract.token_id).toFixed()
      try {
        const tx = await addHorseContract.withdraw({
          owner: resultHorseContract.owner,
          horseId: resultHorseContract.token_ids,
          blockExpired: coverBlockExpired,
          nonce: resultHorseContract.nonce,
          v: resultHorseContract.v,
          r: resultHorseContract.r,
          s: resultHorseContract.s
        })
        if (tx.hash) {
          await provider.waitForTransaction(tx.hash)
          checkNonceWithDrawContract(resultHorseContract.nonce)
        }
      } catch (err) {
        transactionFaild()
      }
    } catch (err) {
      transactionFaild()
    }
  }

  useEffect(() => {
    setHorseDetail(horse)
  }, [])

  const handleBackModalChooseHorse = () => {
    toggleIsChooseHorseModal?.(true)
    onCloseButtonClick?.()
    toggleOpenInProgressBalanceModal(false)
  }

  const transactionSuccess = () => {
    toggleOpenInProgressBalanceModal(false)
    toggleIsModalOpen?.(false)
    toggleOpenConfirmLeaseModal(false)
    toggleIsApproveHorse(false)
    if (isWithdraw) {
      pushNotification(t(`${SPECIAL_MESSAGE}.successTransaction`), true)
    } else {
      pushNotification(t(`${SPECIAL_MESSAGE}.successAddTransaction`), true)

    }
    toggleIsRefresh()
  }

  const transactionFaild = () => {
    toggleOpenInProgressBalanceModal(false)
    setMessageError(t(`${SPECIAL_MESSAGE}.failedTransaction`))
    toggleOpenConfirmLeaseModal(false)
    toggleIsApproveHorse(false)
    toggleIsAddHorse(false)
  }

  const transactionPending = () => {
    toggleOpenInProgressBalanceModal(false)
    setMessageError(t(`${NOTIFICATION_MESSAGE}.transferredPending`))
    toggleOpenConfirmLeaseModal(false)
    toggleIsApproveHorse(false)
    toggleIsAddHorse(false)
  }

  const checkNonceWithDrawContract = async (nonce: string) => {
    let checkNonceExits = null as any
    let x = 0
    const intervalID = setInterval(async () => {
      const [, result] = await handleAsyncRequest(specialRaceApi.postCheckNonce({ nonce }))
      if (!result) {
        transactionFaild()
        clearInterval(intervalID)
        return
      }
      checkNonceExits = result.data.status
      if (checkNonceExits === STATUS_TRANSACTION.success) {
        transactionSuccess()
        clearInterval(intervalID)
      }

      if (checkNonceExits === STATUS_TRANSACTION.expired) {
        transactionFaild()
        clearInterval(intervalID)
      }

      if (++x === 12) {
        if (checkNonceExits === STATUS_TRANSACTION.pending) {
          transactionPending()
          clearInterval(intervalID)
        }
      }
    }, timeCheckNonce)
  }

  const pushNotification = (description: string, isSuccess?: boolean) => {
    openNotification({
      message: '',
      description: description,
      className: isSuccess ? 'toast-success' : 'toast-error'
    })
  }

  const handleClickOpenConfirmModal = () => {
    setMessageError('')
    toggleOpenConfirmLeaseModal(true)
  }

  return (
    <Modal onOverlayClick={toggleIsModalOpen}>
      {!isLoading ? (
        <ConfirmSpecialRaceModalStyled>
          <div className='choose-horse-modal'>
            <button className='close-btn p-0 position-absolute' role='button' onClick={handleBackModalChooseHorse}>
              <img src={CLOSE_BTN} alt='close' />
            </button>
            <div className='choose-title line'>
              {isWithdraw ? (
                <div className='color-orange'>WITHDRAW NFT TO WALLET</div>
              ) : (
                <>
                  <div className='color-primary text-uppercase'> {t(`${SPECIAL_MESSAGE}.registerHorse`)} </div>
                </>
              )}
            </div>
            {!isWithdraw && <div className='text-content'>You have chosen this horse</div>}

            {horseDetail && (
              <div className='horse-list-container d-flex flex-column'>
                <ChooseHorseItem horse={horseDetail} customClass='active' />
              </div>
            )}
            {!isWithdraw && <div className='text-content'>We will process to put your horse into special race farm
              <br />
              Are you OK with that?</div>}

            <div className='confirm-horse'>
              {/* <p className='confirm-horse-title color-white'>{t(`${NOTIFICATION_MESSAGE}.okWithThis`)}</p> */}
              {messageError && messageError?.length > 0 ? (
                <div className='color-red d-flex justify-content-center message-err'> {messageError}</div>
              ) : (
                ''
              )}
              <div className='confirm-horse-btns d-flex align-items-center justify-content-center'>
                <Button
                  buttonName={isWithdraw ? 'Withdraw' : 'Confirm'}
                  onClickButton={handleClickOpenConfirmModal}
                  width={130}
                  btnCancel={isWithdraw}
                />
              </div>
            </div>
          </div>
        </ConfirmSpecialRaceModalStyled>
      ) : (
        <Spin className='mt-2 mr-3' />
      )}

      {openConfirmLeaseModal && (
        <ConfirmOkModal
          toggleIsModalOpen={toggleOpenConfirmLeaseModal}
          onCloseButtonClick={toggleOpenConfirmLeaseModal}
          onConfirm={isWithdraw ? withdrawHorseSpecial : addHorseSpecial}
          message={
            <div className='font-size-24 message-content'>
              {!isWithdraw ?
                `To register to special race, your NFT horse will be transfer to farm.
                  A fee(ADIL) is required for farming.
                  Afer put into farm, your authority over your horse will temporary locked.

                  Withdraw NFT horse from farm to your wallet will also require fee(ADIL).
                  
                  Do you want to register your NFT horse to special race farm?`
                :
                `To register to special race, your NFT horse will be transfer to farm. 
                A fee(ADIL) is required for farming.
                Afer put into farm, your authority over your horse will temporary locked.

                Withdraw NFT horse from farm to your wallet will also require fee(ADIL).
                
                Do you want to withdraw your NFT horse to wallet?`}
            </div>
          }
          isLoading={isLoading}
        />
      )}

      {openInProgressBalanceModal && (
        <InProgressBalanceModal title={isWithdraw ? 'WITHDRAW IN PROGRESS' : 'FARMING IN PROGRESS'} />
      )}
    </Modal>
  )
}

export default ConfirmSpecialRaceModal
