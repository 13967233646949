import links from './links'

export const headerProps = [
  {
    name: 'Race',
    key: 'race',
    path: links.race.open()
  },
  {
    name: 'Lending',
    key: 'lending',
    path: links.market.market()
  },
  {
    name: 'Special race',
    key: 'special-race',
    path: links.specialRace.currentRace(),
    children: [
      {
        name: 'Current event',
        key: 'current-event',
        path: links.specialRace.currentRace()
      },
      {
        name: 'Event history',
        key: 'event-history',
        path: links.specialRace.raceHistory()
      },
      {
        name: 'Old farm',
        key: 'old-farm',
        path: links.specialRace.oldFarm()
      }
    ]
  },
  {
    name: 'Guild',
    key: 'guild',
    path: links.guild.guildList(),
    children: [
      {
        name: 'My guild',
        key: 'my-guild',
        path: links.guild.information(),
        isLogged: true,
        isGuild: true
      },
      {
        name: 'My guild setting',
        key: 'my-guild-setting',
        path: links.guild.inCome(),
        isLogged: true,
        isGuild: true,
        isGuildMaster: true
      },
      {
        name: 'My profile',
        key: 'my-profile',
        path: links.guild.myProfile(),
        isLogged: true,
        isGuild: true
      },
      {
        name: 'Guild List',
        key: 'guild-list',
        path: links.guild.guildList(),
        isGuild: true
      },
      {
        name: 'Join guild',
        key: 'join-guild',
        path: links.guild.joinGuild(),
        isGuild: false,
        isLogged: true
      },
      {
        name: 'My horses',
        key: 'my-horse',
        // path: links.guild.guildFarm(),
        path: links.guild.myHorses(),
        isLogged: true
      },
      {
        name: 'Create guild',
        key: 'create-guild',
        path: links.guild.createGuild(),
        isLogged: true,
        isGuild: false
      }
    ]
  },
  {
    name: 'Shop',
    key: 'shop',
    path: links.shop.buyItems()
  }
]
