/* eslint-disable @typescript-eslint/no-explicit-any */
import raceApi from 'apis/raceApi'
import userApi from 'apis/userApi'
import { links, paths } from 'apps'
import { CURRENCY_TYPE, ROLE_GUILD, STATUS } from 'apps/constants'
import {
  AVATAR_DEFAULT,
  CANCEL_COUNT_DOWN,
  CANCEL_COUNT_DOWN_DISABLE,
  FEMALE_ICON,
  GAME_TOKEN_BIRU,
  GAME_TOKEN_KUDA,
  GAME_TOKEN_MERAH,
  ICON_LOCK,
  MALE_ICON,
  ICON_HIGHLIGHT,
  VIEW_DOPING
} from 'assets/images'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { setCoinUser } from 'features/Balance/coinUser.slice'
import { useAppDispatch } from 'hooks'
import { NOTIFICATION_MESSAGE } from 'i18n/constants'
import _ from 'lodash'
import {
  CurrentUser,
  Gate,
  GetCurrentRaceListParams,
  GetProfit,
  GetRaceListParams,
  GetRaceListPopupParams,
  GetSpecialRace,
  GuildInfo,
  GuildMembersResponse,
  LENDING_TYPE,
  MyHorseListParams,
  Race,
  RecordRace
} from 'models'
import { Fragment, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactLoading from 'react-loading'
import { useLocation, useNavigate } from 'react-router-dom'
import { Cell, Column, useTable } from 'react-table'
import { ClassTag } from 'shared'
import ButtonCustom from 'shared/ButtonCustom'
import ToolTip from 'shared/ToolTip'
import { accessor, guild, profitColumns, specialRace } from 'utils/columns'
import {
  convertPluralText,
  getGuildTag,
  handleAsyncRequest,
  ordinalSuffixOf,
  shortenRaceCourseNameClone,
  shortenRaceNameV2,
  shortenUserName
} from 'utils/helper'
import ConfirmCancelModal from '../ConfirmCancelModal'
import CountDownTime from '../CountDownTime'
import RaceTableStyled from './styled'

interface RaceTableProps {
  data: any[]
  columns: Column[]
  isRowClickable?: boolean
  onRegisterButtonClick?: (gateNumber: number) => void
  raisePage?: any
  params: GetRaceListParams | MyHorseListParams | GetCurrentRaceListParams
  loader?: boolean
  status?: string
  onQuickView?: (id: number) => void
  currentUser?: CurrentUser
  paramsFilterResult?: GetRaceListPopupParams
  checkLoadingPage?: boolean
  timeCancelRace?: number
  toggleIsModalConfirmCancelOpen?: (value?: boolean) => void
  isModalConfirmCancelOpen?: boolean
  totalRace?: number
  onRowClick?: (value: number) => void
  noDataText?: string
  isHorseTable?: boolean
  onClickOneCell?: (id: number) => void
}

const handleStartIn = (cell: Cell) => {
  const slicePathName = window.location.pathname?.split('/').slice(1)[0]
  if ((cell.row.original as Race).status === 'SCHEDULING') {
    if (slicePathName === paths.subDomainSpecialRace.index()) {
      return 'Starting soon'
    } else {
      return 'Scheduling'
    }
  }

  if ((cell.row.original as Race).status === 'WAITING') {
    const countDownTime = +(cell.row.original as Race).start_at + 1000 - new Date().getTime()
    return <CountDownTime time={countDownTime} />
  }

  if ((cell.row.original as Race).status === 'LIVE') {
    return (
      <div className='starts-in color-red font-bold d-flex align-items-center'>
        <div className='dot' /> <span className='live-text'>Live</span>
      </div>
    )
  }
  if ((cell.row.original as Race).status === 'CLOSED' || (cell.row.original as Race).status === 'RESULT') {
    return (
      <div className='value color-white'>
        <div>CLOSED</div>
        <div>
          {dayjs((cell.row.original as Race).close_at).format('DD/MM/YYYY')}{' '}
          {dayjs((cell.row.original as Race).close_at).format('HH:mm')}
        </div>
      </div>
    )
  }
}

const handleCancelCountDown = async (id: number) => {
  await handleAsyncRequest(raceApi.deleteCancelCountDown(id))
}

const timeConversion = (duration: number) => {
  const convertNumber = (number: number) => (number === 0 ? '00' : number > 9 ? number.toString() : `0${number}`)
  const portions: string[] = []

  const msInHour = 1000 * 60 * 60
  const hours = Math.trunc(duration / msInHour)
  if (hours > 0) {
    portions.push(convertNumber(hours) + ':')
    duration = duration - hours * msInHour
  }

  const msInMinute = 1000 * 60
  const minutes = Math.trunc(duration / msInMinute)
  portions.push(convertNumber(minutes) + ':')
  duration = duration - minutes * msInMinute

  const seconds = Math.trunc(duration / 1000)
  portions.push(convertNumber(seconds))
  return portions.join('')
}

const handleRaceName = (cell: Cell) => {
  if (
    (cell.row.original as Race).status === 'OPEN' ||
    (cell.row.original as Race).status === 'SCHEDULING' ||
    (cell.row.original as Race).status === 'WAITING' ||
    (cell.row.original as Race).status === 'LIVE' ||
    (cell.row.original as Race).status === 'CLOSED'
  ) {
    return <ToolTip name={cell.value} />
  }
}

const handleDisplayTotalPrize = (cell: Cell) => {
  const { row } = cell
  const { total_prize } = row.values
  const entry_fee_origin = (cell.row.original as Race).entry_fee
  const entry_fee_detail = row.values.entry_fee as any

  let getEntryFeeData = entry_fee_origin
  if (entry_fee_origin === undefined) {
    getEntryFeeData = entry_fee_detail
  }

  if (getEntryFeeData === 0) {
    if (total_prize > 0 && cell.value > 0) {
      return (
        <div className='prize'>
          <span className='color-biru font-bold'>{cell.value}</span>{' '}
          <img src={GAME_TOKEN_BIRU} alt='' className='game-token-biru' />
        </div>
      )
    } else {
      return '---'
    }
  } else {
    if (total_prize === undefined) {
      return <span>---</span>
    } else {
      return (
        <div className='prize'>
          <span className='color-merah font-bold'>{cell.value}</span>{' '}
          <img src={GAME_TOKEN_MERAH} alt='' className='game-token-merah' />
        </div>
      )
    }
  }
}

const handleDisplayRacePrize = (cell: Cell) => {
  const { row } = cell
  const { getEntryFee } = row.original as any
  const { race_prize } = row.values

  if (getEntryFee === 0) {
    if (race_prize > 0) {
      return (
        <>
          <span className='color-biru font-bold'>{cell.value}</span>{' '}
          <img src={GAME_TOKEN_BIRU} alt='' className='game-token-biru' />
        </>
      )
    } else {
      return '---'
    }
  } else {
    if (race_prize === undefined) {
      return <span>---</span>
    } else {
      return (
        <div className='prize'>
          <span className='color-merah font-bold'>{cell.value}</span>{' '}
          <img src={GAME_TOKEN_MERAH} alt='' className='game-token-merah' />
        </div>
      )
    }
  }
}

const handleFieldType = (field: string) => {
  if (field === 'TURF') {
    return <span className='font-bold color-turf-field'>{field}</span>
  } else {
    return <span className='font-bold color-orange'>{field}</span>
  }
}

const handleProfit = (cell: Cell) => {
  if (cell.value === null) return '---'
  if ((cell.row.original as GetProfit).lending_type === LENDING_TYPE.Share) {
    return <span className='color-need-kuda'>{cell.value} %</span>
  } else
    return (
      <>
        <span className='color-kuda'>{cell.value}</span> <img src={GAME_TOKEN_KUDA} alt='kuda' className='kuda' />
      </>
    )
}

const handlDisplayProfitOwner = (cell: Cell) => {
  return (
    <>
      {!_.isEmpty(cell?.value) ? (
        <span className={`font-bold total-prize ${cell?.value.length > 1 ? 'revert_biru' : ''}`}>
          {cell.value.map((item: any, index: number) => {
            return (
              <div key={index}>
                {item.item_type === CURRENCY_TYPE.BIRU && (
                  <div>
                    <span className='color-biru font-bold'>{item?.amount}</span>
                    <img src={GAME_TOKEN_BIRU} alt='BIRU' className='game-token-biru' />
                  </div>
                )}
                {item.item_type === CURRENCY_TYPE.MERAH && (
                  <div className='prize'>
                    <span className='color-merah font-bold'>{item?.amount}</span>
                    <img src={GAME_TOKEN_MERAH} alt='MERAH' className='game-token-merah' />
                  </div>
                )}
                {item.item_type === CURRENCY_TYPE.KUDA && (
                  <div>
                    <span className='color-kuda font-bold'>{item?.amount}</span>
                    <img src={GAME_TOKEN_KUDA} alt='kuda' className='kuda' />
                  </div>
                )}
              </div>
            )
          })}
        </span>
      ) : (
        '0'
      )}
    </>
  )
}

const handleDisplaySpecialHorseInfo = (cell: Cell) => {
  const isUseDoping = (cell.row.original as any).equipped_boost_item

  return (
    <div className='d-flex align-items-center justify-content-start gap-2'>
      <div>
        <img src={(cell.row.original as GetSpecialRace).sub_avatar} alt='sub avatar' width={40} />
      </div>
      <div className='cut-text'>{shortenRaceCourseNameClone((cell.row.original as GetSpecialRace).horse_name)}</div>
      <div>
        <img
          src={(cell.row.original as GetSpecialRace).horse_gender === 'MALE' ? MALE_ICON : FEMALE_ICON}
          className='horse-gender'
          width={18}
        />
      </div>
      {  isUseDoping && (
        <img src={VIEW_DOPING} alt="" className='used-doping' />
      )}
    </div>
  )
}
const guildHorseInfor = (cell: Cell, user?: CurrentUser) => {
  
  const isOwner = user && (cell.row.original as any).owner?.id === user.id ? true : false
  const isUseDoping = user && (cell.row.original as any).horse?.equipped_boost_item
  return (
    <div className='d-flex align-items-center justify-content-start gap-2'>
      <div>
        <img src={(cell.row.original as GetSpecialRace).sub_avatar} alt='sub avatar' width={40} />
      </div>
      <div className={`cut-text ${isOwner ? 'color-hightlight' : ''}`}>
        {shortenRaceCourseNameClone((cell.row.original as GetSpecialRace).horse_name)}
      </div>
      <div>
        <img
          src={(cell.row.original as GetSpecialRace).horse_gender === 'MALE' ? MALE_ICON : FEMALE_ICON}
          className='horse-gender'
          width={18}
        />
      </div>
      {  isUseDoping && (
        <img src={VIEW_DOPING} alt="" className='used-doping' />
      )}
    </div>
  )
}

const guildMemberInfor = (cell: Cell, user?: CurrentUser) => {
  const isOwner = user && (cell.row.original as any).id === user.id ? true : false
  return (
    <div className='d-flex align-items-center justify-content-start gap-2'>
      <div className={`cut-text ${isOwner ? 'color-hightlight' : ''}`}>
        {shortenRaceNameV2((cell.row.original as any).user_name)}
      </div>
    </div>
  )
}

const guildListInfor = (cell: Cell, user?: CurrentUser) => {
  const isOwner = user && (cell.row.original as any)?.id === user?.guild_id ? true : false
  return (
    <div className='d-flex align-items-center justify-content-start gap-2'>
      <div className={`cut-text ${isOwner ? 'color-hightlight' : ''}`}>
        <span dangerouslySetInnerHTML={{ __html: cell?.value }} />
      </div>
    </div>
  )
}

const guildRenterInfor = (cell: Cell, user?: CurrentUser) => {
  const isRenter = user && (cell.row.original as any)?.renter?.id === user.id ? true : false
  return (
    <div className='d-flex align-items-center justify-content-start gap-2'>
      <div className={`cut-text ${isRenter ? 'color-hightlight' : ''}`}>{shortenRaceCourseNameClone(cell?.value)}</div>
    </div>
  )
}

const guildInfor = (cell: Cell) => {
  return (
    <div className='d-flex align-items-center justify-content-center gap-2'>
      <div>
        {(cell.row.original as GuildInfo)?.horse_guild?.avatar ? (
          <img src={(cell.row.original as GuildInfo)?.horse_guild?.avatar} alt='avatar' width={40} />
        ) : (
          <div>---</div>
        )}
      </div>
      {(cell.row.original as GuildInfo)?.horse_guild?.guild_tag ? (
        <span className='color-yellow'>[{(cell.row.original as GuildInfo)?.horse_guild?.guild_tag}]</span>
      ) : (
        <div>---</div>
      )}
    </div>
  )
}

const renderDataTable = (
  cell: Cell,
  header: string,
  time: number,
  toggleIsModalConfirmCancelOpen?: (value?: boolean) => void,
  status?: string,
  renderConfirmCancelHorseModal?: (value?: any) => void,
  onClickOneCell?: (value: any) => void,
  currentUser?: CurrentUser
) => {
  switch (header) {
    case accessor.cancelCountDown:
      return (
        <div className='d-flex align-items-center justify-content-start gap-2 count-down-btns'>
          <>
            {time > 0 ? (
              <button disabled className='btn-waiting-cancel'>
                {<img src={CANCEL_COUNT_DOWN_DISABLE} />}
                <span className='color-yellow'>{timeConversion(time)}</span>
              </button>
            ) : status === STATUS.SCHEDULING || status === STATUS.WAITING ? (
              <> </>
            ) : (
              <button
                onClick={() => {
                  toggleIsModalConfirmCancelOpen?.(true)
                  renderConfirmCancelHorseModal?.(cell.value)
                }}
              >
                <img src={CANCEL_COUNT_DOWN} />
              </button>
            )}
          </>
          {renderConfirmCancelHorseModal?.(cell.value)}
        </div>
      )
    case accessor.racePosition:
      return <div dangerouslySetInnerHTML={{ __html: ordinalSuffixOf(cell.value) }} />
    case accessor.count_down:
      return handleStartIn(cell)
    case accessor.grade:
      return <ClassTag text={cell.value} isActive={true} />
    case accessor.totalPrize:
      return handleDisplayTotalPrize(cell)
    case accessor.recervedPrize:
      return handleDisplayTotalPrize(cell)

    case accessor.racePrize:
      return handleDisplayRacePrize(cell)
    case accessor.experienceReceived:
      return <span className='font-bold'>{cell.value}</span>
    case accessor.field:
      return <span>{handleFieldType(cell.value) ?? ''}</span>
    case accessor.distance:
      return <span className='font-bold'>{cell.value ? `${(cell.value as number).toLocaleString()}m` : '0m'}</span>
    case accessor.entryFee:
      return (
        <div className='entry-fee text-uppercase font-bold'>
          {cell.value === 0 ? (
            <span className='color-primary font-bold'>FREE</span>
          ) : (
            <>
              <span className='color-kuda font-bold'>{cell.value}</span>{' '}
              <img src={GAME_TOKEN_KUDA} alt='kuda' className='kuda' />
            </>
          )}
        </div>
      )
    case accessor.horse:
      return <div dangerouslySetInnerHTML={{ __html: cell.value }} />
    case accessor.highlight:
      return <img className='icon-highlight' src={cell.value ? ICON_HIGHLIGHT : '#'} alt="" />
    case accessor.registered:
      return (
        <div className='font-bold register'>
          <div>{cell.value}/12</div>
        </div>
      )
    case accessor.startIn:
    case accessor.endAt:
      return (
        <span className='value color-white font-bold'>{dayjs(parseInt(cell.value)).format('DD/MM/YYYY HH:mm')}</span>
      )
    case accessor.gate:
      return <div dangerouslySetInnerHTML={{ __html: cell.value }} />
    case accessor.statistic:
      return <div dangerouslySetInnerHTML={{ __html: cell.value }} />
    case accessor.ownerName:
      return (
        <div
          className='cut-text'
          dangerouslySetInnerHTML={{
            __html:
              `<span class='color-yellow'>  ${getGuildTag((cell.row.original as any).owner_guild_tag)} </span>` +
              ' ' +
              shortenUserName(cell.value)
          }}
        />
      )
    case accessor.bloodLine:
      return <div dangerouslySetInnerHTML={{ __html: cell.value }} />
    case accessor.avatar:
      return <div className='avatar-box'>{<img src={cell.value ?? AVATAR_DEFAULT} alt='' className='avatar' />}</div>
    case accessor.raceName:
      return <span className='font-bold race-name'>{handleRaceName(cell)}</span>
    case accessor.raceCourse:
      return <span className='font-bold'>{handleRaceName(cell)}</span>
    case profitColumns.no:
      return <span className='font-bold'>{cell.row.index + 1}</span>
    case profitColumns.rent_fee:
      return <span className='font-bold'>{handleProfit(cell)}</span>
    case profitColumns.prize_for_owner:
      return <span className='font-bold'>{handlDisplayProfitOwner(cell)}</span>
    case accessor.raceNo:
      return (
        <span className='font-bold'>
          <div className='color-orange-200'>{cell.value}</div>
          <div className='font-size-16'>{dayjs((cell.row.original as RecordRace).real_start_at).format('HH:mm')}</div>
        </span>
      )
    case specialRace.horseInfo:
      return <span className='font-bold '>{handleDisplaySpecialHorseInfo(cell)} </span>
    case specialRace.inDate:
      return <div className='value font-bold'>{dayjs(cell.value).format('DD/MM/YYYY HH:mm')} </div>
    case specialRace.chooseToRace:
      if (cell.value) {
        return (
          <div className='value font-bold'>
            {dayjs(cell.value).format('DD/MM/YYYY HH:mm')}{' '}
            <div className='color-orange-200'>{(cell.row.original as RecordRace).race_no}</div>
          </div>
        )
      } else return <div>No selected</div>
    case specialRace.career:
      return (
        <div>
          <span className='font-bold career-horse color-yellow'>{cell.value.total_number_of_races} &nbsp;</span>
          <span className='font-bold'> {cell.value.first_count}/</span>
          <span className='font-bold'>{cell.value.second_count}/</span>
          <span className='font-bold'>{cell.value.third_count}</span>
        </div>
      )

    case specialRace.eventOpenTime:
      return (
        <div className='value font-bold'>
          {dayjs(cell.value * 1000).format('DD/MM/YYYY')} {dayjs(cell.value * 1000).format('HH:mm')}
        </div>
      )
    case specialRace.eventEndTime:
      return <div className='value font-bold'>{dayjs(cell.value * 1000).format('DD/MM/YYYY HH:mm')}</div>
    case specialRace.totalHorseInRace:
      return <div className='font-bold'>{convertPluralText(cell.value, 'horse')} </div>
    case specialRace.totalRace:
      return <div className='font-bold'>{convertPluralText(cell.value, 'race')}</div>
    case specialRace.totalHorse:
      return <div className='font-bold'>{convertPluralText(cell.value, 'horse')}</div>
    case specialRace.EventId:
      return <div className='font-bold'>{cell.row.index + 1}</div>
    case guild.chooseToGuild:
      return (
        <div className='font-bold '>
          <ButtonCustom
            onClickButton={event => {
              event.stopPropagation()
              onClickOneCell && onClickOneCell((cell.row.original as RecordRace).id)
            }}
            disabled={cell.row.values.disabled}
            buttonName='Join'
          />
        </div>
      )
    case guild.manage:
      return currentUser && currentUser?.id === (cell.row.original as RecordRace).id ? (
        <></>
      ) : (
        <div className='font-bold '>
          <ButtonCustom
            onClickButton={event => {
              event.stopPropagation()
              onClickOneCell && onClickOneCell(cell.row.original)
            }}
            buttonName='Manage'
            disabled={
              currentUser?.role_in_guild === ROLE_GUILD.member ||
              (cell.row.original as GuildMembersResponse).role === ROLE_GUILD.master ||
              ((cell.row.original as GuildMembersResponse).role === ROLE_GUILD.manager &&
                currentUser?.role_in_guild === ROLE_GUILD.manager) ||
              !currentUser?.guild_id
            }
          />
        </div>
      )
    case guild.status:
      return <div dangerouslySetInnerHTML={{ __html: cell.value }} />
    case guild.guildHorse:
      return <div>{guildHorseInfor(cell, currentUser)}</div>
    case guild.member:
      return <div>{guildMemberInfor(cell, currentUser)}</div>
    case guild.renterName:
      return <div>{guildRenterInfor(cell, currentUser)}</div>
    case guild.guildName:
      return <div>{guildListInfor(cell, currentUser)}</div>
    case guild.guildTag:
      return <div>{guildInfor(cell)}</div>
    case profitColumns.renter_name:
      return (
        <div>
          <span className='color-yellow'> {getGuildTag((cell.row.original as any).renter_guild_tag)} </span>{' '}
          {shortenRaceCourseNameClone(cell?.value)}
        </div>
      )
    default:
      return <span>{cell.render('Cell')}</span>
  }
}

const isEmptyGate = (row: any): row is Gate => row.horse === null && typeof row.gate === 'number'

function RaceTable({
  columns,
  data,
  isRowClickable = false,
  onRegisterButtonClick,
  raisePage,
  loader = false,
  params,
  status,
  onQuickView,
  currentUser,
  paramsFilterResult,
  checkLoadingPage,
  timeCancelRace,
  toggleIsModalConfirmCancelOpen,
  totalRace,
  isModalConfirmCancelOpen,
  onRowClick,
  noDataText,
  isHorseTable,
  onClickOneCell
}: RaceTableProps) {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { pathname: currentPathname } = useLocation()

  const fetchCoinUser = async () => {
    const [, resultCoinUser] = await handleAsyncRequest(userApi.getUserItems())
    if (!resultCoinUser) return

    dispatch(setCoinUser(resultCoinUser.data))
  }

  const renderConfirmCancelHorseModal = (value: any) => {
    if (isModalConfirmCancelOpen) {
      const handleOk = () => {
        handleCancelCountDown(value).then(() => fetchCoinUser())
        toggleIsModalConfirmCancelOpen?.(false)
      }

      const handleCancel = () => {
        toggleIsModalConfirmCancelOpen?.(false)
      }
      return (
        <ConfirmCancelModal toggleIsModalOpen={handleCancel} onCloseButtonClick={handleCancel} onConfirm={handleOk} />
      )
    }
  }
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data })
  const memoizedRenderDataTable = useCallback(renderDataTable, [
    columns,
    data,
    timeCancelRace,
    status,
    renderConfirmCancelHorseModal
  ])
  const tableBodyClass = classNames('table-body', { ['highlight']: status === 'RESULT' || status === 'CLOSED' })

  const handleRowItemClicked = (value: any) => {
    if (onRowClick) {
      if (isHorseTable) {
        return () => onRowClick(value)
      } else return () => onRowClick(value.id)
    }
    if (!isRowClickable) return

    const slicePathName = currentPathname?.split('/').slice(1)[0]
    if (slicePathName === paths.subDomainSpecialRace.index()) {
      return () => navigate(links.specialRace.raceDetail(value.id.toString()))
    } else {
      return () => navigate(links.race.detail(value.id.toString()))
    }
  }

  const handleRegisterButtonClick = (gateNumber: number) => () => {
    if (!onRegisterButtonClick) return

    onRegisterButtonClick(gateNumber)
  }

  const handleQuickView = (raceId: any) => () => {
    if (!onQuickView) return

    onQuickView(raceId)
  }

  const handleLoadingRaces = () => {
    if (loader === false) {
      return <ReactLoading className='loading' type={'spin'} />
    } else if (totalRace === 0) {
      return <div className='no-data'>{noDataText ? noDataText : t(`${NOTIFICATION_MESSAGE}.dataNotFound`)}</div>
    } else return <></>
  }

  const checkTextAlign = (cell: Cell) => {
    if (
      cell.column.Header === 'entry fee' ||
      cell.column.Header === 'total_prize' ||
      cell.column.Header === 'prize' ||
      cell.column.id === 'received_prize'
    ) {
      return `max-width-80 justify-content-end`
    }

    return `justify-content-start`
  }

  const checkHeader = (column: any) => {
    if (column.Header === 'entry fee') {
      return `padding-left-header-10`
    }
    if (column.Header === 'prize') {
      return `text-center`
    }
  }

  return (
    <RaceTableStyled isRowClickable={isRowClickable}>
      <div className='race-table-container'>
        <div className='race-table'>
          <InfiniteScroll
            dataLength={data.length}
            next={() =>
              typeof raisePage === 'function' &&
              data.length >= 20 &&
              raisePage?.(
                checkLoadingPage
                  ? { ...paramsFilterResult, page: paramsFilterResult && paramsFilterResult.page + 1 }
                  : { ...params, page: params.page + 1 }
              )
            }
            hasMore={true}
            loader={handleLoadingRaces()}
          >
            <table {...getTableProps()} className='table'>
              <thead className='table-head'>
                {headerGroups.map((headerGroup, index) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={index} className='table-row'>
                    {headerGroup.headers.map((column, index) => {
                      return (
                        <th
                          {...column.getHeaderProps()}
                          key={column.id}
                          className={`th text-uppercase font-bold text-left ${index === 0 && 'ps-3'} ${checkHeader(
                            column
                          )}
                          `}
                        >
                          <div dangerouslySetInnerHTML={{ __html: column.render('Header') as any }} />
                          {/* {column.render('Header')} */}
                        </th>
                      )
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className={tableBodyClass}>
                {rows.map((row: any) => {
                  const userLoginId = currentUser?.id
                  prepareRow(row)
                  if (isEmptyGate(row.original)) {
                    return (
                      <tr {...row.getRowProps()} key={row.id} className='table-row'>
                        <td className='table-data text-left font-bold'>
                          <div className={`table-gate-no no-${row.original.gate}`}>
                            <span className='gate-no'>{row.original.gate}</span>
                          </div>
                        </td>
                        <td className='table-data-empty font-bold' colSpan={4}>
                          <button
                            className='btn-select-gate'
                            onClick={handleRegisterButtonClick(row.original.gate as unknown as number)}
                          >
                            {t(`${NOTIFICATION_MESSAGE}.selectThisGate`)}
                          </button>
                        </td>
                      </tr>
                    )
                  }
                  const handleDetailHorse = (row: any): row is Gate[] => {
                    return row.original.detailHorse
                  }

                  const normal = 'table-row position-relative'
                  const hightLight = 'table-row position-relative table-row-primary'
                  return (
                    <tr
                      {...row.getRowProps()}
                      key={row.id}
                      // Check info user_id and owner-user_id
                      className={userLoginId && userLoginId === row.original.user_id ? hightLight : normal}
                    >
                      {row.cells.map((cell: any, index: number) => {
                        return cell.column.id === 'cancel_count_down' &&
                          !(userLoginId && userLoginId === row.original.user_id) ? (
                          <Fragment key={cell.column.id}></Fragment>
                        ) : (
                          <td
                            onClick={
                              cell.column.id !== 'cancel_count_down' &&
                              (handleRowItemClicked(row.original as RecordRace) ||
                                handleQuickView(handleDetailHorse(row) as unknown as Race))
                            }
                            {...cell.getCellProps()}
                            key={cell.column.id}
                            className={
                              `table-data text-center p-0 position-relative ${status?.toLowerCase()} ${cell?.column?.id} ${index === 0 ? 'ps-2' : ''
                              }`}
                          >
                            <div
                              className={`table-data-container d-flex h-100 align-items-center ${checkTextAlign(cell)}`}
                            >
                              {memoizedRenderDataTable(
                                cell,
                                cell.column.id,
                                timeCancelRace || 0,
                                toggleIsModalConfirmCancelOpen,
                                status,
                                renderConfirmCancelHorseModal,
                                onClickOneCell,
                                currentUser
                              )}
                              {(cell.row.original as Race).protected_race && (
                                <img src={ICON_LOCK} alt=' lock' className='icon-lock' width={30} />
                              )}
                            </div>
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
      </div>
    </RaceTableStyled>
  )
}

export default RaceTable
