/* eslint-disable @typescript-eslint/no-explicit-any */
import { CLOSE_BTN, INPUT_PREVIEW, ITEM_NUMBER } from 'assets/images'
import { useToggle } from 'hooks'
import { ApiResponse, Item, ItemResponse, MasterItem } from 'models'
import { Fragment, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, TwoLineTitle } from 'shared'
import { handleAsyncRequest } from 'utils/helper'
import EnergyItemModalStyled from './styled'
import itemApi from 'apis/itemApi'
import ItemInformationModal from '../ItemInformationModal'
import ConfirmOkModal from 'features/Race/components/ConfirmOkModal'
import { ITEMS_MESSAGE } from 'i18n/constants'
import Button from 'shared/Button'
import { notification } from 'antd'

interface EnergyItemModalProps {
  horseId: string
  onOverlayClick?: () => void
  onCloseButtonClick?: () => void
  onClose?: () => void
  fetchHorse?: () => void
  masterItems: MasterItem
}

function EnergyItemModal({
  onOverlayClick,
  onCloseButtonClick,
  horseId,
  onClose,
  fetchHorse,
  masterItems
}: EnergyItemModalProps) {
  const [isLoading, setIsLoading] = useToggle(false)
  const [itemSelected, setItemSelected] = useState<Item>()
  const { t } = useTranslation()
  const [isInforModal, toggleIsInforModal] = useToggle(false)
  const [isUseItemModal, toggleIsUseItemModal] = useToggle(false)
  const energyItems = masterItems.energy_items

  const handleActiveItemClicked = ((item: Item) => {
    setItemSelected(item)
  })
  const handleOpenItemModal = () => {
    toggleIsInforModal(true)
  }
  const handleCloseItemModal = () => {
    toggleIsInforModal(false)
  }

  const handlePutUseItem = async () => {
    setIsLoading(true)
    const [error, itemResponse]: any = await handleAsyncRequest<ApiResponse<ItemResponse>>(
      itemApi.putBosstItem({ horseId: horseId },
        {
          pay_for_energy: false,
          recover_type: itemSelected?.boost?.effect?.recover === "FULL" ? "FULL" : "NOT_FULL"
        })
    )
    if (error) {
      toggleIsUseItemModal(false)
      notification.error({
        message: 'ERROR',
        description: error.message ?? 'Can not use this item for this horse!',
        placement: 'bottomRight',
        duration: 4,
        className: 'ant-custom-error',
        icon: <></>
      })
    }
    if (itemResponse) {
      handleUseItemSuccess()
    }

    setIsLoading(false)
  }
  const handleUseItemSuccess = () => {
    fetchHorse?.()
    toggleIsUseItemModal(false)
    toggleIsInforModal(false)
    onCloseButtonClick?.()
    onClose?.()
    notification.success({
      message: 'SUCCESS',
      description: 'This item is used for this horse!',
      placement: 'bottomRight',
      duration: 4,
      className: 'ant-custom-success'
    })
  }

  const handleOpenUseItem = () => {
    toggleIsUseItemModal()
  }

  const isCheckItems = useMemo(() => {
    if (energyItems.FULL.amount === 0 && energyItems.FULL.amount === 0) {
      return false
    } return true
  }, [energyItems])

  const layoutItems = (item: any) => {
    if (item.amount > 0) {
      return (<div>
        <div className={`item-list ${itemSelected?.id === item?.id && 'active'}`} >
          <div className='item-line' onClick={() => handleActiveItemClicked(item)}>
            <img src={item?.boost?.img} alt='close' />
            <div className="amount d-flex align-items-center">
              <div className="numb">
                <img src={ITEM_NUMBER} alt="" />
                <span>{item?.amount}</span>
              </div>
            </div>
          </div>
        </div>
        <div className='item-amount'>
          <img src={INPUT_PREVIEW} alt='amount' />
          <span className='amount'>
            {item?.boost?.name}
          </span>
        </div>
      </div>)
    }
  }

  return (
    <Modal onOverlayClick={onOverlayClick}>
      <EnergyItemModalStyled>
        <Fragment>
          <button className='close-btn p-0 position-absolute' role='button' onClick={onCloseButtonClick}>
            <img src={CLOSE_BTN} alt='close' />
          </button>
          <TwoLineTitle text='Energy recover' customClass='title' />
          <div className='quick-view'>
            <div className='content'>{isCheckItems ? 'Choose the item you want to use' : 'You have no item to use'}</div>
            <div className='item-container'>
              {layoutItems(energyItems.NOT_FULL)}
              {layoutItems(energyItems.FULL)}
            </div>
            <div className='btn-group'>
              <Button btnRemove onClickButton={handleOpenItemModal} buttonName="VIEW" width={140} height={40} disabled={!itemSelected} />
              <Button onClickButton={handleOpenUseItem} buttonName="USE" width={140} height={40} disabled={!itemSelected} />
            </div>
          </div>
        </Fragment>
        {isInforModal &&
          <ItemInformationModal boostItem={itemSelected} open={isInforModal}
            onCancel={handleCloseItemModal}
            onConfirm={handleOpenUseItem}
          />
        }
        {isUseItemModal && (
          <ConfirmOkModal
            toggleIsModalOpen={toggleIsUseItemModal}
            onCloseButtonClick={toggleIsUseItemModal}
            onConfirm={handlePutUseItem}
            message={itemSelected?.boost?.effect?.recover === "FULL" ? t(`${ITEMS_MESSAGE}.recover_full_energy`) :
              t(`${ITEMS_MESSAGE}.recover_1_energy`)
            }
            isLoading={isLoading}
            heading='Energy recover'
            isContentCenter
          />
        )}
      </EnergyItemModalStyled>
    </Modal >
  )
}

export default EnergyItemModal
