import { BUTTON_DT_BG } from 'assets/images'
import styled from 'styled-components'

const InfoBoxContainer = styled.div`
  .content-box {
    display: flex;

    .name {
      margin-bottom: 0;
    }

    .img-box {
      width: 147px;
      height: 110px;

      img {
        width: 100%;
      }
    }

    .info {
      p {
        font-size: 18px;
        color: #fff;
        .used-doping{
          width: 40px;
          margin-left: 10px;
        }
      }

      .row-info {
        display: flex;
        span {
          color: #fff;
          font-size: 18px;
          display: inline-block;

          &:last-child {
            padding-left: 5px;
          }
        }

        &.first {
          span {
            color: #B3B3B3;
          }
        }
        &.thrid {
          span {
            color: #4EF076;
          }
        }
      }
    }
  }

  .button-detail {
    background: url(${BUTTON_DT_BG});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 146px;
    height: 42px;
    border: none;
    color: #4EF076;
    font-size: 18px;
    margin-top: 50px;
    a {
      text-decoration: none;
    }
  }
`

export default InfoBoxContainer
