/* eslint-disable @typescript-eslint/no-explicit-any */
import { notification } from 'antd'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import Button from 'shared/Button'

import guildApi from 'apis/guildApi'
import ModalManagerMember from 'features/Guild/components/ModalManagerMember'
import { handleAsyncRequest } from 'utils/helper'

import RemoveMemberContainer from './styled'

const RemoveMember = ({onRemoveMemberSuccess}: any) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [api, contextHolder] = notification.useNotification()

  const { guild_id, user_id } = useParams()

  const openNotification = (message: string, description: string) => {
    notification.config({
      duration: 4
    })

    api.info({
      message,
      description,
      placement: 'bottomRight',
      className: message === 'ERROR' ? 'ant-custom-error' : 'ant-custom-success'
    })
  }

  const _handleToggleModal = () => {
    setShowModal(!showModal)
  }

  const _handleUpgrade = async () => {
    setIsLoading(true)

    const [error, result]: any = await handleAsyncRequest(guildApi.putKickMemberOutGuild(Number(guild_id), Number(user_id)));
    setIsLoading(false);
    if (error) {
      openNotification('ERROR', error.message || error.errors[0]?.message)
    }

    if (result) {
      _handleToggleModal()
      onRemoveMemberSuccess()
    }
  }

  return (
    <RemoveMemberContainer>
      {contextHolder}
      <Button
        buttonName='Remove from guild'
        onClickButton={_handleToggleModal}
        btnRemove
        height={42}
        width={170}
        classNames='remove-btn'
      />
      {showModal && (
        <ModalManagerMember
          title='Remove from guild'
          message={<p>Only expel members when they violate guild rules. <br />Are you sure you want to kick this member?</p>}
          onClose={_handleToggleModal}
          onConfirm={_handleUpgrade}
          loading={isLoading}
          titleColor='#F8AB3C'
        />
      )}
    </RemoveMemberContainer>
  )
}

export default RemoveMember
