/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Form, notification } from 'antd'
import guildApi from 'apis/guildApi'
import { links } from 'apps'
import { LINE_GUILD_GRADIENT, ONE_LINE, ONE_LINE_LEFT, ONE_LINE_RIGTH } from 'assets/images'
import ModalConfirmEditGuild from 'features/Guild/components/ModalConfirmEditGuild'
import { RequestLoginModal } from 'features/Race/components'
import { useToggle } from 'hooks'
import { GUILD_MESSAGE } from 'i18n/constants'
import { EditGuildRequest } from 'models'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
// import { useNavigate } from 'react-router-dom'
import { InputCustomV2 } from 'shared'
import Button from 'shared/Button'
import { handleAsyncRequest } from 'utils/helper'
import UpdateInfoStyled from './styled'
import InputTextarea from 'shared/InputTextarea'
import Loading from '../Loading'

export default function UpdateInfo() {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [api, contextHolder] = notification.useNotification()

  const [logoChoose, setLogoChoose] = useState<string>('')
  const [isShowModalConfirmEditGuild, setIsShowModalConfirmEditGuild] = useState<boolean>(false)
  // const [dataCreateGuild, setDataCreateGuild] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [guildData, setGuildData] = useState<any>(null)
  const [guildDataLoading, setGuildDataLoading] = useState<boolean>(() => true)
  const [isRequestLoginModalOpen, toggleIsRequestLoginModalOpen] = useToggle(false)
  // const navigate = useNavigate()
  // const { auth, profile, coinUser } = useAppSelector(state => state)
  const [valCheckbox, setValCheckbox] = useState(() => false)
  const [createGuildError, setCreatGuildError] = useState<string>('')
  const [statusEdit, setStatusEdit] = useState(() => false)
  const [guildId, setGuildId] = useState(() => "")
  const logoRef = useRef<any>(null)
  const guildDescription = Form.useWatch('guildDescription', form)

  const isTextValid = !guildDescription || !valCheckbox

  useEffect(() => {
    getGuildData()
  }, [])

  // useEffect(() => {
  //   if (auth.isLogged && profile.guild_id) {
  //     navigate(links.guild.information())
  //   }
  // }, [auth])

  const getGuildData = async () => {
    const [error, result]: any = await handleAsyncRequest(guildApi.getGuildMaterData())
    if (error) {
      if (error.code === 403) {
        toggleIsRequestLoginModalOpen()
        return
      }
      openNotification('ERROR', error.message || error.errors[0]?.message)
    }
    if (result) {
      setGuildData(() => result.data)
      logoRef.current = result?.data?.logos[0]
    }
    await getGuildInformation()
    setGuildDataLoading(false)
  }

  const getGuildInformation = async () => {
    const [error, result]: any = await handleAsyncRequest(guildApi.getGuildInformation())

    if (error) {
      setLogoChoose(() => logoRef.current)
      openNotification('ERROR', error.message || error.errors[0]?.message)
    }

    if (result) {
      setLogoChoose(() => result?.data?.avatar)
      setGuildId(() => result?.data?.id)
      form.setFieldValue('guildName', result?.data?.name)
      form.setFieldValue('guildTag', result?.data?.guild_tag)
      form.setFieldValue('guildDescription', result?.data?.description)
    }
  }

  const handleEdit = () => {
    setStatusEdit(() => !statusEdit)
  }

  const handleSubmit = () => {
    // setDataCreateGuild(value)
    setIsShowModalConfirmEditGuild(!isShowModalConfirmEditGuild)
  }

  const handleCloseModalConfirmEditGuild = () => {
    setCreatGuildError('')
    setIsShowModalConfirmEditGuild(!isShowModalConfirmEditGuild)
  }

  const openNotification = (message: string, description: string) => {
    notification.config({
      duration: 4
    })

    api.info({
      message,
      description,
      placement: 'bottomRight',
      className: message === 'ERROR' ? 'ant-custom-error' : 'ant-custom-success'
    })
  }

  const handleConfirmEditGuild = async () => {
    setLoading(true)
    setCreatGuildError('')

    // const trimStr = dataCreateGuild?.guildDescription?.trim()?.replace(/  +/g, ' ');

    const dataResquest: EditGuildRequest = {
      avatar: logoChoose,
      description: guildDescription
    }

    const [error, result]: any = await handleAsyncRequest(guildApi.putEditGuild(guildId, dataResquest))

    if (error) {
      setCreatGuildError(error.message || error.errors[0]?.message)
    }
    if (result) {
      handleCloseModalConfirmEditGuild()
      setCreatGuildError('')
      setStatusEdit(() => !statusEdit)
      setValCheckbox(() => false)
      openNotification('SUCCESS', t(`${GUILD_MESSAGE}.message.validate_edit_guild_success`))
      // navigate(links.guild.information())
    }
    setLoading(false)
  }

  const handdleChooseAvatar = (logo: any) => {
    setLogoChoose(() => logo)
  }

  const onChangeCheckbox = () => {
    setValCheckbox(() => !valCheckbox)
  }

  return (
    <UpdateInfoStyled>
      {contextHolder}
      {guildDataLoading ? (
        <Loading />
      ) : (
        <Form form={form} name='basic' onFinish={statusEdit ? handleSubmit : handleEdit} autoComplete='off'>
          {(values, instance) => {
            const desError = instance.getFieldError('guildDescription');
            const isError = desError?.length > 0

            return (
              <div className='guild-container'>
                <div className='row line__guild--gradiant'>
                  <img src={LINE_GUILD_GRADIENT} />
                </div>
                <div className='color-DEDEDE row'>
                  <div className={`col-4 guil__content ${statusEdit ? "" : "disableDiv"}`}>
                    <div className='guild-label guild__label--logo'>{t(`${GUILD_MESSAGE}.guild_logo`)}</div>
                    <div className='guild__content--logo'>
                      <img src={ONE_LINE} alt='' className='orange-line position-absolute' />
                      <div className='guild__content--avatar'>
                        {logoChoose && <img className='guild-avatar' src={logoChoose} />}
                      </div>
                      <div className='guild__line'>
                        <img src={ONE_LINE_LEFT} />
                        <span className='guild__line--space'></span>
                        <img src={ONE_LINE_RIGTH} />
                      </div>

                      <div className='guild__content--arr-avatar'>
                        {guildData?.logos?.map((logoItem: string | undefined, i: any) => {
                          return (
                            <div
                              key={i}
                              onClick={() => handdleChooseAvatar(logoItem)}
                              className={`item__avatar ${logoItem === logoChoose ? 'active' : ''}`}
                            >
                              <img className='img-avatar' src={logoItem} />
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                  <div className='col-8 guil__content'>
                    <div>
                      <div className='guild-label'>
                        {t(`${GUILD_MESSAGE}.guild_name`)}<span className='request__red'>{t(`${GUILD_MESSAGE}.message.validate_request`)}</span>
                      </div>
                      <div>
                        <Form.Item
                          name='guildName'
                          className='input-box'
                        >
                          <InputCustomV2
                            name='guildName'
                            type='text'
                            value={values.guildName}
                            disabled={true}
                          />
                        </Form.Item>
                      </div>
                    </div>

                    <div className='guil__content--mar5'>
                      <div className='guild-label'>
                        {t(`${GUILD_MESSAGE}.guild_tag`)}<span className='request__red'>{t(`${GUILD_MESSAGE}.message.validate_request`)}</span>
                      </div>
                      <div>
                        <Form.Item
                          name='guildTag'
                          className='input-box'
                        >
                          <InputCustomV2 type='text' disabled={true} />
                        </Form.Item>
                      </div>
                    </div>

                    <div className='guil__content--mar5'>
                      <div className='guild-label'>
                        {t(`${GUILD_MESSAGE}.guild_description`)}<span className='request__red'>{t(`${GUILD_MESSAGE}.message.validate_request`)}</span>
                      </div>
                      <div>
                        <Form.Item
                          name='guildDescription'
                          rules={[
                            { required: true, message: t(`${GUILD_MESSAGE}.message.validate_req_description`) },
                            { max: 500, message: t(`${GUILD_MESSAGE}.message.validate_max_char`) },
                            {
                              validator: (_, value) => {
                                if (value && value && value.trim().length < 50) {
                                  return Promise.reject(new Error(t(`${GUILD_MESSAGE}.message.validate_min_char`)))
                                }
                                return Promise.resolve()
                              }
                            }
                          ]}
                          className='input-box'
                        >
                          <InputTextarea
                            type='textarea'
                            minLength={50}
                            maxLength={500}
                            value={values.guildDescription}
                            customClass='guild__textarea'
                            disabled={!statusEdit}
                            placeholder={t(`${GUILD_MESSAGE}.place_guild_description`)}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>

                {statusEdit && <div className='color-DEDEDE row'>
                  <div className='col-4'></div>
                  <div className={`col-8 guil__content--checkbox`}>
                    <input
                      id="guild_create_checkbox"
                      className='guild__create--checkbox'
                      type='checkbox'
                      onChange={() => onChangeCheckbox()}
                    />
                    <label htmlFor='guild_create_checkbox' className='checkbox__lable'>
                    <span className='checkbox_text'>{t(`${GUILD_MESSAGE}.checkbox_accept`)}</span>
                      <a 
                        rel='noopener noreferrer'
                        href={links.home.privacy()}
                        target='_blank'
                      >
                        <span className='checkbox_text'>{t(`${GUILD_MESSAGE}.checkbox_accept2`)}</span>
                      </a>
                    </label>
                    <div className='border__cover'></div>
                  </div>
                </div>}

                <div className='row line__guild--gradiant'>
                  <img src={LINE_GUILD_GRADIENT} />
                </div>

                <div className='btn-create'>
                  {statusEdit &&
                    <Form.Item>
                      <Button
                        buttonName={
                          <div>
                            {t(`${GUILD_MESSAGE}.btn_save`)}
                          </div>
                        }
                        onClickButton={form.submit}
                        width={147}
                        disabled={isTextValid || isError}
                      />
                    </Form.Item>
                    ||
                    <Form.Item>
                      <Button
                        buttonName={
                          <div>
                            {t(`${GUILD_MESSAGE}.btn_edit`)}
                          </div>
                        }
                        onClickButton={form.submit}
                        width={147}
                      />
                    </Form.Item>}
                </div>
              </div>
            )
          }}
        </Form>
      )}
      {isShowModalConfirmEditGuild && (
        <ModalConfirmEditGuild
          onClose={handleCloseModalConfirmEditGuild}
          onConfirm={handleConfirmEditGuild}
          titleModal={t(`${GUILD_MESSAGE}.guild_title_edit`)}
          loading={loading}
          content={t(`${GUILD_MESSAGE}.guild_modal_edit_content`)}
          error={createGuildError}
        />
      )}

      {isRequestLoginModalOpen && <RequestLoginModal toggleIsModalOpen={toggleIsRequestLoginModalOpen} />}
    </UpdateInfoStyled>
  )
}
