/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from 'antd';
import { links } from 'apps';
import { Link } from 'react-router-dom';
import { shortenUserName } from 'utils/helper';
import InfoBoxContainer from './styled';
import { VIEW_DOPING } from 'assets/images';

const InfoBox = ({ data }: any) => {
  return (
    <InfoBoxContainer>
      <div className='content-box'>
        <div className='img-box'>
          <img src={data?.horse?.avatar} alt="avatar" />
        </div>
        <div className='info'>
          <p className='name'>
            {data?.horse?.name}
            {  data?.horse.equipped_boost_item && (
              <img src={VIEW_DOPING} alt="" className='used-doping' />
            )}
          </p> 
          <div className='row-info first'>
            <span>Token id:</span>
            <span>{data?.horse?.token_id}</span>
          </div>
          <div className='row-info second'>
            <span>Renter:</span>
            <span>{data?.lending_info?.renter?.name ? shortenUserName(data?.lending_info?.renter?.name) : '---'}</span>
          </div>
          <div className='row-info thrid'>
            <span>Owner:</span>
            <span>{shortenUserName(data?.horse?.owner?.name)}</span>
          </div>
        </div>
      </div>
      <Button className='button-detail'>
        <Link to={links.horse.detail(data?.horse?.token_id)}>Detail</Link>
      </Button>
    </InfoBoxContainer>
  )
}

export default InfoBox;