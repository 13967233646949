import { ItemNFT } from 'features/ShopAP/components'
import BuyItemsStyled from './styled'
import { SwitchBtn } from 'features/Race/components'
import SelectCustom from 'shared/SelectCustom'
import { SearchInput } from 'shared'
import { ItemShop, ItemShopsParams, LendingSortType, MyAssetSortType, TypeSorts } from 'models'
import { useToggle } from 'hooks'
import { useRef, useState, useEffect } from 'react'
import { convertPluralText, handleAsyncRequest } from 'utils/helper'
import itemShopsApi from 'apis/itemShopsApi'
import { useLocation, useNavigate } from 'react-router-dom'


const fakeData = [
  {
    amount: 0,
    boost: {
      boost_type: "STATS",
      currency: "E_PRZ",
      description: "string",
      effect: {
        effect: {
          master_effect_type: "MUSCLE",
          unit: "PERCENT",
          value: 0
        },
        recover: "NOT_FULL"
      },
      effect_type: "MUSCLE",
      id: 0,
      img: "string",
      level: 0,
      name: "item name 1",
      price: 0,
      rarity_type: "COMMON",
    }
  },
  {
    amount: 0,
    boost: {
      boost_type: "STATS",
      currency: "E_PRZ",
      description: "string",
      effect: {
        effect: {
          master_effect_type: "MUSCLE",
          unit: "PERCENT",
          value: 0
        },
        recover: "NOT_FULL"
      },
      effect_type: "MUSCLE",
      id: 1,
      img: "string",
      level: 0,
      name: "item name 1",
      price: 100,
      rarity_type: "COMMON",
    },
  },
  {
    amount: 0,
    boost: {
      boost_type: "STATS",
      currency: "E_PRZ",
      description: "string",
      effect: {
        effect: {
          master_effect_type: "MUSCLE",
          unit: "PERCENT",
          value: 0
        },
        recover: "NOT_FULL"
      },
      effect_type: "MUSCLE",
      id: 2,
      img: "string",
      level: 0,
      name: "item name 3",
      price: 200,
      rarity_type: "COMMON",
    },
  },
  {
    amount: 0,
    boost: {
      boost_type: "STATS",
      currency: "E_PRZ",
      description: "string",
      effect: {
        effect: {
          master_effect_type: "MUSCLE",
          unit: "PERCENT",
          value: 0
        },
        recover: "NOT_FULL"
      },
      effect_type: "MUSCLE",
      id: 3,
      img: "string",
      level: 0,
      name: "item name 3",
      price: 200,
      rarity_type: "COMMON",
    },
  },
  {
    amount: 0,
    boost: {
      boost_type: "STATS",
      currency: "E_PRZ",
      description: "string",
      effect: {
        effect: {
          master_effect_type: "MUSCLE",
          unit: "PERCENT",
          value: 0
        },
        recover: "NOT_FULL"
      },
      effect_type: "MUSCLE",
      id: 4,
      img: "string",
      level: 0,
      name: "item name 3",
      price: 200,
      rarity_type: "COMMON",
    },
  },
  {
    amount: 0,
    boost: {
      boost_type: "STATS",
      currency: "E_PRZ",
      description: "string",
      effect: {
        effect: {
          master_effect_type: "MUSCLE",
          unit: "PERCENT",
          value: 0
        },
        recover: "NOT_FULL"
      },
      effect_type: "MUSCLE",
      id: 5,
      img: "string",
      level: 0,
      name: "item name 3",
      price: 200,
      rarity_type: "COMMON",
    },
  },
  {
    amount: 0,
    boost: {
      boost_type: "STATS",
      currency: "E_PRZ",
      description: "string",
      effect: {
        effect: {
          master_effect_type: "MUSCLE",
          unit: "PERCENT",
          value: 0
        },
        recover: "NOT_FULL"
      },
      effect_type: "MUSCLE",
      id: 6,
      img: "string",
      level: 0,
      name: "item name 3",
      price: 200,
      rarity_type: "COMMON",
    },
  },
]

export default function BuyItems() {
  const itemShopsParams: ItemShopsParams = {
    limit: 20,
    page: 1,
    bodyPart: 'HEAD'
  }
  const [isMyHorseOn, toggleIsMyHorseOn] = useToggle(false)
  const useRefSwitch = useRef<HTMLButtonElement>(null)
  const [searchValue, setSearchValue] = useState<string>('')
  const [classSelect, setClassSelect] = useState('Sort')
  const [params, setParams] = useState<ItemShopsParams>(itemShopsParams)
  const [, setIsLoading] = useState(false)
  const [listItems, setListItems] = useState<ItemShop[]>([])
  const [total, setTotal] = useState()

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const onItemClick = () => {
    navigate(`${pathname}/${1}`)
  }

  const fetchListItems = async () => {
    setIsLoading(true)
    const [, result] = await handleAsyncRequest(itemShopsApi.getItemShops({ ...params }))
    const records = result?.data.records
    setListItems(params.page > 1 ? [...listItems, ...records] : records)
    setTotal(result?.data.total)
    if (records && records.length === 0) {
      // setLoader(true)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchListItems()
  }, [])

  const defaultTypeFilters: TypeSorts = [
    {
      name: LendingSortType.Newest,
      isActive: false
    },
    {
      name: LendingSortType.Oldest,
      isActive: false
    },
    {
      name: MyAssetSortType.Ascending,
      isActive: false
    },
    {
      name: MyAssetSortType.Decrease,
      isActive: false
    }
  ]
  const handleSearchValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }
  const handleClassFilterClicked = (item: string) => {
    const handleKeySort = () => {
      switch (item) {
        case LendingSortType.Newest:
          return 'lending_date-desc'
        case LendingSortType.Oldest:
          return 'lending_date-asc'
        case MyAssetSortType.Ascending:
          return 'horse_id-asc'
        case MyAssetSortType.Decrease:
          return 'horse_id-desc'
        default:
          return 'lending_date-desc'
      }
    }
    setParams({
      ...params, page: 1, sort: handleKeySort()
    })
    setClassSelect(item)
  }
  return (
    <BuyItemsStyled>
      <div className='container'>
        <div className='buy-top'>
          <div className="total-items">
            <span>{convertPluralText(total, 'item')}</span>
          </div>
          <div className='filter-items'>
            <SwitchBtn
              title={'Your item'}
              isOn={isMyHorseOn}
              handleSwitchBtnClicked={toggleIsMyHorseOn}
              refSwitch={useRefSwitch}
            />
            <SelectCustom
              dataSelect={defaultTypeFilters}
              nameSelect={'All'}
              onSelected={handleClassFilterClicked}
            />
            <SelectCustom
              dataSelect={defaultTypeFilters}
              nameSelect={classSelect}
              onSelected={handleClassFilterClicked}
            />
            <SearchInput
              searchValue={searchValue}
              handleSearchValueChanged={handleSearchValueChanged}
              customClass='search-horse-input'
              placeholder={'Race name, Horse name...'}
            />
          </div>
        </div>
        <div className='items'>
          {fakeData.map((item, index) => (
            <ItemNFT key={index} itemName={item.boost.name} price={item.boost.price} onClick={onItemClick} />
          ))}
        </div>
      </div>
    </BuyItemsStyled>
  )
}
