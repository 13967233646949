import { useLocation, useOutlet, useParams } from 'react-router-dom'

import { links } from 'apps'
import TabsTitle from 'features/components/TabsTitle'
import GuildStyle from './styled'
import GuildInfo from './guild-info'

function GuildMain() {
  const { pathname } = useLocation()
  const { guild_id } = useParams()
  const outlet = useOutlet()

  const tabsGuildFarm = [
    {
      name: 'Horse Farm',
      link: guild_id ? links.guild.guildFarmId(guild_id) : links.guild.guildFarm()
    },
    {
      name: 'Horse Owner',
      link: guild_id ? links.guild.horseOwnerId(guild_id) : links.guild.horseOwner()
    },
    {
      name: 'Member',
      link: guild_id ? links.guild.membersId(guild_id) : links.guild.members()
    }
  ]

  const tabsGuildInfo = [
    {
      name: 'Information',
      link: links.guild.updateInfo()
    },
    {
      name: 'InCome',
      link: links.guild.inCome()
    },
    {
      name: 'Entry Condition',
      link: links.guild.entryCondition()
    }
  ]


  const tabsGuild = () => {
    if (
      pathname.indexOf(links.guild.members()) !== -1 ||
      pathname.indexOf(links.guild.horseOwner()) !== -1 ||
      pathname.indexOf(links.guild.guildFarm()) !== -1
    ) {
      return 'GUILD_FARM'
    }
    if (
      pathname.indexOf(links.guild.updateInfo()) !== -1 ||
      pathname.indexOf(links.guild.inCome()) !== -1 ||
      pathname.indexOf(links.guild.entryCondition()) !== -1
    ) {
      return 'GUILD_INFO'
    }
    return false
  }

  return (
    <GuildStyle>
      <div className='container'>
        {/* {
          titlePage().length > 0 ?
            <div className='title-tabs-container'>
              <div className='title-tabs color-primary'>
                <TwoLineTitle text={titlePage()} />
              </div>
            </div>
            : <></>
        } */}
        {tabsGuild() === 'GUILD_FARM' && (
          <GuildInfo />
        )}
        
        {tabsGuild() ? <TabsTitle tabs={tabsGuild() === 'GUILD_FARM' ? tabsGuildFarm : tabsGuildInfo} /> : <></>}
        <div className='content'>{outlet}</div>
      </div>
    </GuildStyle>
  )
}

export default GuildMain
